import { useSelector } from "react-redux";
import { ACN } from "../interfaces/ApiInterfaces";
import { acnDropdownValues } from "../reducers/rootReducer";
import { AppState } from "../store/configureStore";

export function useACNDropdownValues() {
    return useSelector<AppState, any>(acnDropdownValues);
}

export function getAcnFromDropdown(acnDropdownValues: any, acnId: number): ACN {
    var acnIndex = acnDropdownValues.findIndex((acn: ACN) => acn.id === acnId);
    return acnIndex !== -1
        ? acnDropdownValues[acnIndex]
        : {id: -1, acnName: '', contactName: '', email: '', telephone: null, address1: '', address2: '', city: '', state: '', zipCode: '', active: false, externalId: '', ein: null, approvedForImport: false};
}