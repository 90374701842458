import React, { useEffect } from 'react';
import { Header } from '../../header/Header';
import { Footer } from '../../footer/Footer';
import './AuthenticatedLayout.css';
import { UserMessagePanel } from '../../user.message.panel/UserMessagePanel';
import { useDispatch, useSelector } from 'react-redux';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { getActingForId, getActingForReason, getUserAcnId, getUserAcnName, hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { ApiUrls } from '../../../constants/ApiUrls';
import { SetACNDropdownValuesAction } from '../../../actions/acnDropdownValuesAction';
import { AppState } from '../../../store/configureStore';
import { applicationInfoInactivityTimeoutInMinutes, shouldStartInactivityTimer, applicationInfoUploadRestrictions } from '../../../reducers/rootReducer';
import { ACNActingForValue, SetACNActingForValueAction } from '../../../actions/acnActingForValueAction';
import { useActingFor } from '../../../hooks/useActingFor';
import { SetAppInactivityTimeoutAction, SetAppUploadRestrictionsAction } from '../../../actions/applicationInfoAction';
import { SetStartInactivityTimerAction } from '../../../actions/authAction';
import { useACNDropdownValues } from '../../../hooks/useACNDropdownValues';
import { useParams } from 'react-router-dom';
import { UploadRestrictions } from '../../../interfaces/ApiInterfaces';

export const AuthenticatedLayout: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const canActForAllACNs = hasPermissions(Permissions.CAN_ACT_FOR);
    const acnActingFor = useActingFor();
    const acnValues = useACNDropdownValues();
    const apiInactivityTimeoutMinutes = useSelector<AppState, number | null>(applicationInfoInactivityTimeoutInMinutes);
    const startInactivityTimer = useSelector<AppState, boolean>(shouldStartInactivityTimer);
    const uploadRestrictions = useSelector<AppState, UploadRestrictions | null>(applicationInfoUploadRestrictions);

    const shouldCreateInactivityTimer: boolean = startInactivityTimer && !!apiInactivityTimeoutMinutes;
    // Extra 5 seconds to make sure to hit the API after token expiry
    const inactivityTimerValue: number = !apiInactivityTimeoutMinutes ? 0 : (apiInactivityTimeoutMinutes * 60000 + 5000);
    useEffect(() => {
        if (!apiInactivityTimeoutMinutes) {
            const getInactivityTimeout = async () => {
                const inactivityTimeout = await makeJSONGetRequest(ApiUrls.GET_APP_INACTIVITY_TIMEOUT, dispatch, null, false, false);
                dispatch(SetAppInactivityTimeoutAction(inactivityTimeout.body.inactivityTimeoutInMinutes));
            }
            getInactivityTimeout();
        }
    }, [dispatch, apiInactivityTimeoutMinutes]);

    useEffect(() => {
        if (shouldCreateInactivityTimer) {
            const routeToLoginTimer = setTimeout(async () => {
                await makeJSONGetRequest(ApiUrls.GET_APP_VERSION, dispatch, null, false, false)
            }, inactivityTimerValue);
            dispatch(SetStartInactivityTimerAction(false));
            return () => clearTimeout(routeToLoginTimer);
        }
    }, [dispatch, inactivityTimerValue]);

    useEffect(() => {
        if (!uploadRestrictions) {
            const getFileUploadRestrictions = async () => {
                const fileUploadRestrictions = await makeJSONGetRequest(ApiUrls.GET_APP_UPLOAD_RESTRICTIONS, dispatch, null, false, false);
                dispatch(SetAppUploadRestrictionsAction(fileUploadRestrictions.body.uploadRestrictions));
            }
            getFileUploadRestrictions();
        }
    }, [dispatch, uploadRestrictions]);

    useEffect(() => {
        const getACNs = async () => {
            var result = await makeJSONGetRequest(canActForAllACNs ? ApiUrls.GET_ALL_ACNS : ApiUrls.GET_MY_ACNS, dispatch, null, false, false);
            var values = result.body;
            if (values.length === 0)
                throw 'No ACNs found!?'; // prevent infinite loop
            dispatch(SetACNDropdownValuesAction(values));
            setActingForACN(values);
        };

        const setActingForACN = (values: any[]) => {
            var selectedACN: ACNActingForValue;
            if (!!getUserAcnId() && !!getUserAcnName()) {
                selectedACN = { id: getUserAcnId()!, acnName: getUserAcnName()!, reason: "" };
            }
            else {
                const linkedActingForId = params.actingForId ? +params.actingForId : undefined;
                const linkedActingForACN = !Number.isNaN(linkedActingForId) && values.some((acn: any) => acn.id === linkedActingForId)
                    ? { ...values.find((acn: any) => acn.id == linkedActingForId), reason: getActingForReason() ?? "" }
                    : null;
                selectedACN = !!linkedActingForACN
                    ? linkedActingForACN
                    : acnActingFor.id !== -1 && values.some((acn: any) => acn.id === acnActingFor.id)
                        ? acnActingFor
                        : values.length > 0
                            ? (values.some((acn: any) => acn.id === +(getActingForId()!))
                                ? { ...values.find((acn: any) => acn.id === +(getActingForId()!)), reason: getActingForReason()! }
                                : { ...values[0], reason: "" })
                            : { id: -1, acnName: "", reason: "" };
            }
            if (acnActingFor.id !== selectedACN.id || acnActingFor.acnName !== selectedACN.acnName || acnActingFor.reason !== selectedACN.reason) {
                dispatch(SetACNActingForValueAction(selectedACN.id, selectedACN.acnName, selectedACN.reason));
            }
        };

        if (acnValues.length === 0) {
            getACNs();
        }
        else {
            setActingForACN(acnValues);
        }
    }, [dispatch, acnValues, acnActingFor]);

    return (
        <div className="authenticated-layout">
            <Header {...props} />
            <div className="page-content">
                <UserMessagePanel />
                <div className="authenticated-content">{props.children}</div>
                <Footer />
            </div>
        </div>
    );
};
