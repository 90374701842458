import React, { useEffect, useState } from 'react';
import './Login.css';
import { Container, Card, CardHeader, CardContent, Button } from '@mui/material';
import { getLabel } from '../../components/common/label/Label.library';
import { UnauthenticatedLayout } from '../../components/layouts/unauthenticated.layout/UnauthenticatedLayout';
import { TextInput } from '../../components/common/text.input/TextInput';
import { Formik } from 'formik';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { setPermissions, isAuthenticated, getAuthenticatedHomePage, setRoles, setUserId, setUserName, setUserAcnId, setUserAcnName, setIsViewOnly, setUserEmail } from '../../services/auth/auth';
import { getUserPermissions, getUserRoles } from './Login.library';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { loginRedirectPathname } from '../../reducers/rootReducer';
import { AppState } from '../../store/configureStore';
import { ClearLoginRedirectAction } from '../../actions/loginRedirectAction';
import { SetLogOutAction } from '../../actions/authAction';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFeatureFlagCheck } from '../../hooks/useFeatureFlags';
import { FeatureFlags } from '../../constants/FeatureFlags';

export const Login: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const redirectPathname = useSelector<AppState, string>(loginRedirectPathname);
  const location = useLocation();
  const navigate = useNavigate();
  const allowSSO = useFeatureFlagCheck(FeatureFlags.SSOLogIn);

  const queryParams = queryString.parse(location.search);
  const [ssoInProgress, setSsoInProgress] = useState(false);

  useEffect(() => {
    document.title = `${getLabel('app_name')} ${getLabel('title_login')}`
  }, [])

  const login = async (values: any) => {
    const data = {
      Email: values.username,
      Password: values.password,
    };
    const response = await makeJSONPostRequest(ApiUrls.LOGIN, dispatch, data);
    await processLoginResponse(response.body);
  };

  const ssoLogin = async () => {
    setSsoInProgress(true);
    try {
      // assuming already "logged in" with Entra(AAD), this is the following POST to finalize our hybrid login
      const response = await makeJSONPostRequest(ApiUrls.SSO_LOGIN, dispatch, null);
      await processLoginResponse(response.body);
    } finally {
      setSsoInProgress(false);
    }
  };

  const ssoRedirect = () => {
    setSsoInProgress(true);
    // simple GET redirect, and once logged in with Entra(AAD), will redirect back here with ?sso param
    window.location.href = ApiUrls.SSO_LOGIN;
  };

  useEffect(() => {
    if ('sso' in queryParams) {
      const sameLocationWithoutParams = location.pathname;
      navigate(sameLocationWithoutParams, { replace: true });
      ssoLogin();
    }
  }, [queryParams.sso]);

  const processLoginResponse = async (json: any) => {
    dispatch(SetLogOutAction(false));
    setUserId(json.id);
    setUserName(json.name);
    setUserEmail(json.email);
    // The only scenario that doesn't have the acting for selector is for a ACN role user with a single ACN associated
    if (!!json.accountableCareNetworks && json.accountableCareNetworks.length === 1) {
      setUserAcnId(json.accountableCareNetworks[0].id);
      setUserAcnName(json.accountableCareNetworks[0].acnName);
    }
    setRoles(getUserRoles(json.roles));
    setPermissions(getUserPermissions(json.roles));
    setIsViewOnly(String(json.isViewOnly));
    navigate(!!redirectPathname && redirectPathname !== ApplicationRoutes.LOGIN
      ? redirectPathname
      : getAuthenticatedHomePage()
    );
    dispatch(ClearLoginRedirectAction());
  };

  const validate = (values: any) => {
    const errors: { [key: string]: string } = {};
    // Validate username
    if (!values.username) {
      errors.username = getLabel('validation_message_required');
    }
    // Validate password
    if (!values.password) {
      errors.password = getLabel('validation_message_required');
    }
    return errors;
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(getAuthenticatedHomePage(), { replace: true });
    }
  }, []);

  useEffect(() => {
    if (queryParams.passwordSet) {
      dispatch(SetUserMessageSuccessAction('set_password_success_text'));
    }
  }, [queryParams.passwordSet, dispatch]);

  return (
    <UnauthenticatedLayout {...props}>
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={validate}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          login(values).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props) => !ssoInProgress && allowSSO !== undefined && (
          <form onSubmit={props.handleSubmit}>
            <Container maxWidth={false} className="login-container">
              <Card className="login-panel">
                <CardHeader title={getLabel('login_heading')} />
                <CardContent className="login-card-content">
                  <TextInput name="username" label="login_username_label" fullwidth={true} />
                  <TextInput name="password" label="login_password_label" type="password" fullwidth={true} />
                  <div className="submit-button">
                    <Button variant="contained" color="primary" type="submit" fullWidth={true}
                      disabled={!(props.dirty && props.isValid) || props.isSubmitting}>
                      {getLabel('login_submit_button_label')}
                    </Button>
                    {allowSSO && <Button variant="contained" color="secondary"
                      onClick={ssoRedirect} disabled={props.dirty}>
                      {getLabel('login_sso_button_label')}
                    </Button>}
                  </div>
                </CardContent>
              </Card>
              <div className="login-links">
                <Link to={ApplicationRoutes.RESET_PASSWORD}>{getLabel('reset_password_label')}</Link>
                {allowSSO && <a href={ApiUrls.SSO_LOGOUT}>{getLabel('logout_sso_label')}</a>}
              </div>
            </Container>
          </form>
        )}
      </Formik>
    </UnauthenticatedLayout>
  );
};
