export const isEmpty = (value: any) => !value || !value.trim();
export const isAllEmpty = (...values: any[]) => values.every(isEmpty);


const emailRegex = new RegExp(
    '^' +
    '([A-Z0-9_.%+-]+)@' +
    '([A-Z0-9-]{1,63}\\.)+' +
    '([A-Z0-9-]{1,63})' +
    '$', 'i');

export const isValidEmail = (email: any) => emailRegex.test(email);
