import { Button, Card, CardContent, Container } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { makeJSONGetRequest, makeJSONPostFileRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { getLabel } from '../../../components/common/label/Label.library';
import { Formik, FormikProps } from 'formik';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import { InfoTitles } from '../../../constants/InfoTitles';
import { FileInput } from '../../../components/common/file.input/FileInput';
import './FileUpload.css';
import { ClearUserMessageAction, SetUserMessageSuccessAction, SetUserMessageErrorAction } from '../../../actions/userMessageAction';
import { TextInput } from '../../../components/common/text.input/TextInput';
import { SelectInput } from '../../../components/common/select.input/SelectInput';
import { useActingFor } from '../../../hooks/useActingFor';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { ReinstateApiTokenTimeoutExpiryAction, SuspendApiTokenTimeoutExpiryAction } from '../../../actions/authAction';
import { FileType, UploadRestrictions } from "../../../interfaces/ApiInterfaces";
import { AppState } from '../../../store/configureStore';
import { applicationInfoUploadRestrictions } from '../../../reducers/rootReducer';
import { ClearFileSearchAction } from '../../../actions/fileSearchAction';
import { useNavigate } from 'react-router-dom';

export const FileUpload: React.FC<any> = (props) => {
    const acnActingFor = useActingFor();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isContainerReady, setIsContainerReady] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [fileTypes, setFileTypes] = useState<FileType[]>([]);
    const formikRef = useRef<FormikProps<{ Files: any[]; Comment: string; FileType: string; }>>(null);
    const uploadRestrictions = useSelector<AppState, UploadRestrictions | null>(applicationInfoUploadRestrictions);

    const uploadFiles = async (values: any, resetFunc: (any)) => {
        try {
            var data = new FormData();
            values.Files.forEach((file: any) => data.append("files", file, file.name));
            data.append("filetype", values.FileType);
            if (values.Comment) {
                data.append("comment", values.Comment);
            }
            data.append("actingForReason", acnActingFor.reason);

            const dataSize = getFormDataSize(data);
            const requestHeaderSizeBuffer = 51200; // 50 KB
            if (!!uploadRestrictions?.maximumSizeInBytes && (dataSize + requestHeaderSizeBuffer) > uploadRestrictions.maximumSizeInBytes) {
                dispatch(SetUserMessageErrorAction(getLabel('file_upload_max_file_size_exceeded')));
                return;
            }

            dispatch(SuspendApiTokenTimeoutExpiryAction());
            await makeJSONPostFileRequest(createUrl(ApiUrls.UPLOAD_FILE, { acnId: acnActingFor.id }), dispatch, data);
            setUploadSuccess(true);
            resetFunc({ values: { Files: [], Comment: '',  FileType: '' }});
            dispatch(SetUserMessageSuccessAction(getLabel('file_upload_success', { success: values.Files.length, total: values.Files.length })));
            navigateToFilesTab();
        }
        finally {
            dispatch(ReinstateApiTokenTimeoutExpiryAction());
        }
    };

    const getFormDataSize = (formData: FormData): number => {
        var size = 0;
        formData.forEach((value: FormDataEntryValue) => size += typeof value === "string" ? value.length : value.size);
        return size
    }

    useEffect(() => {
        const checkConnection = async () => {
            try {
                await makeJSONPostRequest(createUrl(ApiUrls.PREPARE_STORAGE_CONTAINER, { acnId: acnActingFor.id }), dispatch, null, true, true);
                setIsContainerReady(true);
                dispatch(ClearUserMessageAction());
            } catch {
                setIsContainerReady(false);
            }
        }
        if (acnActingFor.id !== -1) {
            checkConnection();
        }
    }, [acnActingFor.id])

    useEffect(() => {
        const getFileTypes = async () => {
            dispatch(ClearUserMessageAction());
            try {
                const url = createUrl(ApiUrls.GET_FILE_OPTIONS, { acnId: acnActingFor.id }) + '?forUpload=true';
                const response = await makeJSONGetRequest(url, dispatch, null, false, false);
                setFileTypes(response.body.fileTypes);
            } catch {
                setIsContainerReady(false);
            }       
        }
        if (acnActingFor.id !== -1) {
            getFileTypes();
        }
    }, [acnActingFor.id])

    const getFileTypeOptions = (): any => {
        return fileTypes.map((fileType) =>
            <option key={fileType.id} value={fileType.name}>
                {fileType.name}
            </option>
        );
    };

    const emptyFormWhenSwitchingACN = (id: number, acnName: string) => {
        if (id !== -1) {
            formikRef.current?.resetForm();
        }
    };

    const navigateToFilesTab = () => {
        dispatch(ClearFileSearchAction());
        navigate(createRoute(ApplicationRoutes.FILES));
    }

    const getAllowedExtensions = (): string | undefined => {
        return uploadRestrictions?.allowedExtensions.join(',')
    }

    return (
        <AuthenticatedLayout {...props} actingForOnChange={emptyFormWhenSwitchingACN} infoTitle={InfoTitles.FILE_UPLOAD}>
            <Container maxWidth={false} className="file-upload">
                <h2><PageHeading to={createRoute(ApplicationRoutes.FILES)} parentHeading={getLabel('file_page_heading')}>{getLabel('file_upload_page_heading')}</PageHeading></h2>
                {isContainerReady &&
                <Card className="file-upload-panel">
                    <CardContent>
                        <Formik
                            innerRef={formikRef}
                            initialValues={{ Files: [], Comment: '', FileType: '' }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                setUploadSuccess(false);
                                uploadFiles(values, actions.resetForm).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(formikProps) => (
                                <form encType='multipart/form-data' onSubmit={formikProps.handleSubmit}>
                                    <div className="file-upload truncate">
                                        <FileInput name="Files" label={'file_upload_choose_label'} clear={uploadSuccess} disabled={formikProps.isSubmitting} multiple={true} accept={getAllowedExtensions()}/>
                                        <SelectInput name="FileType" label="file_upload_filetype_label" blankOption={false} values={getFileTypeOptions()} disabled={formikProps.isSubmitting}/>
                                        <TextInput name="Comment" label="file_upload_comment_label" fullwidth={true} multiline={true}/>
                                    </div>
                                    <div className="file-upload-buttons">
                                        <Button className="button" type="button" variant="contained" color="primary" onClick={() => {formikProps.resetForm(); navigate(createRoute(ApplicationRoutes.FILES));}} disabled={formikProps.isSubmitting}>
                                            {getLabel('file_upload_cancel_button_label')}
                                        </Button>
                                        <Button className="red-button upload-button button red-button" type="submit" variant="contained" color="primary" disabled={formikProps.isSubmitting || formikProps.values.Files.length === 0 || !formikProps.values.FileType.trim()}>
                                            {getLabel('file_upload_submit_button_label')}
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>}
            </Container>
        </AuthenticatedLayout>
    )
}