import { CLEAR_ACN_ACTING_FOR_VALUE, ACNActingForValue, ACNActingForValueAction, SET_ACN_ACTING_FOR_VALUE } from '../actions/acnActingForValueAction';

const initialState: ACNActingForValue = { id: -1, acnName: "", reason: "" };

export const acnActingForValue = (
    state: ACNActingForValue = initialState,
    action: ACNActingForValueAction
): ACNActingForValue => {
    if (action.type === SET_ACN_ACTING_FOR_VALUE) {
        return {
            id: action.acnActingForValue.id,
            acnName: action.acnActingForValue.acnName,
            reason: action.acnActingForValue.reason
        };
    }
    else if (action.type === CLEAR_ACN_ACTING_FOR_VALUE) {
        return initialState;
    }
    return state;

};

export const getACNActingForValue = (state: ACNActingForValue) => state;
