import { Button, Link } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SetFetchNotificationsAction } from '../../../actions/fetchNotificationsAction';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { getLabel } from '../../../components/common/label/Label.library';
import { AffiliationStatus } from '../../../constants/AffiliationStatus';
import { ApiUrls } from '../../../constants/ApiUrls';
import { Permissions } from '../../../constants/Permissions';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { TabValue } from '../../../constants/TabValue';
import { useActingFor, getActingForDetails } from '../../../hooks/useActingFor';
import { makeJSONPostRequest } from '../../../services/ajax/ajax';
import { hasPermissions } from '../../../services/auth/auth';
import { getClientId } from '../../../services/client/client';
import { APIButton } from '../../common/button/APIButton';
import { DialogModal } from '../../common/dialog.modal/DialogModal';
import { Program } from '../../../../src/interfaces/ApiInterfaces';
import './AffiliationButton.css';

export interface AffiliationACN {
    id: number;
    acnName: string;
}

interface Props {
    providerId: number;
    productId?: number;
    programId?: number;
    currentStatus: string | null;
    onChange?: Function;
    hasButtonInfo?: boolean;
    affiliatedWith?: AffiliationACN | null;
    requestedBy?: AffiliationACN[];
    providerActive: boolean;
    disabled?: boolean;
    setIsAffiliationActionInProgress: Function;
    setShowAffiliationCycleParticipationDialog?: Function;
    setAffiliationCycleParticipationDialogTitle?: Function;
}

export const AffiliationButton: React.FC<Props> = ({ providerId, productId, programId, currentStatus, onChange, hasButtonInfo = false,
    affiliatedWith, requestedBy, providerActive, disabled, setIsAffiliationActionInProgress, setShowAffiliationCycleParticipationDialog,
    setAffiliationCycleParticipationDialogTitle }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const canManageProviders = hasPermissions(Permissions.CAN_MANAGE_PROVIDERS);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [showProgramRuleViolationDialog, setShowProgramRuleViolationDialog] = useState(false);
    const [programRuleViolationDialogTitle, setProgramRuleViolationDialogTitle] = useState<string>("");
    const [programOptions, setProgramOptions] = useState<{ [label: string] : any }>({});

    const acnActingFor = useActingFor();

    function getRequestData() {
        return {
            AccountableCareNetworkId: acnActingFor.id,
            ProviderId: providerId,
            ProductId: productId,
            ProgramId: programId,
            ...getActingForDetails(acnActingFor)
        };
    }

    async function handleAffiliate(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, chosenProgramId: number | null = null) {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation(); // Avoid taking user to another page when a program is chosen on the program rule violations dialog on Providers tab
        setIsAffiliationActionInProgress(true);
        try {
            const data = {
                ClientId: getClientId(),
                ChosenProgramId: chosenProgramId,
                ...getRequestData()
            };
            const response = await makeJSONPostRequest(ApiUrls.ACN_CREATE_NEW_AFFILIATION, dispatch, data);
            if (response.response.ok) {
                if (!response.body.programRuleViolations || response.body.programRuleViolations.length === 0) {
                    await handleSuccess('affiliation_success_create_text', true);

                    const isFullParticipation = response.body.isFullParticipationInNextCycle;
                    const isNextCycleAttested = response.body.isNextCycleAttested;
                    if (!isFullParticipation && setShowAffiliationCycleParticipationDialog && setAffiliationCycleParticipationDialogTitle) {
                        setShowAffiliationCycleParticipationDialog(true);
                        setAffiliationCycleParticipationDialogTitle(isNextCycleAttested
                            ? getLabel("providers_new_affiliation_cycle_already_attested")
                            : getLabel("providers_included_in_another_ACN_attested_roster")
                        );
                    }
                } else {
                    handleProgramRuleViolations(response.body.programRuleViolations, response.body.programOptions);
                }
            }
        } catch (error: any) {
            handleError(error, 'affiliation_failed_create_unauthorized');
        } finally {
            setIsAffiliationActionInProgress(false);
        }
    }

    const handleProgramRuleViolations = (programRuleViolations: string[], programOptions: Program[]) =>  {
        if (!!programRuleViolations && programRuleViolations.length >= 1) {
            const programActionMap: { [label: string] : any; } = {};
            programOptions.forEach((p: Program)=> programActionMap[p.name] = (e: any) => chooseProgram(e, p.id));

            let title = programRuleViolations.join("\n");
            if (Object.keys(programActionMap).length >= 1) {
                title += "\nChoose one program.";
            }

            setProgramRuleViolationDialogTitle(title);
            setProgramOptions(programActionMap);
            dispatch(SetUserMessageErrorAction(getLabel('affiliation_failed_create_text')));
            setShowProgramRuleViolationDialog(true);
        }
    }

    async function completeRemoveAffiliation(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        setIsAffiliationActionInProgress(true);
        setShowRemoveDialog(false);
        try {
            const data = getRequestData();
            await makeJSONPostRequest(ApiUrls.ACN_END_AFFILIATION, dispatch, data);
            await handleSuccess('affiliation_success_end_text', true);
        } catch (error: any) {
            handleError(error, 'affiliation_failed_end_unauthorized');
        } finally {
            setIsAffiliationActionInProgress(false);
        }
    }

    async function handleRequestRelease(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        setIsAffiliationActionInProgress(true);
        try {
            const data = getRequestData();
            await makeJSONPostRequest(ApiUrls.REQUEST_PROVIDER_RELEASE, dispatch, data);
            await handleSuccess('request_release_sent', false);
        } catch (error: any) {
            handleError(error);
        } finally {
            setIsAffiliationActionInProgress(false);
        }
    }

    async function handleDeclineRelease(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        setIsAffiliationActionInProgress(true);
        try {
            const data = getRequestData();
            await makeJSONPostRequest(ApiUrls.REJECT_PROVIDER_RELEASE, dispatch, data);
            await handleSuccess('release_declined', true);
        } catch (error: any) {
            handleError(error);
        } finally {
            setIsAffiliationActionInProgress(false);
        }
    }

    async function handleApproveRelease(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        setIsAffiliationActionInProgress(true);
        try {
            const data = getRequestData();
            await makeJSONPostRequest(ApiUrls.APPROVE_PROVIDER_RELEASE, dispatch, data);
            await handleSuccess('release_approved', true);
        } catch (error: any) {
            handleError(error);
        } finally {
            setIsAffiliationActionInProgress(false);
        }
    }

    async function handleSuccess(message: string, affectsNotifications: boolean) {
        if (onChange) {
            await onChange({ providerId, productId, programId });
        }
        if (affectsNotifications) {
            dispatch(SetFetchNotificationsAction(true));
        }
        dispatch(SetUserMessageSuccessAction(message));
    }

    function handleError(error:any, unauthorizedMessage?: string) {
        if (error.status === 401 && unauthorizedMessage) {
            dispatch(SetUserMessageErrorAction(unauthorizedMessage));
        }
        else {
            console.warn(error);
        }
    }

    function getButtonInfoLabel(){
        if ((currentStatus == AffiliationStatus.UNAVAILABLE || currentStatus == AffiliationStatus.RELEASE_PENDING) && affiliatedWith) {
            return getLabel("providers_list_affiliated_with_header");
        } else if (currentStatus == AffiliationStatus.RELEASE_REQUESTED && requestedBy && requestedBy.length) {
            return getLabel("providers_list_requested_by_header");
        } else {
            return null;
        }
    }

    function getButtonInfoValue(){
        return (
            <>
                {
                    (currentStatus == AffiliationStatus.UNAVAILABLE || currentStatus == AffiliationStatus.RELEASE_PENDING) && affiliatedWith &&
                        <div><Link onClick={() => toEditACN(affiliatedWith)}>{affiliatedWith.acnName}</Link></div>
                }
                { 
                    currentStatus === AffiliationStatus.RELEASE_REQUESTED && requestedBy && requestedBy.length &&
                        requestedBy.map(request => <div><Link onClick={() => toEditACN(request)}>{request.acnName}</Link></div>)
                }
            </>
        );
    }

    const cancelRemoveAffiliation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setShowRemoveDialog(false);
    }

    const handleRemoveAffiliation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setShowRemoveDialog(true);
    }

    const toEditACN = (acn: AffiliationACN) => {
        navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acn.id, tab: TabValue.ACN_ATTRIBUTES }));
    }

    const getRemoveDialogText = () => {
        if (!programId) {
            if (!productId) {
                return getLabel("remove_all_provider_dialog_title");
            }
            return getLabel("remove_product_provider_dialog_title");
        }
        if (!productId) {
            return getLabel("remove_program_provider_dialog_title");
        }
        return getLabel("remove_provider_dialog_title");
    }

    const dismissProgramRuleViolationDialog = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        if (onChange) { // To "unstuck" disabled Affiliate button on Providers tab
            await onChange({ providerId, productId, programId });
        }
        setShowProgramRuleViolationDialog(false);
    };

    const chooseProgram = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, chosenProgram: number) => {
        handleAffiliate(event, chosenProgram);
        setShowProgramRuleViolationDialog(false);
    };

    return (
        <div className="affiliation-button-container">
            <DialogModal id="removeProviderDialog" title={getRemoveDialogText()} onClickLeft={(e: any) => cancelRemoveAffiliation(e)} onClickRight={(e: any) => completeRemoveAffiliation(e)}
                open={showRemoveDialog} labelLeft={getLabel("remove_provider_dialog_cancel")} labelRight={getLabel("remove_provider_dialog_remove")} />
            <DialogModal id="programRuleViolationDialog" open={showProgramRuleViolationDialog} title={programRuleViolationDialogTitle} extraOptions={programOptions}
                onClickRight={(e: any) => dismissProgramRuleViolationDialog(e)} labelRight={getLabel("providers_new_affiliation_violations_dialog_cancel_button")} /> 
            {hasButtonInfo &&
                <div className="affiliation-button-info">
                    <div className="colHeader">{getButtonInfoLabel()}</div>
                    <div className="affiliation-button-info-value truncate">{getButtonInfoValue()}</div>
                </div>
            }
            <div className="affiliation-button">
                {currentStatus == AffiliationStatus.AFFILIATED &&
                    <APIButton type="button" variant="contained" color="primary" disabled={!canManageProviders || !providerActive || disabled} className="red-button" onClick={handleRemoveAffiliation}>{getLabel("providers_list_remove_button_text")}</APIButton>}
                {currentStatus == AffiliationStatus.AVAILABLE &&
                    <APIButton type="button" variant="contained" color="primary" disabled={!canManageProviders || !providerActive || disabled} className="green-button" onClick={handleAffiliate}>{getLabel("providers_list_affiliate_button_text")}</APIButton>}
                {currentStatus == AffiliationStatus.UNAVAILABLE &&
                    <APIButton type="button" variant="contained" color="primary" disabled={!canManageProviders || !providerActive || disabled} className="result-action-button-request" onClick={handleRequestRelease}>{getLabel("providers_list_request_button_text")}</APIButton>}
                {currentStatus == AffiliationStatus.RELEASE_REQUESTED && <>
                    <APIButton type="button" variant="contained" color="primary" disabled={!canManageProviders || !providerActive || disabled} className="result-action-button-requested-decline" onClick={handleDeclineRelease}>{getLabel("providers_list_decline_button_text")}</APIButton>
                    <APIButton type="button" variant="contained" color="primary" disabled={!canManageProviders || !providerActive || disabled} className="result-action-button-requested-approve" onClick={handleApproveRelease}>{getLabel("providers_list_approve_button_text")}</APIButton></>}
                {currentStatus == AffiliationStatus.RELEASE_PENDING &&
                    <Button type="button" variant="contained" color="primary" disabled className="result-action-button-pending">{getLabel("providers_list_pending_button_text")}</Button>}
            </div>
        </div>
    );
};
