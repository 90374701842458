import { CLEAR_NEXT_CYCLE, NextCycleActionTypes, SET_NEXT_CYCLE, SET_NEXT_CYCLE_LOADING } from "../actions/nextCycleAction";
import { Cycle } from "../interfaces/ApiInterfaces";

export interface NextCycleValue extends Cycle {
    acnId: number;
}

const initialState: NextCycleValue = {
    acnId: -1,
    id: -1,
    name: '',
    startAt: '',
    endAt: '',
    snapshotAt: '',
    isOpen: true,
    locked: false
}

export const nextCycle = (state: NextCycleValue = initialState, action: NextCycleActionTypes): NextCycleValue => {
    switch (action.type) {
        case SET_NEXT_CYCLE_LOADING:
            return { ...initialState, acnId: action.acnId };
        case SET_NEXT_CYCLE:
            return { ...action.cycle, acnId: state.acnId };
        case CLEAR_NEXT_CYCLE:
            return initialState;
        default:
            return state;
    }
}

export const getNextCycle = (state: NextCycleValue) => state;
