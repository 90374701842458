import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, Button, Card, CardContent } from '@mui/material';
import { getLabel } from '../common/label/Label.library';
import './FileDetailsModal.css';
import { File } from '../../interfaces/ApiInterfaces';
import { FileDetailsRow } from './FileDetailsRow';
import { AttestationFileDetails } from './AttestationFileDetails';
import { ImportResultFileDetails } from './ImportResultFileDetails';
import { FileOperationStatus } from '../../constants/FileStatus';
import { useActingFor } from '../../hooks/useActingFor';
import { useDispatch } from 'react-redux';
import { makeJSONPostRequest, makePostBlobRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { APIButton } from '../common/button/APIButton';

interface Props {
    open: boolean;
    file: File;
    attestationText: string;
    updateIndividualFileInList: (updatedFile: File) => void;
    onPostDelete: () => void;
    onClickClose: () => void;
}

export const FileDetailsModal: React.FC<Props> = ({ open, file, attestationText, updateIndividualFileInList, onPostDelete, onClickClose }) => {
    const acnActingFor = useActingFor();
    const dispatch = useDispatch();
    const [modalContent, updateModalContent] = useState<JSX.Element>();
    const [canDownloadResult, setCanDownloadResult] = useState<boolean>(false);

    const getImportDetails = async (operationStatus: FileOperationStatus) : Promise<string> => {
        const data = {
            AcnId: acnActingFor.id,
            FileName: file.name
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_FILE_IMPORT_DETAILS, dispatch, data, true, true);
        
        setCanDownloadResult(response.body.canDownload);

        let importDetails = response.body.importDetails;            
        if (operationStatus.code === FileOperationStatus.IMPORT_IN_PROGRESS || operationStatus.code === FileOperationStatus.IMPORT_ABORTED) {
            importDetails = getLabel(`file_import_details_${operationStatus.code}`, { details: importDetails });
        }
        dispatch(ClearUserMessageAction());
        
        return importDetails;
    };

    const onDownloadResult = async () => {
        const data = {
            AcnId: acnActingFor.id,
            FileName: file.name
        };
        const outFileName = file.name + '-ImportResult.csv';
        await makePostBlobRequest(ApiUrls.GET_FILE_IMPORT_DETAILS_CSV, dispatch, outFileName, data, true, true);
        dispatch(SetUserMessageSuccessAction('import_result_download_prepared'));
    };

    const getModalContent = async (fileOperationStatus: string) : Promise<JSX.Element | undefined> => {
        const status = new FileOperationStatus(fileOperationStatus);
        if (status.isAttestable()) {
            return <AttestationFileDetails 
                open={open}
                file={file}
                attestationText={attestationText}
                updateIndividualFileInList={updateIndividualFileInList}
            />
        }
        if (status.hasImportDetails()) {
            const importDetails = await getImportDetails(status)
            return <ImportResultFileDetails
                importDetail={importDetails}
            />
        }
    };

    const onClose = () => {
        onClickClose();
        updateModalContent(undefined);
    };

    useEffect(() => {
        if (open && !!file) {
            getModalContent(file.operationStatus).then(content => updateModalContent(content));
        }
    }, [file, open]);
    
    return (
        <>
            {!!file && !!modalContent &&
                <Dialog open={open} className="file-details-modal" fullWidth maxWidth="lg">
                    <Card>
                        <CardContent>
                            <FileDetailsRow file={file} onPostDelete={onPostDelete} />
                        </CardContent>
                    </Card>
                    {modalContent}
                    <DialogActions id="dialog-buttons">
                        <Button variant="contained" color="primary" onClick={onClose}>{getLabel('file_modal_close_button_label')}</Button>
                        {canDownloadResult &&
                            <APIButton variant="contained" color="primary" className="button green-button" onClick={onDownloadResult}>{getLabel('file_modal_download_result_button_label')}</APIButton>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};