import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { Container } from '@mui/material';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import './ViewReport.css';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { Report as ReportInterface } from '../../../interfaces/ApiInterfaces';
import { PowerBIEmbed } from 'powerbi-client-react';
import { IEmbedConfiguration, service, Embed, models, Report as PowerBiReport } from 'powerbi-client';
import { ReportViewProperties } from '../../../constants/ReportViewProperties';
import { useParams } from 'react-router-dom';

export const ViewReport: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const viewReportRef = useRef<HTMLDivElement>(null);
    const params = useParams();
    const reportId = +params.id!;
    const [reportValues, setReportValues] = useState<ReportInterface>({ id: -1, name: '', url: '', categories: [], active: true, embedToken: '', reportId: '', datasetId: null });

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_view_report')}`;
    }, []);

    const [report, setReport] = useState<PowerBiReport>();

    const [sampleReportConfig, setReportConfig] = useState<IEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
        },
    });

    const getReportStyleClass = () => {
        if (viewReportRef.current && viewReportRef.current.clientWidth && viewReportRef.current.clientHeight) {
            if ((viewReportRef.current.clientWidth / ReportViewProperties.WIDTH_TO_HEIGHT_VIEW_RATIO) > viewReportRef.current.clientHeight) {
                return "report-height-limiting";
            } else {
                return "report-width-limiting";
            }
        }
    };

    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
        }],
        ['rendered', function () {
            console.log('Report has rendered');

        }],
        ['error', function (event?: service.ICustomEvent<any>) {
            if (event) {
                console.error(event.detail);
            }
        }]
    ]);

    useEffect(() => {
        const loadReport = async () => {
            const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_REPORT, { reportId }), dispatch, null, false, false);
            const report: ReportInterface = response.body;
            const config: IEmbedConfiguration = {
                ...sampleReportConfig,
                embedUrl: report.url,
                accessToken: report.embedToken
            };
            if (report.datasetId)
                config.datasetBinding = { datasetId: report.datasetId };
            setReportValues(report);
            setReportConfig(config);
        }
        loadReport();
    }, []);

    return (
        <AuthenticatedLayout {...props} infoTitle={reportValues.name}>
            <Container ref={viewReportRef} maxWidth={false} className="viewReport">
                {reportValues.id != -1 && <PowerBIEmbed
                    embedConfig={sampleReportConfig}
                    eventHandlers={eventHandlersMap}
                    cssClassName={`report-style-class ${getReportStyleClass()}`}
                    getEmbeddedComponent={(embedObject: Embed) => {
                        console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                        setReport(embedObject as PowerBiReport);
                    }}
                />}
            </Container>
        </AuthenticatedLayout>
    );
};
