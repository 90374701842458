import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SetNextCycleAction, SetNextCycleLoadingAction } from '../../../actions/nextCycleAction';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { ApplicationRoutes, createRoute } from '../../../constants/ApplicationRoutes';
import { Permissions } from '../../../constants/Permissions';
import { TabValue } from '../../../constants/TabValue';
import { useActingForId } from '../../../hooks/useActingFor';
import { NextCycleValue } from '../../../reducers/nextCycleReducer';
import { nextCycleValue } from '../../../reducers/rootReducer';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { hasPermissions } from '../../../services/auth/auth';
import { AppState } from '../../../store/configureStore';
import { getLabel } from '../label/Label.library';
import './NextCycle.css';

export const NextCycle: React.FC<any> = () => {
    const dispatch = useDispatch();
    const canViewCycles = hasPermissions(Permissions.CAN_VIEW_CYCLES);
    const acnActingForId = useActingForId();
    const nextCycle = useSelector<AppState, NextCycleValue>(nextCycleValue);

    useEffect(() => {
        const getNextCycle = async () => {
            dispatch(SetNextCycleLoadingAction(acnActingForId));
            const url = createUrl(ApiUrls.GET_NEXT_CYCLE, { acnId: acnActingForId });
            const response = await makeJSONGetRequest(url, dispatch, null, false, false);
            dispatch(SetNextCycleAction(response.body));
        }
        if (canViewCycles && acnActingForId !== nextCycle.acnId && acnActingForId !== -1) {
            getNextCycle();
        }
    }, [acnActingForId, nextCycle.acnId]);

    return (
        <>
            {
                (canViewCycles && nextCycle.id !== -1) &&
                <div className="next-cycle">
                    <span className="next-cycle-label">{getLabel('next_cycle_label')}</span>
                    <Link className="next-cycle-name" to={createRoute(ApplicationRoutes.EDIT_CYCLE, { id: nextCycle.id, tab: TabValue.CYCLE_ATTRIBUTES })}>
                        {nextCycle.name}
                    </Link>
                </div>
            }
        </>
    );
};