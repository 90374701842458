export interface UserACNsSearch {
    acnName: string;
  }
  
  export const SET_USER_ACNS_SEARCH = "SET_USER_ACNS_SEARCH";
  export const CLEAR_USER_ACNS_SEARCH = "CLEAR_USER_ACNS_SEARCH";
  
  interface SetUserACNsSearch {
    type: typeof SET_USER_ACNS_SEARCH;
    userACNsSearch: UserACNsSearch;
  }
  
  interface ClearUserACNsSearch {
    type: typeof CLEAR_USER_ACNS_SEARCH;
  }
  
  export type UserACNsSearchAction = SetUserACNsSearch | ClearUserACNsSearch;
  
  export const SetUserACNsSearchAction = (acnName: string): SetUserACNsSearch => ({
    type: SET_USER_ACNS_SEARCH,
    userACNsSearch: {acnName: acnName}
  });
  
  export const ClearUserACNsSearchAction = (): ClearUserACNsSearch => ({
    type: CLEAR_USER_ACNS_SEARCH
  });