import { ACNDropdownValues, ACNDropdownValuesAction, SET_ACN_DROPDOWN_VALUES } from '../actions/acnDropdownValuesAction';

const initialState: ACNDropdownValues = { acns: [] };

export const acnDropdownValues = (
    state: ACNDropdownValues = initialState,
    action: ACNDropdownValuesAction
): ACNDropdownValues => {
    if (action.type === SET_ACN_DROPDOWN_VALUES) {
        return {
            acns: action.acnDropdownValues.acns
        };
    }
    return state;

};

export const getACNDropdownValues = (state: ACNDropdownValues) => state.acns;
