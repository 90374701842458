import { Button, Card, CardContent } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { CheckboxInput } from "../../common/checkbox.input/CheckboxInput";
import { getLabel } from "../../common/label/Label.library";
import { TextInput } from "../../common/text.input/TextInput";
import { AllProviderSearch } from "../../../actions/allProviderSearchAction";
import { allProviderSearchValues } from "../../../reducers/rootReducer";
import { hasPermissions } from "../../../services/auth/auth";
import { Permissions } from '../../../constants/Permissions';
import './AllProvidersSearch.css';

interface Props {
    performSearch: any;
    clearSearch: any;
    disabled: boolean;
}

export const AllProvidersSearch: React.FC<Props> = ({ performSearch, clearSearch, disabled }) => {
    const searchValues = useSelector<AppState, AllProviderSearch>(allProviderSearchValues);

    return (
        <Card>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        nationalProviderId: searchValues.nationalProviderId ?? '',
                        includeInactive: searchValues.includeInactive
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        performSearch(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form className="providers-search" onSubmit={props.handleSubmit}>
                            <div className="search-filter-fields">
                                <TextInput name="nationalProviderId" type="number" label="providers_filter_pcp_npi" fullwidth={false} />
                                <div className="search-filter-buttons">
                                    {hasPermissions(Permissions.CAN_VIEW_ALL_PROVIDERS) && <CheckboxInput name="includeInactive" label="providers_filter_include_inactive" />}
                                    <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearch(); props.resetForm(); }}>
                                        {getLabel('clear_search_filter_button_label')}
                                    </Button>
                                    <Button className="button" type="submit" disabled={props.isSubmitting || disabled}variant="contained" color="primary">
                                        {getLabel('perform_search_button_label')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};
