import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardContent, List, ListItem, Paper } from '@mui/material';
import { getLabel } from "../common/label/Label.library";
import './ProgramProducts.css';
import { Product, Program } from '../../interfaces/ApiInterfaces';
import { makeJSONGetRequest, makeJSONPostRequest } from "../../services/ajax/ajax";
import { createUrl, ApiUrls } from "../../constants/ApiUrls";
import { SetUserMessageSuccessAction } from "../../actions/userMessageAction";
import { hasPermissions } from "../../services/auth/auth";
import { Permissions } from "../../constants/Permissions";
import { APIButton } from "../common/button/APIButton";
import { DialogModal } from "../common/dialog.modal/DialogModal";
import { ClearNextCycleAction } from "../../actions/nextCycleAction";

interface Props {
    program: Program;
}

export const ProgramProducts: React.FC<Props> = ({ program }) => {
    const dispatch = useDispatch();
    const [products, setProducts] = useState<any>([]);
    const [allProducts, setAllProducts] = useState<any>([]);
    const canManageProduct = hasPermissions(Permissions.CAN_MANAGE_PROGRAMS);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [removeDialogProduct, setRemoveDialogProduct] = useState<Product>();

    useEffect(() => {
        getProgramProducts();
        getAllProducts();
    }, [program.id]);

    const getProgramProducts = async () => {
        const response = await makeJSONGetRequest(
            createUrl(ApiUrls.GET_PROGRAM_PRODUCTS, { programId: program.id }), dispatch, null, true, true
        );
        setProducts(response.body);
    }

    const getAllProducts = async () => {
        const allProductResponse = await makeJSONGetRequest(ApiUrls.GET_ALL_PRODUCTS, dispatch, null, true, true);
        var allProducts: [] = allProductResponse.body;
        
        if (allProducts.length === 0) {
            dispatch(SetUserMessageSuccessAction(getLabel('product_search_result_none')));
        }
        else if (allProducts.length === 1) {
            dispatch(SetUserMessageSuccessAction(getLabel('product_search_result_one')));
        }
        else {
            dispatch(SetUserMessageSuccessAction(getLabel('product_search_result_many', { totalCount: allProducts.length })));
        }
        setAllProducts(allProducts);
    }

    const addProgramProduct = async (product: number) => {
        const data = {
            ProgramId: program.id,
            ProductId: product
        };
        const updateResponse = await makeJSONPostRequest(ApiUrls.ADD_PRODUCT_TO_PROGRAM, dispatch, data, true, true);
        await getProgramProducts();
        if (updateResponse.response.ok) {
            dispatch(SetUserMessageSuccessAction('product_success_included_text'));
            dispatch(ClearNextCycleAction());
        }
    }

    const completeRemoveProduct = async () => {
        setShowRemoveDialog(false);
        const data = {
            ProgramId: program.id,
            ProductId: removeDialogProduct?.id
        };
        const updateResponse = await makeJSONPostRequest(ApiUrls.REMOVE_PRODUCT_FROM_PROGRAM, dispatch, data, true, true)
        await getProgramProducts();
        if (updateResponse.response.ok) {
            dispatch(SetUserMessageSuccessAction('product_success_excluded_text'));
            dispatch(ClearNextCycleAction());
        }
    }

    const cancelRemoveProduct = () => {
        setShowRemoveDialog(false);
    }

    const handleRemoveProduct = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, product: Product) => {
        event.stopPropagation();
        setRemoveDialogProduct(product);
        setShowRemoveDialog(true);
    }

    return (
        <Card className="program-product">
            <DialogModal id="removeProductDialog" title={getLabel("remove_product_dialog_title")} onClickLeft={cancelRemoveProduct} onClickRight={completeRemoveProduct}
                open={showRemoveDialog} labelLeft={getLabel("remove_product_dialog_cancel")} labelRight={getLabel("remove_product_dialog_remove")} />
            <CardContent>
                <List id="resultList">
                    {allProducts.map((allProduct: Product) => <Paper key={allProduct.id} elevation={2}> <ListItem className="row">
                        <div className="product-list-row truncate">
                            <div>
                                <div className="product-name">{allProduct.name}</div>
                            </div>
                            <div>
                                {!products.some((n: any) => n.id === allProduct.id) && <APIButton className="button-add green-button product-button" type="button" variant="contained" color="primary" disabled={!canManageProduct || !program.active} onClick={(e: any) => addProgramProduct(allProduct.id)}>{getLabel("program_product_list_add_button_text")}</APIButton>}
                                {products.some((n: any) => n.id === allProduct.id) && <APIButton className="button-remove red-button product-button" type="button" variant="contained" color="primary" disabled={!canManageProduct || !program.active} onClick={(e: any) => handleRemoveProduct(e, allProduct)}>{getLabel("program_product_list_remove_button_text")}</APIButton>}
                            </div>
                        </div>
                    </ListItem></Paper>)}
                </List>
            </CardContent>
        </Card>
    );
};
