import React, { useState, useEffect } from 'react';
import { CardContent, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import './AccountableCareNetworkPrograms.css';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { ACN, Product, Program } from '../../interfaces/ApiInterfaces';
import { ACNProgramAssociationButton } from './ACNProgramAssociationButton';
import { ClearUserMessageAction } from '../../actions/userMessageAction';

interface Props {
    acn: ACN;
    canMakeChangesForACN: boolean;
}

export const AccountableCareNetworkPrograms: React.FC<Props> = ({acn, canMakeChangesForACN}) => {
    const dispatch = useDispatch();
    const [allProducts, setAllProducts] = useState<Product[]>();
    const [allPrograms, setAllPrograms] = useState<Program[]>();
    const [acnPrograms, setAcnPrograms] = useState<Program[]>();
    const canManageACNPrograms = hasPermissions(Permissions.CAN_JOIN_LEAVE_PROGRAMS) && canMakeChangesForACN;
    const [isAssociationActionInProgress, setIsAssociationActionInProgress] = useState(false);

    useEffect(() => {
        getAllProducts();
    }, []);

    useEffect(() => {
        getPrograms();
    }, [acn.id]);

    const getAllProducts = async () => {
        const isInitialLoad = !allProducts;
        const allProductsResponse: Product[] = (await makeJSONGetRequest(ApiUrls.GET_ALL_PRODUCTS, dispatch, null, isInitialLoad, isInitialLoad)).body;
        setAllProducts(allProductsResponse);
        if (isInitialLoad) dispatch(ClearUserMessageAction());
    };

    const getPrograms = async () => {
        const isInitialLoad = !acnPrograms;
        const allProgramsWithProductsResponse = await makeJSONPostRequest(ApiUrls.GET_PROGRAMS_AND_PRODUCTS, dispatch, null, isInitialLoad, isInitialLoad);
        const acnProgramsWithProductsResponse = await makeJSONPostRequest(createUrl(ApiUrls.GET_ACN_PROGRAMS_AND_PRODUCTS, {acnId: acn.id}), dispatch, null, isInitialLoad, isInitialLoad);
        setAllPrograms(allProgramsWithProductsResponse.body);
        setAcnPrograms(acnProgramsWithProductsResponse.body);
        if (isInitialLoad) dispatch(ClearUserMessageAction());
    };

    const isCellAssociated = (product: Product, program: Program): boolean | null => {
        if (!program.products.some(programProduct => programProduct.id == product.id)) {
            return null;
        }
        var acnProgram = acnPrograms!.find(acnProgram => acnProgram.id === program.id);
        return !!acnProgram && acnProgram.products.some(acnProgramProduct => acnProgramProduct.id === product.id);
    };

    function cssDisabled(product: Product, program: Program): string {
        const isDisabled = isCellAssociated(product, program) == null;
        return isDisabled ? 'disabled' : '';
    }

    return (
        (!!allProducts && !!allPrograms && !!acnPrograms)
            ? <Card className="acn-programs">
                <CardContent>
                    <TableContainer className="acn-program-product-association-table">
                        <Table>
                            <TableHead>
                                <TableRow className="association-header">
                                    <TableCell className="acn-program-product-association-col1"/>
                                    {allProducts.map(product => <TableCell key={product.id} className="acn-program-product-association-header-product" align="center">
                                        {product.name}
                                    </TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody className="truncate">
                                {allPrograms.map(program => <TableRow key={program.id}>
                                    <TableCell className="acn-program-product-association-col1">
                                        <Tooltip arrow title={program.description}><div>{program.name}</div></Tooltip>
                                    </TableCell>
                                    {allProducts.map(product => <TableCell key={product.id} className={`acn-program-product-association-product-action ${cssDisabled(product, program)}`} align="center">
                                        <ACNProgramAssociationButton acn={acn}
                                            product={product} program={program} isAssociated={isCellAssociated(product, program)}
                                            disabled={!canManageACNPrograms || isAssociationActionInProgress} onChange={getPrograms}
                                            setIsAssociationActionInProgress={setIsAssociationActionInProgress}
                                        />
                                    </TableCell>)}
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            : <></>
    );
}
