import { Button } from "@mui/material";
import React, { ReactNode } from "react";
import { FileInput } from "../file.input/FileInput";
import { getLabel } from "../label/Label.library";
import './ImportFileInput.css';
import { TextInput } from "../text.input/TextInput";

interface Props {
    name: string;
    notes: string;
    clear: boolean;
    onCancel: Function;
    disableSubmit: boolean;
    disable?: boolean;
    children?: ReactNode;
}

export const ImportFileInput: React.FC<Props> = (props) => {
    return (
        <div className="import-file-input truncate">
            <FileInput name={props.name} label="import_file_label" clear={props.clear} disabled={props.disable} />
            <TextInput name={props.notes} label="import_notes_label" fullwidth={false} multiline={true} disabled={props.disable} />
            {props.children}
            <Button className="button" type="button" variant="contained" color="primary" onClick={() => props.onCancel()} disabled={props.disable}>
                {getLabel('import_cancel_button_label')}
            </Button>
            <Button className="red-button button" type="submit" variant="contained" color="primary" disabled={props.disableSubmit || props.disable}>
                {getLabel('import_submit_button_label')}
            </Button>
        </div>
    );
};
