import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated, hasPermissions, getAuthenticatedHomePage } from '../../../services/auth/auth';
import { ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { useDispatch } from 'react-redux';
import { SetLoginRedirectAction } from '../../../actions/loginRedirectAction';

export const PrivateRoute = ({ Component, permission }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const renderRoute = () => {
    if (isAuthenticated()) {
      return (permission && !hasPermissions(permission))
        ? <Navigate to={{ pathname: getAuthenticatedHomePage() }} replace />
        : <Component />;
    }

    dispatch(SetLoginRedirectAction(location.pathname+location.search));
    return <Navigate to={{ pathname: ApplicationRoutes.LOGIN }} replace />;
  };

  return renderRoute();
};
