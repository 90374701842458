import { Card, CardContent, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { ProviderDesignation } from "../../../../interfaces/ApiInterfaces";
import { getLabel } from "../../../common/label/Label.library";
import './ViewDesignationAttributes.css';

interface Props {
    designation: ProviderDesignation;
}

export const ViewDesignationAttributes: React.FC<Props> = ({designation}) => {
    return (
        <Card>
            <CardContent>
                <div className="view-only viewDesignation grid">
                    <div>
                        <span><div className="colHeader">{getLabel('provider_designation_attributes_type_label')}</div><div>{designation.designationType.name}</div></span>
                        <span><div className="colHeader">{getLabel('provider_designation_attributes_source_label')}</div><div>{designation.designationSource.name}</div></span>
                        <span><div className="colHeader">{getLabel('provider_designation_attributes_start_label')}</div><div>{designation.startDate}</div></span>
                        <span><div className="colHeader">{getLabel('provider_designation_attributes_end_label')}</div><div>{designation.endDate}</div></span>
                        <div><FormControlLabel label={getLabel('provider_designation_attributes_active_label')} control={<Checkbox color="primary" checked={designation.active} disabled />} /></div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
