import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, List, ListItem, Paper } from '@mui/material';
import { ACN, User } from '../../../interfaces/ApiInterfaces';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { makeJSONPostRequest } from '../../../services/ajax/ajax';
import { useDispatch, useSelector } from 'react-redux';
import { getLabel } from '../../common/label/Label.library';
import cloneDeep from "lodash/cloneDeep";
import './UserAccountableCareNetworks.css';
import { SetUserMessageSuccessAction, ClearUserMessageAction } from '../../../actions/userMessageAction';
import { SearchDefaults } from '../../../constants/SearchDefaults';
import { getUserId, hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { APIButton } from '../../common/button/APIButton';
import { formatPhone } from '../../../services/format/format';
import { Formik } from 'formik';
import { ClearUserACNsSearchAction, UserACNsSearch, SetUserACNsSearchAction } from '../../../actions/userACNsSearchAction';
import { AppState } from '../../../store/configureStore';
import { userACNsSearchValues } from '../../../reducers/rootReducer';
import { TextInput } from '../../common/text.input/TextInput';
import { PagingFooter } from '../../common/paging/PagingFooter';
import { SetSuccessMessageAction } from '../../../actions/successMessageAction';
import { DialogModal } from '../../common/dialog.modal/DialogModal';
import { ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { useNavigate } from 'react-router-dom';

export const UserACNsMessagesOnLoad = 2; // the initial "working"... and "<x> matching ACNs found"

interface Props {
    user: User;
    updateUser: any;
}

export const UserAccountableCareNetworks: React.FC<Props> = ({ user, updateUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ACNs, setACNs] = useState<ACN[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, UserACNsSearch>(userACNsSearchValues);
    const canUpdateUser = hasPermissions(Permissions.CAN_UPDATE_USER);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [acnToRemove, setAcnToRemove] = useState<number>(-1);

    const getACNs = async (values: any, newPage: number, showSucessMessage: boolean = true) => {
        setACNs([]);
        setTotalResults(-1);
        const data: any = {
            ACNName: values.acnName,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "ACNName",
            OrderByDir: "ASC"
        };
        if (!data.ACNName) delete data.ACNName;
        if (!canUpdateUser) data.UserId = user.id;

        // A logged in user who can't update associations should only see this user's existing associations
        const response = await makeJSONPostRequest(canUpdateUser ? ApiUrls.GET_ACNS : ApiUrls.GET_USER_ACNS, dispatch, data, true, true);

        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('acn_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('acn_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('acn_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setACNs(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getACNs(searchValues, page, false);
    };

    const handleAdd = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, acnId: number) => {
        event.stopPropagation();
        await makeJSONPostRequest(createUrl(ApiUrls.CREATE_USER_ACN_ASSOCIATION, { userId: user.id, acnId }), dispatch, null, true, true);

        var newUser = cloneDeep(user);
        newUser.acnIds.push(acnId);
        updateUser(newUser);

        dispatch(SetUserMessageSuccessAction('user_acn_success_create_association_text'));
    };

    const handleRemove = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, acnId: number) => {
        event.stopPropagation();
        var acnIds = ACNs.map(acn => acn.id);
        if (user.acnIds.filter(value => acnIds.includes(value)).length == 1 && !hasPermissions(Permissions.CAN_VIEW_ALL_ACNS) && !user.isCreator) {
            setAcnToRemove(acnId);
            setShowRemoveDialog(true);
        }
        else {
            await makeJSONPostRequest(createUrl(ApiUrls.REMOVE_USER_ACN_ASSOCIATION, { userId: user.id, acnId }), dispatch, null, true, true);

            var newUser = cloneDeep(user);
            const indexOfACNToRemove = newUser.acnIds.findIndex(associatedAcnId => associatedAcnId === acnId);
            if (indexOfACNToRemove !== -1) {
                newUser.acnIds.splice(indexOfACNToRemove, 1);
                updateUser(newUser);
            }

            dispatch(SetUserMessageSuccessAction('user_acn_success_remove_association_text'));
        }
    };

    const performSearch = async (values: any) => {
        dispatch(SetUserACNsSearchAction(values.ACNName));
        await getACNs({ acnName: values.ACNName }, 1, true);
    }

    const clearSearchFilters = async () => {
        dispatch(ClearUserACNsSearchAction());
        await getACNs({ acnName: '' }, 1, true);
    }

    useEffect(() => {
        getACNs(searchValues, 1, true);
    }, []);

    const completeRemove = (async () => {
        setShowRemoveDialog(false);
        await makeJSONPostRequest(createUrl(ApiUrls.REMOVE_USER_ACN_ASSOCIATION, { userId: user.id, acnId: acnToRemove }), dispatch, null, true, true);
        dispatch(SetSuccessMessageAction('user_acn_success_remove_association_text'));
        navigate(ApplicationRoutes.USERS);
    })

    const cancelRemove = (() => {
        setShowRemoveDialog(false);
    })

    return (
        <Card>
            <DialogModal id="removeDialog" open={showRemoveDialog} labelLeft={getLabel('remove_acn_cancel_button')} labelRight={getLabel('remove_acn_remove_button')} onClickLeft={cancelRemove} onClickRight={completeRemove}
                title={getLabel('remove_acn_remove_dialog_message')}></DialogModal>
            <CardContent className="user-acns">
                <Formik enableReinitialize={true}
                    initialValues={{ ACNName: searchValues.acnName }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        performSearch(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <TextInput name="ACNName" label="acn_filter_name" fullwidth={false} />
                            </div>
                            <div></div>
                            <div className="flexRow">
                                <span className="flexFill"></span>
                                <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
                <List id="acnList">
                    {ACNs.map((acn: ACN) =>
                        <Paper key={acn.id} elevation={2}>
                            <ListItem className="row" >
                                <div className="user-acn-row truncate">
                                    <div>
                                        <div className="name">{acn.acnName}</div>
                                        {acn.contactName &&
                                        <div className="user-acn-row-data truncate">
                                            <span><div className="colHeader">{getLabel("acn_list_address_header")}</div><div>{acn.address1}</div></span>
                                            <span><div className="colHeader">{getLabel("acn_list_city_header")}</div><div>{acn.city}</div></span>
                                            <span><div className="colHeader">{getLabel("acn_list_state_header")}</div><div>{acn.state}</div></span>
                                            <span><div className="colHeader">{getLabel("acn_list_zip_code_header")}</div><div>{acn.zipCode}</div></span>
                                            <span><div className="colHeader">{getLabel("acn_list_telephone_header")}</div><div>{formatPhone(acn.telephone)}</div></span>
                                            <span><div className="colHeader">{getLabel("acn_list_email_header")}</div><div>{acn.email}</div></span>
                                        </div>}
                                        {acn.secondContactName &&
                                        <div className="user-acn-row-data truncate">
                                            <span><div>{acn.secondContactAddress1}</div></span>
                                            <span><div>{acn.secondContactCity}</div></span>
                                            <span><div>{acn.secondContactState}</div></span>
                                            <span><div>{acn.secondContactZipCode}</div></span>
                                            <span><div>{formatPhone(acn.secondContactTelephone)}</div></span>
                                            <span><div>{acn.secondContactEmail}</div></span>
                                        </div>}
                                    </div>
                                    <div className="user-acn-buttons">
                                        {canUpdateUser && (hasPermissions(Permissions.CAN_ACT_FOR) || user.id !== getUserId()) &&
                                            <>
                                                {user.acnIds.includes(acn.id)
                                                    ? <APIButton className="red-button button" type="button" variant="contained" color="primary" disabled={!user.active} onClick={(e: any) => handleRemove(e, acn.id)}>{getLabel("user_acn_list_remove")}</APIButton>
                                                    : <APIButton className="green-button button" type="button" variant="contained" color="primary" disabled={!user.active} onClick={(e: any) => handleAdd(e, acn.id)}>{getLabel("user_acn_list_add")}</APIButton>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </ListItem>
                        </Paper>
                    )}
                </List>
                {totalResults === 0 && <p className="paging">{getLabel("user_acn_search_result_none")}</p>}
                {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
            </CardContent>
        </Card>
    );
};
