import React, { useState, useEffect } from 'react';
import { getLabel } from '../common/label/Label.library';
import { Button, CardContent, Card, List, ListItem, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import './CyclePrograms.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { ClearUserMessageAction, SetUserMessageSuccessAction, SetUserMessageErrorAction } from '../../actions/userMessageAction';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { Cycle, Program, ProgramRule } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { TabValue } from '../../constants/TabValue';
import { APIButton } from '../common/button/APIButton';
import { PagingFooter } from '../common/paging/PagingFooter';
import { ProgramRuleTypes } from '../../constants/ProgramRuleTypes';
import { ClearNextCycleAction } from '../../actions/nextCycleAction';

interface Props {
    cycle: Cycle
}

export const CyclePrograms: React.FC<Props> = ({cycle}) => {
    const [programs, setPrograms] = useState<Program[]>([]);
    const [programRules, setProgramRules] = useState<ProgramRule[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const canSeeProgramDetails = hasPermissions(Permissions.CAN_SEE_PROGRAMS_PAGE);

    useEffect(() => {
        getPrograms(1, true);
        if (canSeeProgramDetails) {
            getProgramRules();
        }
    }, [cycle.id]);

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getPrograms(page, false);
    };

    const removeProgram = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, programId: number) => {
        event.stopPropagation();
        try {
            const response = await makeJSONPostRequest(createUrl(ApiUrls.CYCLE_REMOVE_PROGRAM, { programId, cycleId: cycle.id }), dispatch, null);

            if (response.response.ok) {
                await getPrograms(page, false);
                if (canSeeProgramDetails) {
                    await getProgramRules();
                }
                dispatch(SetUserMessageSuccessAction('program_success_remove_text'));
                dispatch(ClearNextCycleAction());
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('program_failed_save_unauthorized'));
        }
    }

    const getPrograms = async (newPage: number, showSucessMessage: boolean = true) => {
        setPrograms([]);
        setTotalResults(-1);
        const data = {
            id: cycle.id,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_CYCLE_PROGRAMS, dispatch, data, true, true);

        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('program_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('program_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('program_search_result_many', { totalCount: response.body.totalCount })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setPrograms(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const getProgramRules = async () => {
        const url = createUrl(ApiUrls.GET_CYCLE_PROGRAM_RULES, { cycleId: cycle.id });
        const response = await makeJSONGetRequest(url, dispatch, null, false, false);
        setProgramRules(response.body);
    }

    const findMorePrograms = () => {
        navigate(
            createRoute(ApplicationRoutes.PROGRAMS),
            {state: { clearFilters: true }}
        );
    }

    const selectProgram = (index: number) => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: index, tab: TabValue.PROGRAM_ATTRIBUTES }));
    }

    const getRuleAssociatedPrograms = (ruleType: string, programId: number): string => {
        const associatedRules = programRules.filter(rule => rule.value === ruleType && (rule.programId === programId || rule.otherProgramId === programId));
        const associatedProgramNames = associatedRules.map(associatedRule => associatedRule.programId === programId ? associatedRule.otherProgramName : associatedRule.programName);
        return associatedProgramNames.length === 0 ? '' : associatedProgramNames.join(', ');
    }

    return (
        <Card>
            <CardContent>
                <div className="cyclePrograms">
                    {canSeeProgramDetails && <Button id="toPrograms" className="link" color="primary" onClick={findMorePrograms}>{getLabel('find_more_programs_label')}</Button>}
                    <List id="resultList">
                        {programs.map((program: { id: any; name: any; description: any; externalId: any; active: any;}) => 
                            <Paper key={program.id} elevation={2}> <ListItem className="row" button={canSeeProgramDetails as any}
                                onClick={canSeeProgramDetails ? () => selectProgram(program.id) : undefined}>
                                <div className="program-list-row truncate">
                                    <div>
                                        <div className="name">{program.name}</div>
                                        <div className="program-row-data truncate">
                                            <span style={{ gridColumn: !canSeeProgramDetails ? "span 3" : undefined }}>
                                                <div className="colHeader">{getLabel("program_list_description_header")}</div>
                                                <div>{program.description}</div>
                                            </span>
                                            {canSeeProgramDetails &&
                                                <>
                                                    <span>
                                                        <div className="colHeader">{ProgramRuleTypes.MUTUALLY_EXCLUSIVE.name}</div>
                                                        <div>{getRuleAssociatedPrograms(ProgramRuleTypes.MUTUALLY_EXCLUSIVE.value, program.id)}</div>
                                                    </span>
                                                    {/*
                                                    <span>
                                                        <div className="colHeader">{ProgramRuleTypes.EXCLUSIVE_PER_LOCATION.name}</div>
                                                        <div>{getRuleAssociatedPrograms(ProgramRuleTypes.EXCLUSIVE_PER_LOCATION.value, program.id)}</div>
                                                    </span>
                                                    */}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {program.active && cycle.isOpen && !cycle.locked &&
                                            <APIButton id="deactivate" className="red-button" type="button" variant="contained" color="primary"
                                                onClick={(e: any) => removeProgram(e, program.id)} disabled={!hasPermissions(Permissions.CAN_UPDATE_CYCLE)}>{getLabel('edit_cycle_remove_label')}</APIButton>
                                        }
                                    </div>
                                </div>
                            </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("cycle_program_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </CardContent>
        </Card>
    )
}
