export interface ProgramSearch {
  name: string;
  description: string;
  includeInactive: boolean;
}

export const SET_PROGRAM_SEARCH = "SET_PROGRAM_SEARCH";
export const CLEAR_PROGRAM_SEARCH = "CLEAR_PROGRAM_SEARCH";

interface SetProgramSearch {
  type: typeof SET_PROGRAM_SEARCH;
  programSearch: ProgramSearch;
}

interface ClearProgramSearch {
  type: typeof CLEAR_PROGRAM_SEARCH;
}

export type ProgramSearchAction = SetProgramSearch | ClearProgramSearch;

export const SetProgramSearchAction = (
    name: string,
    description: string,
    includeInactive: boolean
  ): SetProgramSearch => ({
  type: SET_PROGRAM_SEARCH,
  programSearch: {
    name: name,
    description: description,
    includeInactive: includeInactive
  }
});

export const ClearProgramSearchAction = (): ClearProgramSearch => ({
  type: CLEAR_PROGRAM_SEARCH
});