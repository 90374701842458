import { Cycle } from "../interfaces/ApiInterfaces";

export const SET_NEXT_CYCLE_LOADING = "SET_NEXT_CYCLE_LOADING";
export const SET_NEXT_CYCLE = "SET_NEXT_CYCLE";
export const CLEAR_NEXT_CYCLE = "CLEAR_NEXT_CYCLE";

interface SetNextCycleLoading {
    type: typeof SET_NEXT_CYCLE_LOADING;
    acnId: number;
}

interface SetNextCycle {
    type: typeof SET_NEXT_CYCLE;
    cycle: Cycle;
}

interface ClearNextCycle {
    type: typeof CLEAR_NEXT_CYCLE;
}

export type NextCycleActionTypes = SetNextCycleLoading | SetNextCycle | ClearNextCycle;

export const SetNextCycleLoadingAction = (acnId: number): SetNextCycleLoading => ({
    type: SET_NEXT_CYCLE_LOADING,
    acnId: acnId
});

export const SetNextCycleAction = (cycle: Cycle): SetNextCycle => ({
    type: SET_NEXT_CYCLE,
    cycle: cycle
});

export const ClearNextCycleAction = (): ClearNextCycle => ({
    type: CLEAR_NEXT_CYCLE
});