import React, { useState, useEffect} from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper } from '@mui/material';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { StatesDropdown } from '../../components/common/states.dropdown/StatesDropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { ClearACNSearchAction, ACNSearch, SetACNSearchAction } from '../../actions/acnSearchAction';
import { acnSearchValues, successMessageValue } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import './AccountableCareNetworks.css';
import { TabValue } from '../../constants/TabValue';
import { ACN } from '../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { InfoTitles } from '../../constants/InfoTitles';
import { formatPhone } from '../../services/format/format';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { ClearSuccessMessageAction } from '../../actions/successMessageAction';
import { useNavigate } from 'react-router-dom';

export const AccountableCareNetworks: React.FC<any> = (props) => {
  const [ACNs, setACNs] = useState<ACN[]>([]);
  const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(0);
  const [page, setPage] = useState(0);
  const searchValues = useSelector<AppState, ACNSearch>(acnSearchValues);
  const successMessage = useSelector<AppState, string>(successMessageValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title =`${getLabel('app_name')} ${getLabel('title_list_accountable_care_networks')}`
  }, [])

  useEffect(() => {
    getACNs(searchValues, true, true);
  },[])

  async function performSearch(values: any) {
      dispatch(SetACNSearchAction(values.ACNName, values.City, values.State, values.IncludeInactive));
      await getACNs({ acnName: values.ACNName, city: values.City, state: values.State, includeInactive: values.IncludeInactive}, true, true);
  }

  async function clearSearchFilters() {
      dispatch(ClearACNSearchAction());
      await getACNs({ ACNName: '', city: '', state: '', includeInactive: false}, true, true);
  }

  async function getACNs(values: any, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) {
    const data = {
      ACNName: values.acnName,
      City: values.city,
      State: values.state,
      IncludeInactive: values.includeInactive,
      Take: SearchDefaults.TAKE,
      OrderBy: "ACNName",
      OrderByDir: "ASC"
    };
    if (!data.City) delete data.City;
    if (!data.ACNName) delete data.ACNName;
    if (!data.State) delete data.State;
    const response = await makeJSONPostRequest(ApiUrls.GET_ACNS, dispatch, data, showSucessMessage, showWorkingMessage);

    if (showSucessMessage) {
        if (!!successMessage) {
            dispatch(SetUserMessageSuccessAction(successMessage));
        }
      else if (response.body.totalCount === 0) {
        dispatch(SetUserMessageSuccessAction(getLabel('acn_search_result_none')));
      }
      else if (response.body.totalCount === 1) {
        dispatch(SetUserMessageSuccessAction(getLabel('acn_search_result_one')));
      }
      else {
        dispatch(SetUserMessageSuccessAction(getLabel('acn_search_result_many', {
          totalCount: response.body.totalCount,
        })));
        }
    }
    dispatch(ClearSuccessMessageAction());
    setACNs(response.body.result);
    setTotalResults(response.body.totalCount);
    setSkip(response.body.skip);
    setTake(response.body.take);
    setPage(1);
  }

  const selectACN = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, acn: ACN) => {
    navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acn.id, tab: TabValue.ACN_ATTRIBUTES }))
  }

  const createNewACN = () => {
    navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: -1, tab: TabValue.ACN_ATTRIBUTES }))
  }

  const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    await updatePage(value);
  };

  async function updatePage(value: number) {
    setPage(value);
    setACNs([]);
    setTotalResults(-1);
    const data: any = {
      ACNName: searchValues.acnName,
      City: searchValues.city,
      State: searchValues.state,
      IncludeInactive: searchValues.includeInactive,
      skip: skip + ((value - page) * take),
      take: take,
      OrderBy: "ACNName",
      OrderByDir: "ASC"
    };
    if (!data.City) delete data.City;
    if (!data.ACNName) delete data.ACNName;
    if (!data.State) delete data.State;
    const response = await makeJSONPostRequest(ApiUrls.GET_ACNS, dispatch, data);
    dispatch(ClearUserMessageAction());
    setACNs(response.body.result);
    setTotalResults(response.body.totalCount);
    setSkip(response.body.skip);
    setTake(response.body.take);
    console.log(ACNs);
  };

  return (
    <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_ACNS}>
      <Container maxWidth={false} className="accountable-care-networks">
        <h2>{getLabel("acn_page_heading")}</h2>
        <Card>
          <CardContent>
            <Formik enableReinitialize={true}
              initialValues={{ ACNName: searchValues.acnName, City: searchValues.city, State: searchValues.state, IncludeInactive: searchValues.includeInactive}}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, actions) => {
                performSearch(values).finally(() => {
                    actions.setSubmitting(false);
                });
              }}>
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="search-filter-fields">
                    <TextInput name="ACNName" label="acn_filter_name" fullwidth={false} />
                    <TextInput name="City" label="acn_filter_city" fullwidth={false} />
                    <StatesDropdown name="State" label="acn_filter_state" />
                  </div>
                  <div className="search-filter-buttons">
                    <CheckboxInput name="IncludeInactive" label="acn_filter_include_inactive"/>
                    <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => {clearSearchFilters(); props.resetForm();}}>{getLabel('clear_search_filter_button_label')}</Button>
                    <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                  </div>
                </form>)}
            </Formik>
          </CardContent>
        </Card>
        <div className="acn-list-buttons">
          {hasPermissions(Permissions.CAN_CREATE_ACN) && <Button id="addButton" variant="contained" color="primary" onClick={createNewACN}>Add</Button>}
          {hasPermissions(Permissions.CAN_VIEW_IMPORT_ACN_DATA) &&
                <Button className="red-button" type="button" variant="contained" color="primary" onClick={() => { navigate(createRoute(ApplicationRoutes.IMPORT_ACNS)) }}>
                    {getLabel('acn_import_acn_button')}
                </Button>
          }
        </div>
        <div className="accountable-care-networks-list">
          <List id="resultList">
            {ACNs.map((acn: ACN) => <Paper key={acn.id}> <ListItem className="row truncate" button onClick={(event) => selectACN(event, acn)}>
                {acn.active && <div className="name">{acn.acnName}</div>}
                {!acn.active && <div className="name inactive-result">{getLabel('inactive_name', { name: acn.acnName })}</div>}
                {acn.contactName &&
                <div className="truncate grid">
                  <span><div className="colHeader">{getLabel("acn_list_address_header")}</div><div>{acn.address1}</div></span>
                  <span><div className="colHeader">{getLabel("acn_list_city_header")}</div><div>{acn.city}</div></span>
                  <span><div className="colHeader">{getLabel("acn_list_state_header")}</div><div>{acn.state}</div></span>
                  <span><div className="colHeader">{getLabel("acn_list_zip_code_header")}</div><div>{acn.zipCode}</div></span>
                  <span><div className="colHeader">{getLabel("acn_list_telephone_header")}</div><div>{formatPhone(acn.telephone)}</div></span>
                  <span><div className="colHeader">{getLabel("acn_list_email_header")}</div><div>{acn.email}</div></span>
                </div>}
                {acn.secondContactName &&
                <div className="truncate grid">
                  <span><div>{acn.secondContactAddress1}</div></span>
                  <span><div>{acn.secondContactCity}</div></span>
                  <span><div>{acn.secondContactState}</div></span>
                  <span><div>{acn.secondContactZipCode}</div></span>
                  <span><div>{formatPhone(acn.secondContactTelephone)}</div></span>
                  <span><div>{acn.secondContactEmail}</div></span>
                </div>}
            </ListItem></Paper>)}
          </List>
                  {totalResults === 0 && <p className="paging">{getLabel("acn_search_result_none")}</p>}
                  {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
        </div>
      </Container>
    </AuthenticatedLayout>
  );
};
