import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ProductAttributes } from '../../components/products/ProductAttributes';
import { ViewProductAttributes } from '../../components/products/ViewProductAttributes';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { getLabel } from '../../components/common/label/Label.library';
import { TabValue } from '../../constants/TabValue';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { Product } from '../../interfaces/ApiInterfaces';
import { InfoTitles } from '../../constants/InfoTitles';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';

export const EditProduct: React.FC<any> = (props) => {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const productId = +params.id!;
    const isAdd = productId === -1;
    const [product, setProduct] = useState<Product>();
    const canEdit = hasPermissions(Permissions.CAN_UPDATE_PRODUCT);

    useEffect(() => {
        document.title = isAdd ? `${getLabel('app_name')} ${getLabel('title_add_product')}` : `${getLabel('app_name')} ${getLabel('title_edit_product')}`
    }, [])

    useEffect(() => {
        if (!isAdd) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_PRODUCT, { productId }), dispatch, null, false, false).then((productResponse) => {
                setProduct(productResponse.body);
            });
        }
        else {
            setProduct({ id: -1, active: true, name: '', exportName: '', rosterImportName: '', providerImportName: '' });
        }
    }, [productId]);

    useEffect(() => {
        if (params.tab === TabValue.PRODUCT_ATTRIBUTES) {
            setTabValue(0);
        } else if (params.tab === TabValue.PRODUCT_ACNS) {
            setTabValue(1);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_PRODUCT, { id: productId, tab: TabValue.PRODUCT_ATTRIBUTES }))
            setTabValue(0);
        }
    }, [productId, params.tab]);

    function getAddEditViewLabel() {
        var label;
        if (isAdd) {
            label = getLabel('product_add_page_heading');
        } else {
            label = getLabel(canEdit
                ? 'product_edit_page_heading'
                : 'product_view_page_heading', { name: product!.name });
        }
        return label;
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_PRODUCT;
        }
        else {
            return InfoTitles.PRODUCT_ACNS;
        }
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_PRODUCT, { id: productId, tab: TabValue.PRODUCT_ATTRIBUTES }))
        } else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_PRODUCT, { id: productId, tab: TabValue.PRODUCT_ACNS }))
        }
        setTabValue(value);
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="user-attributes-container">
                {!!product &&
                    <>
                        <h2><PageHeading to={ApplicationRoutes.PRODUCTS} parentHeading={getLabel('product_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="user-attributes-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} aria-label="simple tabs example" indicatorColor="primary"
                                    textColor="primary">
                                    <Tab label={getLabel('tab_label_attributes')} value={0} />
                                </Tabs>
                            </AppBar>
                            {tabValue === 0 && (canEdit ? (<ProductAttributes product={product} />) : (<ViewProductAttributes product={product} />))}
                        </Card>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};