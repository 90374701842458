import React from 'react';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import './DialogModal.css';
import { APIButton } from '../button/APIButton';

interface Props {
    open: boolean;
    onClickLeft?: any;
    labelLeft?: string;
    onClickRight: any;
    labelRight: string;
    onClickMiddle?: any;
    labelMiddle?: string;
    id: string;
    title: string;
    extraOptions?: { [label: string]: any };
}

export const DialogModal: React.FC<Props> = ({ open, onClickLeft, onClickRight, labelLeft, labelRight, id, title, onClickMiddle = null, labelMiddle = null, extraOptions = {} }) => {

    return (
        <Dialog open={open} id={id} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
            <DialogTitle id="dialog-title">
                {title.split("\n").map((line, index) =>
                    <React.Fragment key={line}>
                        {index !== 0 ? <br /> : null}
                        {line}
                    </React.Fragment>
                )}
            </DialogTitle>
            <DialogActions id="dialog-buttons">
                {Object.keys(extraOptions).map(label =>
                    <APIButton key={label} className="button green-button" variant="contained" color="primary" onClick={(e: any) => extraOptions[label](e)}>{label}</APIButton>)}
                {!!onClickLeft && <APIButton className="button" variant="contained" color="primary" onClick={(e: any) => onClickLeft(e)}>{labelLeft}</APIButton>}
                {!!onClickMiddle && <APIButton className="button" variant="contained" color="primary" onClick={(e: any) => onClickMiddle(e)}>{labelMiddle}</APIButton>}
                <APIButton className="button" variant="contained" color="primary" onClick={(e: any) => onClickRight(e)}>{labelRight}</APIButton>
            </DialogActions>
        </Dialog>
    );
};
