export class AffiliationStatus {
    public static readonly AFFILIATED = "AFFILIATED";
    public static readonly AVAILABLE = "AVAILABLE";
    public static readonly RELEASE_REQUESTED = "RELEASE_REQUESTED";
    public static readonly RELEASE_PENDING = "RELEASE_PENDING";
    public static readonly UNAVAILABLE = "UNAVAILABLE";
    public static readonly RECENTLY_APPROVED = "RECENTLY_APPROVED";
    public static readonly RECENTLY_DECLINED = "RECENTLY_DECLINED";
    public static readonly MIXED = "MIXED";
}

export function getStatuses(): any {
    return [{ name: AffiliationStatus.AFFILIATED, value: "Affiliated" }, { name: AffiliationStatus.RELEASE_REQUESTED, value: "Release Requested" },
        { name: AffiliationStatus.RELEASE_PENDING, value: "Release Pending" }, { name: AffiliationStatus.RECENTLY_APPROVED, value: "Recently Approved" },
        { name: AffiliationStatus.RECENTLY_DECLINED, value: "Recently Declined" }
    ];
}