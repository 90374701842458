import React from 'react';
import { getLabel } from '../label/Label.library';
import { InputLabel, Select, FormControl, FormHelperText } from '@mui/material';
import './SelectInput.css';
import { useField } from 'formik';

interface Props {
  name: string;
  label: string;
  values: any;
  fullwidth?: boolean;
  disabled?: boolean;
  blankOption?: boolean;
  shrink?: boolean;
  halfWidth?: boolean;
}

export const SelectInput: React.FC<Props> = ({ name, label, values, fullwidth = false, disabled = false, blankOption = true, shrink = false, halfWidth = false }) => {
  const [field, meta] = useField(name);

    return (
    <FormControl className={(halfWidth != null && halfWidth) ? 'select-input-halfwidth' : 'select-input'} variant="outlined" disabled={disabled}>
        <InputLabel id="label" className={(fullwidth != null && fullwidth) ? 'select-input-label' : 'select-input-label-padded'} error={meta.touched && Boolean(meta.error)} htmlFor={field.name}>{getLabel(label)}</InputLabel>
        <Select 
            className={
              (fullwidth != null && fullwidth) ? 'select-input-select' : 'select-input-select-padded'
            }
            native
            labelId="label"
            label={getLabel(label)}
            value={field.value}
            onChange={(event) => {
              field.onChange(event);
              field.onBlur(event);
            }}
            error={meta.touched && Boolean(meta.error)}
            id={field.name}
            name={field.name}>
                {blankOption && <option ></option>}
                <option hidden>{getLabel(label)}</option>
                {values}
        </Select>
        { meta.error && (<FormHelperText className="validateText">{meta.error}</FormHelperText>)}
    </FormControl>
  );
};
