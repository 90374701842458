export interface ACNSearch {
  acnName: string;
  city: string;
  state: string;
  includeInactive: boolean;
}

export const SET_ACN_SEARCH = "SET_ACN_SEARCH";
export const CLEAR_ACN_SEARCH = "CLEAR_ACN_SEARCH";

interface SetACNSearch {
  type: typeof SET_ACN_SEARCH;
  acnSearch: ACNSearch;
}

interface ClearACNSearch {
  type: typeof CLEAR_ACN_SEARCH;
}

export type ACNSearchAction = SetACNSearch | ClearACNSearch;

export const SetACNSearchAction = (acnName: string, city: string, state: string, includeInactive: boolean): SetACNSearch => ({
  type: SET_ACN_SEARCH,
  acnSearch: {acnName: acnName, city: city, state: state, includeInactive: includeInactive}
});

export const ClearACNSearchAction = (): ClearACNSearch => ({
  type: CLEAR_ACN_SEARCH
});