import React, { useState, useEffect } from 'react';
import { AppState } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { SetUserMessageSuccessAction, SetUserMessageErrorAction } from '../../actions/userMessageAction';
import { getLabel } from '../../components/common/label/Label.library';
import { groupSearchValues } from '../../reducers/rootReducer';
import { Button, ListItem, Paper, List, Container } from '@mui/material';
import { GroupSearch } from '../../components/groups/GroupSearch';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { TabValue } from '../../constants/TabValue';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { useActingForId } from '../../hooks/useActingFor';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { InfoTitles } from '../../constants/InfoTitles';
import { useNavigate } from 'react-router-dom';
import { GroupSearchValues } from '../../actions/groupSearchAction';
import './Groups.css';
import { Group } from '../../interfaces/ApiInterfaces';

export const Groups: React.FC<any> = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actingForId = useActingForId();
  const [groups, setGroups] = useState<Group[]>([]);
  const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
  const [page, setPage] = useState(0);
  const groupSearch = useSelector<AppState, GroupSearchValues>(groupSearchValues);

  useEffect(() => {
    document.title = `${getLabel('app_name')} ${getLabel('title_list_groups')}`;
  }, [])

  useEffect(() => {
    if (actingForId !== -1) getGroups(groupSearch, 1);
  }, [actingForId]);

  const getGroups = async (values: GroupSearchValues, newPage: number, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) => {
    const data: any = {
      Name: values.name || undefined,
      City: values.city || undefined,
      State: values.state || undefined,
      IncludeInactive: values.includeInactive,
      AccountableCareNetworkId: actingForId,
      Skip: (newPage - 1) * SearchDefaults.TAKE,
      Take: SearchDefaults.TAKE,
      OrderBy: "Name",
      OrderByDir: "ASC"
    }
    const response = await makeJSONPostRequest(ApiUrls.GET_GROUPS, dispatch, data, showSucessMessage, showWorkingMessage);

    if (showSucessMessage) {
      if (response.body.totalCount === 0) {
        dispatch(SetUserMessageSuccessAction(getLabel('group_search_result_none')));
      }
      else if (response.body.totalCount === 1) {
        dispatch(SetUserMessageSuccessAction(getLabel('group_search_result_one')));
      }
      else {
        dispatch(SetUserMessageSuccessAction(getLabel('group_search_result_many', {
          totalCount: response.body.totalCount,
        })));
      }
    }

    setGroups(response.body.result);
    setTotalResults(response.body.totalCount);
    setPage(newPage);
  };

  const createNewGroup = () => {
    if (actingForId === -1) {
      dispatch(SetUserMessageErrorAction(getLabel('group_edit_acn_required')));
      return;
    }
    navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: -1, tab: TabValue.GROUP_ATTRIBUTES }));
  };

  const selectGroup = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, groupId: number) => {
    var group = groups.find(group => group.id === groupId)!;
    navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: group.id, tab: TabValue.GROUP_ATTRIBUTES }));
  };

  const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
    await getGroups(groupSearch, page, true, true);
  };

  return (
    <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_GROUPS}>
      <Container maxWidth={false} className="user-attributes-container">
        <h2>{getLabel("group_search_page_heading")}</h2>
        <GroupSearch getGroups={getGroups} disabledSearch={actingForId === -1} />
        {hasPermissions(Permissions.CAN_CREATE_GROUP) &&
          <Button id="addButton" variant="contained" color="primary" onClick={createNewGroup} disabled={actingForId === -1}>{getLabel("group_list_add")}</Button>
        }
        <div>
          <List id="resultList">
            {
              groups.map(group => <Paper key={group.id}>
                <ListItem className="row" button onClick={(event) => selectGroup(event, group.id)}>
                  <div className="truncate">
                    {group.active && <div className="name">{group.name}</div>}
                    {!group.active && <div className="name inactive-result">{getLabel('inactive_name', { name: group.name })}</div>}
                    <div>
                      <span className="groupCol"><div className="colHeader">{getLabel("group_list_city_header")}</div><div>{group.city}</div></span>
                      <span className="groupCol"><div className="colHeader">{getLabel("group_list_state_header")}</div><div>{group.state}</div></span>
                      <span className="groupCol"><div className="colHeader">{getLabel("group_list_desc_header")}</div><div>{group.description}</div></span>
                    </div>
                  </div>
                </ListItem>
              </Paper>)
            }
          </List>
          {totalResults === 0 && <p className="paging">{getLabel("group_search_result_none")}</p>}
          {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
        </div>
      </Container>
    </AuthenticatedLayout>
  );
};
