import React, { useState, useEffect } from 'react';
import { getLabel } from '../common/label/Label.library';
import { CardContent, Card, List, ListItem, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import './SubgroupProviders.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { ApiUrls } from '../../constants/ApiUrls';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { SetUserMessageSuccessAction, ClearUserMessageAction } from '../../actions/userMessageAction';
import { Group, ListResultProvider } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { Permissions } from '../../constants/Permissions';
import { hasPermissions } from '../../services/auth/auth';
import { TabValue } from '../../constants/TabValue';
import { APIButton } from '../common/button/APIButton';
import { PagingFooter } from '../common/paging/PagingFooter';
import { getActingForDetails, useActingFor } from '../../hooks/useActingFor';
import { AppState } from '../../store/configureStore';
import { ClearSubgroupProviderSearchAction, SubgroupProviderSearch, SetSubgroupProviderSearchAction } from '../../actions/subgroupProviderSearchAction';
import { subgroupProviderSearchValues } from '../../reducers/rootReducer';
import { DialogModal } from '../common/dialog.modal/DialogModal';
import { SubgroupProvidersSearch } from '../providers/provider.search/SubgroupProvidersSearch';

interface Props {
    subgroup: Group;
}

interface SubgroupProvider extends ListResultProvider {
    isAddedToThisSubgroup : boolean;
}

export const SubgroupProviders: React.FC<Props> = ({ subgroup }) => {
    const [providers, setProviders] = useState<SubgroupProvider[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acnActingFor = useActingFor();
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const providerSearchValues = useSelector<AppState, SubgroupProviderSearch>(subgroupProviderSearchValues);
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState<SubgroupProvider>();
    const [existingSubgroup, setExistingSubgroup] = useState<Group>();
    const canManageProviders = hasPermissions(Permissions.CAN_MANAGE_PROVIDERS);

    const getProviders = async (values: any, newPage: number, showWorkingMessage: boolean = true, showSuccessMessage: boolean = true) => {
        setProviders([]);
        setTotalResults(-1);

        const data: any = {
            SubgroupId: subgroup.id,
            AccountableCareNetworkId: acnActingFor.id,
            FirstName: values.firstName ? values.firstName : null,
            LastName: values.lastName ? values.lastName : null,
            GroupNPI: values.groupNPI ? values.groupNPI : null,
            AlreadyAddedToThisSubgroupOnly: values.alreadyAddedOnly,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE
        };

        const response = await makeJSONPostRequest(ApiUrls.GET_SUBGROUP_PROVIDERS, dispatch, data, showWorkingMessage, showSuccessMessage);

        if (showSuccessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_many', { totalCount: response.body.totalCount })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setProviders(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const selectProvider = (index: number) => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: index, tab: TabValue.PROVIDER_ATTRIBUTES }));
    };

    const addProvider = async (event: React.ChangeEvent<unknown>, provider: SubgroupProvider) => {
        event.stopPropagation();
        const data = {
            GroupId: subgroup.id,
            ProviderId: provider.id,
            AccountableCareNetworkId: acnActingFor.id,
            ...getActingForDetails(acnActingFor)
        };
        const response = await makeJSONPostRequest(ApiUrls.SUBGROUP_ADD_MEMBERSHIP, dispatch, data, false, false);
        if (response.body != null) {
            setSelectedProvider(provider);
            setExistingSubgroup(response.body);
            setShowUpdateDialog(true);
        }
        else {
            await getProviders(providerSearchValues, page, false, false);
            dispatch(SetUserMessageSuccessAction('subgroup_add_provider_success_text'));
        }
    };

    const removeProvider = async (event: React.ChangeEvent<unknown>, providerId: number) => {
        event.stopPropagation();
        const data = {
            GroupId: subgroup.id,
            ProviderId: providerId,
            AccountableCareNetworkId: acnActingFor.id,
            ...getActingForDetails(acnActingFor)
        };
        const response = await makeJSONPostRequest(ApiUrls.SUBGROUP_END_MEMBERSHIP, dispatch, data);
        await getProviders(providerSearchValues, page, false, false);
        if (response.response.ok) {
            dispatch(SetUserMessageSuccessAction('subgroup_remove_provider_success_text'));
        }
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getProviders(providerSearchValues, page);
        dispatch(ClearUserMessageAction());
    };

    useEffect(() => {
        getProviders(providerSearchValues, 1);
    }, [acnActingFor.id])

    const cancelUpdateSubgroup = () => {
        setShowUpdateDialog(false);
        setSelectedProvider(undefined);
        setExistingSubgroup(undefined);
    };

    const updateSubgroup = async () => {
        setShowUpdateDialog(false);
        const data = {
            OldGroupId: existingSubgroup!.id,
            GroupId: subgroup.id,
            ProviderId: selectedProvider!.id,
            AccountableCareNetworkId: acnActingFor.id,
            ...getActingForDetails(acnActingFor)
        };
        await makeJSONPostRequest(ApiUrls.SUBGROUP_ADD_MEMBERSHIP, dispatch, data);
        await getProviders(providerSearchValues, page, false, false);
        dispatch(SetUserMessageSuccessAction('subgroup_add_provider_success_text'));
        setSelectedProvider(undefined);
        setExistingSubgroup(undefined);
    }

    async function performSearch(values: any) {
        dispatch(SetSubgroupProviderSearchAction(values.firstName, values.lastName, values.groupNPI, values.alreadyAddedOnly));
        await getProviders(values, 1, true, true);
    }

    async function clearSearchFilters() {
        dispatch(ClearSubgroupProviderSearchAction());
        await getProviders({ firstName: '', lastName: '', groupNPI: null, alreadyAddedOnly: false }, 1, true, true);
    }

    return (
        <>
            <DialogModal id="updateSubgroupDialog" title={getLabel("subgroup_provider_update_dialog_title", { provider: selectedProvider?.pcpFullName, existingSubgroup: existingSubgroup?.name, newSubgroup: subgroup.name })}
                onClickLeft={cancelUpdateSubgroup} onClickRight={updateSubgroup} open={showUpdateDialog} labelLeft={getLabel("subgroup_provider_update_dialog_cancel")} labelRight={getLabel("subgroup_provider_update_dialog_update")}
            />
            <Card>
                <CardContent>
                    <SubgroupProvidersSearch performSearch={performSearch} clearSearch={clearSearchFilters} disabled={acnActingFor.id === -1 || !acnActingFor.id} />
                    <div className="subgroupProviders">
                        <List id="resultList">
                            {providers.map((provider) => <Paper key={provider.id} elevation={2}> <ListItem className="row" button onClick={() => selectProvider(provider.id)}>
                                <div className="flexCenter provider-list-row">
                                    <div>
                                        <div className="name">{provider.pcpFullName}</div>
                                        <div className="provider-row-data">
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_pcp_credentials_header")}</div>
                                                <div>{provider.pcpCredentials}</div>
                                            </span>
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_specialty_header")}</div>
                                                <div>{provider.specialty}</div>
                                            </span>
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_pcp_npi_header")}</div>
                                                <div>{provider.pcpnpi}</div>
                                            </span>
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_pp_npi_header")}</div>
                                                <div>{provider.groupNPI}</div>
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        {provider.isAddedToThisSubgroup 
                                            ? <APIButton className="red-button button" type="button" variant="contained" color="primary" disabled={!canManageProviders} onClick={(event: any) => removeProvider(event, provider.id)}>{getLabel('subgroup_provider_remove_label')}</APIButton>
                                            : <APIButton className="green-button button" type="button" variant="contained" color="primary" disabled={!canManageProviders} onClick={(event: any) => addProvider(event, provider)}>{getLabel('subgroup_provider_add_label')}</APIButton>
                                        }
                                    </div>
                                </div>
                            </ListItem></Paper>)}
                        </List>
                        {totalResults === 0 && <p className="paging">{getLabel("subgroup_provider_search_result_none")}</p>}
                        {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                    </div>
                </CardContent>
            </Card>
        </>
    )
}
