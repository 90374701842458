import { CycleDropdown, CycleDropdownAction, SET_CYCLE_DROPDOWN } from '../actions/cycleDropdownAction';

const initialState: CycleDropdown = {
    cycles: null
};

export const cycleDropdown = (
    state: CycleDropdown = initialState,
    action: CycleDropdownAction
): CycleDropdown => {
    if (action.type === SET_CYCLE_DROPDOWN) {
        return { cycles: action.cycleDropdown.cycles};
    }
    return state;

};

export const getCycleDropdown = (state: CycleDropdown) => state.cycles;
