import React, { useState, useEffect } from 'react';
import { Card, CardContent, Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { Info } from '../../../interfaces/ApiInterfaces';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import './ViewInfo.css';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';

export const ViewInfo: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const infoId = +params.id!;
    const [info, setInfo] = useState<Info>({ id: -1, category: '', title: '', content: '' });

    useEffect(() => {
        if (infoId !== -1) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_INFO, { infoId }), dispatch, null, false, false).then((infoResponse) => {
                var info = infoResponse.body;
                setInfo({ id: info.id, category: info.category, title: info.title, content: info.content });
            }, () => navigate(ApplicationRoutes.INFO))
        }
    }, [infoId, dispatch]);

    return (
        <AuthenticatedLayout {...props}>
            <Container maxWidth={false}>
                <h2><PageHeading to={ApplicationRoutes.INFO} parentHeading={getLabel('info_page_heading')}>{info.category}</PageHeading></h2>
                <Card>
                    <CardContent>
                        <div className="edit-info-view-info grid">
                            <div>
                                <span><h2>{info.title}</h2></span>
                                <span><div dangerouslySetInnerHTML={{ __html: info.content }} /></span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </AuthenticatedLayout>
    );
};