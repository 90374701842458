import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { Container, Card, AppBar, Tabs, Tab } from '@mui/material';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AccountableCareNetworkInfo } from '../../../components/accountable.care.networks/accountable.care.network.info/AccountableCareNetworkAttributes';
import { ViewAccountableCareNetworkInfo } from '../../../components/accountable.care.networks/accountable.care.network.info/ViewAccountableCareNetworkAttributes';
import { AccountableCareNetworkProviders } from '../../../components/accountable.care.networks/AccountableCareNetworkProviders';
import { AccountableCareNetworkPrograms } from '../../../components/accountable.care.networks/AccountableCareNetworkPrograms';
import './EditAccountableCareNetwork.css';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { TabValue } from '../../../constants/TabValue';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { ACN } from '../../../interfaces/ApiInterfaces';
import { SetACNActingForValueAction } from '../../../actions/acnActingForValueAction';
import { useActingForId } from '../../../hooks/useActingFor';
import { InfoTitles } from '../../../constants/InfoTitles';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import { useACNDropdownValues } from '../../../hooks/useACNDropdownValues';
import { useNavigate, useParams } from 'react-router-dom';

export const EditAccountableCareNetwork: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actingForId = useActingForId();
    const acnValues = useACNDropdownValues();
    const [tabValue, setTabValue] = useState(0);
    const params = useParams();
    const acnId = +params.id!;
    const [acn, updateAcn] = useState<ACN>();
    const canMakeChangesForACN = actingForId === acnId || acnValues.some((acnValue: any) => acnValue.id === acnId);

    useEffect(() => {
        document.title = acnId === -1
            ? `${getLabel('app_name')} ${getLabel('title_add_accountable_care_network')}`
            : `${getLabel('app_name')} ${getLabel('title_edit_accountable_care_network')}`;
    }, []);

    useEffect(() => {
        if (acnId !== -1) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_ACCOUNTABLE_CARE_NETWORK, { acnId }), dispatch, null, false, false).then((acnResponse) => {
                updateAcn(acnResponse.body);
            });
        }
        else {
            updateAcn({
                id: -1, acnName: '',
                contactName: '', email: '', telephone: null, extension: '', address1: '', address2: '', city: '', state: '', zipCode: '',
                active: true, externalId: '', ein: null, approvedForImport: false, storageContainerName: '',
                secondContactName: '', secondContactEmail: '', secondContactTelephone: null, secondContactExtension: '', secondContactAddress1: '', secondContactAddress2: '', secondContactCity: '', secondContactState: '', secondContactZipCode: ''
            })
        }
    }, [acnId, dispatch]);

    useEffect(() => {
        if (acnId !== -1 && actingForId != acnId && canMakeChangesForACN && !!acn) {
            dispatch(SetACNActingForValueAction(acn.id, acn.acnName, ''));
        }
    }, [acn]);

    useEffect(() => {
        if (params.tab === TabValue.ACN_ATTRIBUTES) {
            setTabValue(0);
        }
        else if (params.tab === TabValue.ACN_PROVIDERS && acnId !== -1) {
            setTabValue(1);
        }
        else if (params.tab === TabValue.ACN_PROGRAMS) {
            setTabValue(2);
        }
        else if (params.tab === TabValue.ACN_PRODUCTS) {
            setTabValue(3);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acnId, tab: TabValue.ACN_ATTRIBUTES }))
            setTabValue(0);
        }
    },[acnId, params.tab])

    function getAddEditViewLabel() {
        return acnId === -1
            ? getLabel('acn_add_page_heading')
            : getLabel(hasPermissions(Permissions.CAN_UPDATE_ACN)
                ? 'acn_edit_page_heading'
                : 'acn_view_page_heading', { displayName: acn!.acnName });
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_ACN;
        } else if (tabValue === 1) {
            return InfoTitles.ACN_PROVIDERS;
        } else if (tabValue === 2) {
            return InfoTitles.ACN_PROGRAMS;
        }
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acnId, tab: TabValue.ACN_ATTRIBUTES }))
        }
        else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acnId, tab: TabValue.ACN_PROVIDERS }))
        }
        else if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acnId, tab: TabValue.ACN_PROGRAMS }))
        }
        else if (value === 3) {
            navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acnId, tab: TabValue.ACN_PRODUCTS }))
        }
        setTabValue(value);
    }

    return (
        <AuthenticatedLayout {...props} actingForForceFocusOnReason={acnId !== -1 && actingForId != acnId && canMakeChangesForACN} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="edit-acn-container">
                {!!acn &&
                    <>
                        <h2><PageHeading to={createRoute(ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS)} parentHeading={getLabel('acn_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="edit-acn-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary"
                                    textColor="primary">
                                    <Tab value={0} label={getLabel('tab_label_attributes')} />
                                    <Tab value={1} label={getLabel('tab_label_providers')} disabled={acnId === -1 || !hasPermissions(Permissions.CAN_VIEW_PROVIDERS)} />
                                    <Tab value={2} label={getLabel('tab_label_programs')} disabled={acnId === -1 || !hasPermissions(Permissions.CAN_VIEW_PROGRAMS)} />
                                </Tabs>
                            </AppBar>
                            {tabValue === 0 && ((hasPermissions(Permissions.CAN_CREATE_ACN) || (hasPermissions(Permissions.CAN_UPDATE_ACN) && canMakeChangesForACN)) ? (<AccountableCareNetworkInfo acn={acn} />) : (<ViewAccountableCareNetworkInfo acn={acn} />))}
                            {tabValue === 1 && <AccountableCareNetworkProviders acn={acn} canMakeChangesForACN={canMakeChangesForACN} />}
                            {tabValue === 2 && <AccountableCareNetworkPrograms acn={acn} canMakeChangesForACN={canMakeChangesForACN} />}
                        </Card>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};
