import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createRoute, getActiveClass, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { Label } from '../../common/label/Label';
import { makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ApiUrls } from '../../../constants/ApiUrls';
import { getLabel } from '../../common/label/Label.library';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
    infoTitle: string;
}

export const InfoLink: React.FC<Props> = ({ infoTitle }) => {
    const infoTabName = "info_tab";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (window.name === infoTabName) document.title = `${getLabel('app_name')} ${getLabel('title_list_info')}`
    }, [])

    const handleClick = () => {
        if (location.pathname.startsWith(ApplicationRoutes.INFO)) {
            navigate(ApplicationRoutes.INFO);
            return;
        }

        if (infoTitle == null) {
            if (window.name === infoTabName) {
                navigate(ApplicationRoutes.INFO);
            } else {
                const aTag = document.createElement('a');
                aTag.target = infoTabName;
                aTag.href = ApplicationRoutes.INFO;
                aTag.click();
            }
            return;
        }

        const data = {
            title: infoTitle
        };
        var routeTo = "";
        makeJSONPostRequest(ApiUrls.GET_INFO_ID_BY_TITLE, dispatch, data, false, false).then((response) => {
            routeTo = createRoute(ApplicationRoutes.VIEW_INFO, { id: response.body });
        }, () => {
            routeTo = createRoute(ApplicationRoutes.INFO);
        }).finally(() => {
            if (window.name === infoTabName) {
                navigate(routeTo);
            } else {
                const aTag = document.createElement('a');
                aTag.target = infoTabName;
                aTag.href = routeTo;
                aTag.click();
            }
        });
    }

    return (<a className={getActiveClass(location.pathname, [ApplicationRoutes.INFO])} onClick={handleClick}>
                <Label label="info_nav_heading" />
            </a>);
}