import { AnyAction, combineReducers } from "redux";
import { userMessage, getSeverity, getUserMessage } from "./userMessageReducer";
import { userSearch, getUserSearch, acnSearch, getACNSearch, programSearch, getProgramSearch, groupSearch, getGroupSearch, subgroupSearch, getSubgroupSearch, cycleSearch, getCycleSearch, allProviderSearch, getAllProviderSearch, myProviderSearch, getMyProviderSearch, reportSearch, getReportSearch, infoSearch, getInfoSearch, productSearch, getProductSearch, getUserACNsSearch, userACNsSearch, getProgramACNsSearch, getProductACNsSearch, programACNsSearch, productACNsSearch, getSubgroupProviderSearch, subgroupProviderSearch, getFileSearch, fileSearch, groupProviderSearch, getGroupProviderSearch } from "./searchFilterReducer";
import { roleDropdown, getRoleDropdown } from "./roleDropdownReducer";
import { acnDropdownValues as acnDropdownOptions, getACNDropdownValues } from "./acnDropdownValuesReducer";
import { acnActingForValue as acnActingForOption, getACNActingForValue } from "./acnActingForValueReducer";
import { shouldFetchNotifications, getFetchNotifications } from "./fetchNotificationsReducer";
import { notifications, getNotifications } from "./notificationsReducer";
import { AppState } from "../store/configureStore";
import { authStatus, getLoggedOutStatus, getStartInactivityTimerStatus } from "./authReducer";
import { cycleDropdown, getCycleDropdown } from "./cycleDropdownReducer";
import { applicationInfo, getApplicationFeatureFlags, getApplicationInactivityTimeoutInMinutes, getApplicationVersion, getApplicationUploadRestrictions } from "./applicationInfoReducer";
import { nextCycle, getNextCycle } from "./nextCycleReducer";
import { loginRedirect, getLoginRedirectPathname } from "./loginRedirectReducer";
import { DESTROY_STORE } from "../actions/rootAction";
import { getSuccessMessage, successMessage } from "./successMessageReducer";
import { designationTypeDropdown, getDesignationTypeDropdown } from "./designationTypeDropdownReducer";
import { designationSourceDropdown, getDesignationSourceDropdown } from "./designationSourceDropdownReducer";

const appReducer = combineReducers({
    userMessage: userMessage,
    userSearch: userSearch,
    roleDropdown: roleDropdown,
    acnDropdownValues: acnDropdownOptions,
    acnActingForValue: acnActingForOption,
    shouldFetchNotifications: shouldFetchNotifications,
    notifications: notifications,
    authStatus: authStatus,
    acnSearch: acnSearch,
    programSearch: programSearch,
    groupSearch: groupSearch,
    subgroupSearch: subgroupSearch,
    cycleSearch: cycleSearch,
    allProviderSearch: allProviderSearch,
    myProviderSearch: myProviderSearch,
    groupProviderSearch: groupProviderSearch,
    subgroupProviderSearch: subgroupProviderSearch,
    cycleDropdown: cycleDropdown,
    reportSearch: reportSearch,
    infoSearch: infoSearch,
    applicationInfo: applicationInfo,
    nextCycle: nextCycle,
    productSearch: productSearch,
    userACNsSearch: userACNsSearch,
    programACNsSearch: programACNsSearch,
    productACNsSearch: productACNsSearch,
    loginRedirect: loginRedirect,
    successMessage: successMessage,
    designationTypeDropdown: designationTypeDropdown,
    designationSourceDropdown: designationSourceDropdown,
    fileSearch: fileSearch,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === DESTROY_STORE) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action);
}

export const userMessageSeverity = (state: AppState) => getSeverity(state.userMessage);
export const userMessageText = (state: AppState) => getUserMessage(state.userMessage);
export const userSearchValues = (state: AppState) => getUserSearch(state.userSearch);
export const roleDropdownValues = (state: AppState) => getRoleDropdown(state.roleDropdown);
export const acnDropdownValues = (state: AppState) => getACNDropdownValues(state.acnDropdownValues);
export const acnActingForValue = (state: AppState) => getACNActingForValue(state.acnActingForValue);
export const notificationListValues = (state: AppState) => getNotifications(state.notifications);
export const fetchNotifications = (state: AppState) => getFetchNotifications(state.shouldFetchNotifications);
export const isLoggedOut = (state: AppState) => getLoggedOutStatus(state.authStatus);
export const shouldStartInactivityTimer = (state: AppState) => getStartInactivityTimerStatus(state.authStatus);
export const acnSearchValues = (state: AppState) => getACNSearch(state.acnSearch);
export const programSearchValues = (state: AppState) => getProgramSearch(state.programSearch);
export const groupSearchValues = (state: AppState) => getGroupSearch(state.groupSearch);
export const subgroupSearchValues = (state: AppState) => getSubgroupSearch(state.subgroupSearch);
export const cycleSearchValues = (state: AppState) => getCycleSearch(state.cycleSearch);
export const allProviderSearchValues = (state: AppState) => getAllProviderSearch(state.allProviderSearch);
export const myProviderSearchValues = (state: AppState) => getMyProviderSearch(state.myProviderSearch);
export const groupProviderSearchValues = (state: AppState) => getGroupProviderSearch(state.groupProviderSearch);
export const subgroupProviderSearchValues = (state: AppState) => getSubgroupProviderSearch(state.subgroupProviderSearch);
export const cycleDropdownValues = (state: AppState) => getCycleDropdown(state.cycleDropdown);
export const reportSearchValues = (state: AppState) => getReportSearch(state.reportSearch);
export const infoSearchValues = (state: AppState) => getInfoSearch(state.infoSearch);
export const applicationInfoCurrentVersion = (state: AppState) => getApplicationVersion(state.applicationInfo);
export const applicationInfoFeatureFlags = (state: AppState) => getApplicationFeatureFlags(state.applicationInfo);
export const applicationInfoInactivityTimeoutInMinutes = (state: AppState) => getApplicationInactivityTimeoutInMinutes(state.applicationInfo);
export const applicationInfoUploadRestrictions = (state: AppState) => getApplicationUploadRestrictions(state.applicationInfo);
export const nextCycleValue = (state: AppState) => getNextCycle(state.nextCycle);
export const productSearchValues = (state: AppState) => getProductSearch(state.productSearch);
export const userACNsSearchValues = (state: AppState) => getUserACNsSearch(state.userACNsSearch);
export const programACNsSearchValues = (state: AppState) => getProgramACNsSearch(state.programACNsSearch);
export const productACNsSearchValues = (state: AppState) => getProductACNsSearch(state.productACNsSearch);
export const loginRedirectPathname = (state: AppState) => getLoginRedirectPathname(state.loginRedirect);
export const successMessageValue = (state: AppState) => getSuccessMessage(state.successMessage);
export const designationTypeDropdownValue = (state: AppState) => getDesignationTypeDropdown(state.designationTypeDropdown);
export const designationSourceDropdownValue = (state: AppState) => getDesignationSourceDropdown(state.designationSourceDropdown);
export const fileSearchValues = (state: AppState) => getFileSearch(state.fileSearch);

export default rootReducer;