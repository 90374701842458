export class NotificationType {
    public static readonly AttestationRequired = "AttestationRequired";
    public static readonly ProviderReleaseApproved = "ProviderReleaseApproved";
    public static readonly ProviderReleaseDeclined = "ProviderReleaseDeclined";
    public static readonly RequestProviderRelease = "RequestProviderRelease";
    public static readonly DesignationPracticeLocationChanged = "DesignationPracticeLocationChanged";
    public static readonly ExpiringDesignation = "ExpiringDesignation";
    public static readonly FileFailedValidation = "FileFailedValidation";
    public static readonly FileAttested = "FileAttested";
    public static readonly FileRejected = "FileRejected";
}
