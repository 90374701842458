export interface FetchNotifications {
    toggle: boolean;
}

export const TOGGLE_FETCH_NOTIFICATIONS = "TOGGLE_FETCH_NOTIFICATIONS";

interface ToggleFetchNotifications {
    type: typeof TOGGLE_FETCH_NOTIFICATIONS;
    toggleNotifications: FetchNotifications;
}

export type FetchNotificationsAction = ToggleFetchNotifications;

export const SetFetchNotificationsAction = (toggle: boolean): ToggleFetchNotifications => ({
    type: TOGGLE_FETCH_NOTIFICATIONS,
    toggleNotifications: { toggle: toggle }
});
