export interface CycleSearch {
    startDate: string;
    endDate: string;
}

export const SET_CYCLE_SEARCH = "SET_CYCLE_SEARCH";
export const CLEAR_CYCLE_SEARCH = "CLEAR_CYCLE_SEARCH";

interface SetCycleSearch {
    type: typeof SET_CYCLE_SEARCH;
    cycleSearch: CycleSearch;
}

interface ClearCycleSearch {
    type: typeof CLEAR_CYCLE_SEARCH;
}

export type CycleSearchAction = SetCycleSearch | ClearCycleSearch;

export const SetCycleSearchAction = (startDate: string, endDate: string): SetCycleSearch => ({
    type: SET_CYCLE_SEARCH,
    cycleSearch: { startDate: startDate, endDate: endDate}
});

export const ClearCycleSearchAction = (): ClearCycleSearch => ({
    type: CLEAR_CYCLE_SEARCH
});
