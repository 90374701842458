import React, { useState } from "react";
import { File } from "../../interfaces/ApiInterfaces";
import { APIButton } from "../common/button/APIButton";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import LoopIcon from '@mui/icons-material/Loop';
import { Tooltip } from "@mui/material";
import { useActingFor } from "../../hooks/useActingFor";
import { ReinstateApiTokenTimeoutExpiryAction, SuspendApiTokenTimeoutExpiryAction } from "../../actions/authAction";
import { useDispatch } from "react-redux";
import { ClearUserMessageAction, SetUserMessageSuccessAction } from "../../actions/userMessageAction";
import { makeJSONPostRequest, makePostBlobRequest } from "../../services/ajax/ajax";
import { ApiUrls } from "../../constants/ApiUrls";
import { getLabel } from "../common/label/Label.library";
import { SetFetchNotificationsAction } from "../../actions/fetchNotificationsAction";
import { hasPermissions } from "../../services/auth/auth";
import { Permissions } from '../../constants/Permissions';
import { DialogModal } from "../common/dialog.modal/DialogModal";
import './FileDetailsRow.css';
import { FileValidationStatus, FileOperationStatus } from "../../constants/FileStatus";

interface Props {
    file: File;
    onPostDelete: Function;
}

export const FileDetailsRow: React.FC<Props> = ({ file, onPostDelete }) => {
    const dispatch = useDispatch();
    const acnActingFor = useActingFor();
    const [fileToDelete, setFileToDelete] = useState<File>();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const getValidationStatusIcon = (status: string) => {
        switch (status) {
            case FileValidationStatus.OK:
                return <Tooltip arrow title={status}><CheckIcon color='primary' /></Tooltip>
            case FileValidationStatus.PENDING:
                return <Tooltip arrow title={status}><LoopIcon color='action' /></Tooltip>
            default:
                return <Tooltip arrow title={<div style={{ whiteSpace: 'pre' }}>{status}</div>}>
                    <CloseIcon color='error' />
                </Tooltip>
        }
    }

    const getOperationStatusIcon = (statusString: string) => {
        const status = new FileOperationStatus(statusString);
        switch (status.code) {
            case FileOperationStatus.ATTESTED_IN_USE:
                return <Tooltip arrow title={status.message}><CheckCircleIcon htmlColor='blue' /></Tooltip>
            case FileOperationStatus.ATTESTED_REJECTED:
                return <Tooltip arrow title={status.message}><CheckCircleIcon htmlColor='orange' /></Tooltip>
            case FileOperationStatus.IMPORT_IN_PROGRESS:
                return <Tooltip arrow title={status.message}><ChangeCircleIcon htmlColor='gray' /></Tooltip>
            case FileOperationStatus.IMPORT_FINISHED:
                return <Tooltip arrow title={status.message}><CheckCircleIcon htmlColor='green' /></Tooltip>
            case FileOperationStatus.IMPORT_ABORTED:
                return <Tooltip arrow title={status.message}><ErrorIcon htmlColor='red' /></Tooltip>
        }
    }

    const downloadFile = async (event: React.ChangeEvent<unknown>, file: File) => {
        event.stopPropagation();
        try {
            const data = {
                FileName: file.name,
                AcnId: acnActingFor.id
            }
            dispatch(SuspendApiTokenTimeoutExpiryAction());
            await makePostBlobRequest(ApiUrls.DOWNLOAD_FILE, dispatch, file.name, data, true, true);
            dispatch(SetUserMessageSuccessAction(getLabel('file_download_success')));
        }
        finally {
            dispatch(ReinstateApiTokenTimeoutExpiryAction());
        }
    };

    const copyFileLinkToClipboard = (event: React.ChangeEvent<unknown>, file: File) => {
        event.stopPropagation();
        dispatch(ClearUserMessageAction());
        navigator.clipboard.writeText(
            `${window.location.origin}/files/${acnActingFor.id}?file=${encodeURIComponent(file.name)}`
        ).then(() => {
            dispatch(SetUserMessageSuccessAction(getLabel('file_copy_link_success')));
        });
    };

    const deleteFile = (event: React.ChangeEvent<unknown>, file: File) => {
        event.stopPropagation();
        setFileToDelete(file);
        setShowDeleteDialog(true);
    };

    const cancelDelete = (event: React.ChangeEvent<unknown>) => {
        event.stopPropagation();
        setShowDeleteDialog(false);
    };

    const continueDelete = async (event: React.ChangeEvent<unknown>) => {
        event.stopPropagation();
        setShowDeleteDialog(false);
        try {
            const data = {
                FileName: fileToDelete!.name,
                AcnId: acnActingFor.id
            }
            dispatch(SuspendApiTokenTimeoutExpiryAction());
            await makeJSONPostRequest(ApiUrls.DELETE_FILE, dispatch, data, true, true);
            if (onPostDelete)
                await onPostDelete();
            dispatch(SetUserMessageSuccessAction(getLabel('file_delete_success')));
            dispatch(SetFetchNotificationsAction(true));
        }
        finally {
            dispatch(ReinstateApiTokenTimeoutExpiryAction());
        }
    };

    const canSeeDeleteButton = (): boolean => {
        return hasPermissions(Permissions.CAN_WRITE_FILES);
    };

    const isDeleteButtonDisabled = (file: File): boolean => {
        const status = new FileOperationStatus(file.operationStatus);
        return status.code == FileOperationStatus.ATTESTED_IN_USE
            || status.code == FileOperationStatus.ATTESTED_REJECTED;
    };

    return (
        <span className="file-detail-row">
            <DialogModal id="deleteFileDialog" title={getLabel("delete_file_title")}
                    onClickLeft={(event: React.ChangeEvent<unknown>) => cancelDelete(event)}
                    onClickRight={(event: React.ChangeEvent<unknown>) => continueDelete(event)}
                    open={showDeleteDialog} labelLeft={getLabel("delete_file_dialog_cancel")} labelRight={getLabel("delete_file_dialog_continue")}
                />
            <div className="filename-and-buttons-row">
                <div className="filename name">{file.name}</div>
                <div className="file-buttons">
                    <APIButton className="button" type="button" variant="contained" color="primary" onClick={(event: React.ChangeEvent<unknown>) => copyFileLinkToClipboard(event, file)}>{getLabel("file_copy_link_button_text")}</APIButton>
                    <APIButton className="button" type="button" variant="contained" color="primary" onClick={(event: React.ChangeEvent<unknown>) => downloadFile(event, file)}>{getLabel("file_download_button")}</APIButton>
                    {canSeeDeleteButton() &&
                        <APIButton className="button red-button" type="button" variant="contained" color="primary" disabled={isDeleteButtonDisabled(file)} onClick={(event: React.ChangeEvent<unknown>) => deleteFile(event, file)}>{getLabel("file_delete_button")}</APIButton>
                    }
                </div>
            </div>
            <div className="file-attributes grid truncate">
                <div><div className="colHeader">{getLabel("file_list_size_header")}</div><div>{getLabel("file_size_kb_value", { size: (file.length / 1024).toLocaleString() })}</div></div>
                <div><div className="colHeader">{getLabel("file_list_type_header")}</div><div>{file.type}</div></div>
                <div><div>{getValidationStatusIcon(file.validationStatus)}</div></div>
                <div><div>{getOperationStatusIcon(file.operationStatus)}</div></div>
                <div><div className="colHeader">{getLabel("file_list_uploaded_by_header")}</div><div>{file.uploadedBy}</div></div>
                <Tooltip arrow title={file.comment == null ? "" : file.comment}><div><div className="colHeader">{getLabel("file_list_comment_header")}</div><div>{file.comment}</div></div></Tooltip>
            </div>
        </span>
    )
}