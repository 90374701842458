export interface SubgroupProviderSearch {
    firstName: string;
    lastName: string;
    groupNPI: number | null;
    alreadyAddedOnly: boolean;
}

export const SET_SUBGROUP_PROVIDER_SEARCH = 'SET_SUBGROUP_PROVIDER_SEARCH';
export const CLEAR_SUBGROUP_PROVIDER_SEARCH = 'CLEAR_SUBGROUP_PROVIDER_SEARCH';

interface SetSubgroupProviderSearch {
    type: typeof SET_SUBGROUP_PROVIDER_SEARCH;
    subgroupProviderSearch: SubgroupProviderSearch;
}

interface ClearSubgroupProviderSearch {
    type: typeof CLEAR_SUBGROUP_PROVIDER_SEARCH;
}

export type SubgroupProviderSearchAction = SetSubgroupProviderSearch | ClearSubgroupProviderSearch;

export const SetSubgroupProviderSearchAction = (firstName: string, lastName: string, groupNPI: number | null, alreadyAddedOnly: boolean

): SetSubgroupProviderSearch => ({
    type: SET_SUBGROUP_PROVIDER_SEARCH,
    subgroupProviderSearch: {
        firstName: firstName,
        lastName: lastName,
        groupNPI: groupNPI,
        alreadyAddedOnly: alreadyAddedOnly
    },
});

export const ClearSubgroupProviderSearchAction = (): ClearSubgroupProviderSearch => ({
    type: CLEAR_SUBGROUP_PROVIDER_SEARCH
});