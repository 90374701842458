import { Card, CardContent } from '@mui/material';
import React from 'react';
import { ProviderDetails } from '../../../interfaces/ApiInterfaces';
import { ViewProvider } from '../provider.view/ViewProvider';
import { getLabel } from '../../common/label/Label.library';

interface Props {
    provider: ProviderDetails;
}

export const ViewProviderAttributes: React.FC<Props> = ({ provider }) => {

    const override: ProviderDetails = {
        id: provider.id,
        active: provider.active,
        pcpLastName: provider.providerOverride ? provider.providerOverride.pcpLastName ?? ' ' : ' ',
        pcpFirstName: provider.providerOverride ? provider.providerOverride.pcpFirstName ?? ' ' : ' ',
        pcpnpi: provider.pcpnpi,
        groupNPI: provider.providerOverride?.groupNPI ?? -1,
        groupNPIName: provider.providerOverride?.groupNPIName ?? '',
        specialty: provider.providerOverride ? provider.providerOverride.specialty ?? '' : '',
        externalId: provider.providerOverride ? provider.providerOverride.externalId ?? '' : '',

        pcpFullName: provider.providerOverride ? provider.providerOverride.pcpFullName ?? '' : '',
        pcpCredentials: provider.providerOverride ? provider.providerOverride.pcpCredentials ?? '' : '',
        affiliationStatus: provider.providerOverride ? provider.providerOverride.affiliationStatus ?? '' : '',
        providerOverride: provider.providerOverride,
        hasActiveReleaseRequests: false,
        affiliatedACNs: [],
        releaseRequestACNs: []
    }

    return (
        <Card>
            <CardContent>
                <div className="provider-attributes-label provider-imported-label">{getLabel('provider_imported')}</div>
                <ViewProvider provider={provider} />
                <div className="provider-attributes-label provider-edited-label">{getLabel('provider_edited')}</div>
                <ViewProvider provider={override} isOverride={true} />
            </CardContent>
        </Card>
    );
};
