import { DesignationSourceDropdown, DesignationSourceDropdownAction, SET_DESIGNATION_SOURCE_DROPDOWN } from "../actions/designationSourceDropdownAction";

const initialState: DesignationSourceDropdown = {
    designationSources: []
};

export const designationSourceDropdown = (
    state: DesignationSourceDropdown = initialState,
    action: DesignationSourceDropdownAction
): DesignationSourceDropdown => {
    if (action.type === SET_DESIGNATION_SOURCE_DROPDOWN) {
        return { designationSources: action.designationSourceDropdown.designationSources};
    }
    return state;

};

export const getDesignationSourceDropdown = (state: DesignationSourceDropdown) => state.designationSources;