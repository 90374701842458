export class InfoTitles {
    public static readonly LIST_USERS = "List Users";
    public static readonly UPDATE_USER = "Add, Edit, or View User";
    public static readonly USER_ACNS = "User ACNs";
    public static readonly LIST_ACNS = "List ACNs";
    public static readonly UPDATE_ACN = "Add, Edit, or View ACN";
    public static readonly ACN_PROVIDERS = "ACN Providers";
    public static readonly ACN_PROGRAMS = "ACN Programs";
    public static readonly LIST_PROVIDERS = "List Providers";
    public static readonly UPDATE_PROVIDER = "Add, Edit, or View Provider";
    public static readonly PROVIDER_AFFILIATIONS = "Provider Affiliations";
    public static readonly PROVIDER_DESIGNATIONS = "List Provider Designations";
    public static readonly UPDATE_DESIGNATION = "Add, Edit, or View Designation";
    public static readonly LIST_PROGRAMS = "List Programs";
    public static readonly UPDATE_PROGRAM = "Add, Edit, or View Program";
    public static readonly PROGRAM_PRODUCTS = "Program Products";
    public static readonly PROGRAM_RULES = "Program Rules";
    public static readonly PROGRAM_CYCLES = "Program Cycles";
    public static readonly LIST_CYCLES = "List Cycles";
    public static readonly UPDATE_CYCLE = "Add, Edit, or View Cycle";
    public static readonly CYCLE_PROGRAMS = "Cycle Programs";
    public static readonly CYCLE_ATTESTATION = "Cycle Attestation";
    public static readonly LIST_REPORTS = "List Reports";
    public static readonly LIST_PRODUCTS = "List Products";
    public static readonly UPDATE_PRODUCT = "Add, Edit, or View Product";
    public static readonly PRODUCT_ACNS = "Product ACNs";
    public static readonly IMPORT_FACILITY = "Import Facility";
    public static readonly IMPORT_PROVIDERS = "Import Providers";
    public static readonly IMPORT_ROSTER = "Import Roster";
    public static readonly IMPORT_FACILITY_RESULT = "Import Facility Result";
    public static readonly IMPORT_PROVIDERS_RESULT = "Import Providers Result";
    public static readonly IMPORT_ROSTER_RESULT = "Import Roster Result";
    public static readonly IMPORT_ACN = "Import ACN";
    public static readonly IMPORT_ACN_RESULT = "Import ACN Result";
    public static readonly LIST_GROUPS = "List Groups";
    public static readonly UPDATE_GROUP = "Add, Edit, or View Group";
    public static readonly GROUP_PROVIDERS = "Group Providers";
    public static readonly LIST_SUBGROUPS = "List Subgroups";
    public static readonly UPDATE_SUBGROUP = "Add, Edit, or View Subgroup";
    public static readonly SUBGROUP_PROVIDERS = "Subgroup Providers";
    public static readonly LIST_FILES = "List Files";
    public static readonly FILE_UPLOAD = "Upload Files";
}