import React, { useState, useEffect} from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper } from '@mui/material';
import './Users.css';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { SelectInput } from '../../components/common/select.input/SelectInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { ClearUserSearchAction, SetUserSearchAction, UserSearch } from '../../actions/userSearchAction';
import { SetRoleDropdownAction } from '../../actions/roleDropdownAction';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { userSearchValues, roleDropdownValues, successMessageValue } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { TabValue } from '../../constants/TabValue';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { Permissions } from '../../constants/Permissions';
import { hasPermissions } from '../../services/auth/auth';
import { ACN, UserRole } from '../../interfaces/ApiInterfaces';
import cloneDeep from "lodash/cloneDeep";
import { getFilteredRoles } from './Users.library';
import { InfoTitles } from '../../constants/InfoTitles';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { ClearSuccessMessageAction } from '../../actions/successMessageAction';
import { useACNDropdownValues } from '../../hooks/useACNDropdownValues';
import { useNavigate } from 'react-router-dom';

export const Users: React.FC<any> = (props) => {
    const [roles, setRoles] = useState<any>([]);
    const [users, setUsers] = useState([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, UserSearch>(userSearchValues);
    const roleValues = useSelector<AppState, any>(roleDropdownValues);
    const acnValues = useACNDropdownValues();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const canViewOtherUsers = hasPermissions(Permissions.CAN_VIEW_ONLY_MY_ACN_USERS) || hasPermissions(Permissions.CAN_VIEW_ALL_USERS) || hasPermissions(Permissions.CAN_VIEW_ONLY_CLIENT_AND_ACN_USERS);
    const canCreateUsers = hasPermissions(Permissions.CAN_CREATE_USER);
    const successMessage = useSelector<AppState, string>(successMessageValue);

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_list_users')}`
    }, []);

    useEffect(() => {
        const getRoles = async () => {
            var result;
            if (!roleValues) {
                result = await makeJSONGetRequest(ApiUrls.GET_ROLES, dispatch, null, false, false);
                dispatch(SetRoleDropdownAction(result));
            }
            else {
                result = roleValues;
            }
            const body = cloneDeep(result.body);
            const sortedRoles = getFilteredRoles(body).sort(function (a: UserRole, b: UserRole) {
                return a.name.localeCompare(b.name);
            });
            setRoles(sortedRoles);
        }
        if (roles.length === 0) {
            getRoles();
        }
    }, [roles, roleValues, dispatch]);

    useEffect(() => {
        getUsers(searchValues, true, true);
    },[]);

    async function performSearch(values: any) {
        dispatch(SetUserSearchAction(values.Name, values.Email, values.AccountableCareNetwork, values.Role, values.IncludeInactive));
        await getUsers({name: values.Name, email: values.Email, accountableCareNetwork: values.AccountableCareNetwork, roleCode: values.Role, includeInactive: values.IncludeInactive}, true, true);
    }

    async function clearSearchFilters() {
        dispatch(ClearUserSearchAction());
        await getUsers({name: '', email: '', accountableCareNetwork: '', roleCode: '', includeInactive: false}, true, true);
    }

    async function getUsers(values: any, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) {
        const data = {
            Name: values.name,
            Email: values.email,
            AccountableCareNetworkId: values.accountableCareNetwork ? +values.accountableCareNetwork : null,
            RoleCode: values.roleCode,
            IncludeInactive: values.includeInactive,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Email) delete data.Email;
        if (!data.Name) delete data.Name;
        if (!data.RoleCode) delete data.RoleCode;
        const response = await makeJSONPostRequest(ApiUrls.GET_USERS, dispatch, data, showSucessMessage, showWorkingMessage);

        if (showSucessMessage) {
            if (!!successMessage) {
                dispatch(SetUserMessageSuccessAction(successMessage));
            }
            else if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('user_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('user_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('user_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        }
        dispatch(ClearSuccessMessageAction());
        setUsers(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        setPage(1);
    }

    const selectUser = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        var user: any = users.find((user: any) => user.id == index);
        navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: user.id, tab: TabValue.USER_ATTRIBUTES }))
    }

    const createNewUser = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: -1, tab: TabValue.USER_ATTRIBUTES }))
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await updatePage(value);
    };

    async function updatePage(value: number) {
        setPage(value);
        setUsers([]);
        setTotalResults(-1);
        const data: any = {
            Name: searchValues.name,
            Email: searchValues.email,
            AccountableCareNetworkId: searchValues.accountableCareNetwork ? +searchValues.accountableCareNetwork : null,
            RoleCode: searchValues.roleCode,
            IncludeInactive: searchValues.includeInactive,
            skip: skip + ((value - page) * take),
            take: take,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Email) delete data.Email;
        if (!data.Name) delete data.Name;
        if (!data.RoleCode) delete data.RoleCode;
        const response = await makeJSONPostRequest(ApiUrls.GET_USERS, dispatch, data);
        dispatch(ClearUserMessageAction());
        setUsers(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        console.log(users);
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_USERS}>
            <Container maxWidth={false} className="users">
                <h2>{getLabel("user_page_heading")}</h2>
                {canViewOtherUsers && <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ Name: searchValues.name, Email: searchValues.email, AccountableCareNetwork: searchValues.accountableCareNetwork, Role: searchValues.roleCode, IncludeInactive: searchValues.includeInactive }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="search-filter-fields">
                                        <TextInput name="Name" label="user_filter_name" fullwidth={false} />
                                        <TextInput name="Email" label="user_filter_email" fullwidth={false} />
                                        <SelectInput name="Role" label="user_filter_role" values={roles.map((r: any) =>
                                            <option key={r.code} value={r.code}>{r.name}</option>
                                        )} />
                                        <SelectInput name="AccountableCareNetwork" label="user_filter_acn" values={acnValues.map((a: any) =>
                                            <option key={a.id} value={a.id}>{a.acnName}</option>
                                        )} />
                                    </div>
                                    <div className="search-filter-buttons">
                                        <CheckboxInput name="IncludeInactive" label="user_filter_include_inactive" />
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>}
                {canCreateUsers && <Button id="addButton" variant="contained" color="primary" onClick={createNewUser}>Add</Button>}
                <div>
                    <List id="resultList" className="users-results">
                        {users.map((user: { id: any; name: any; email: any; roles: any; accountableCareNetworks: any; active: boolean; }) => <Paper key={user.id}> <ListItem className="row truncate" button onClick={(event) => selectUser(event, user.id)}>
                            <div className="user-row">
                                {user.active && <div className="name">{user.name}</div>}
                                {!user.active && <div className="name inactive-result">{getLabel('inactive_name', { name: user.name })}</div>}
                                <div className="user-row-data truncate grid">
                                    <span className="userCol"><div className="colHeader">{getLabel("user_list_email_header")}</div><div>{user.email}</div></span>
                                    <span className="userCol"><div className="colHeader">{getLabel("user_list_roles_header")}</div><div>{(user.roles.map((role: { name: any; }) => role.name)).join(', ')}</div></span>
                                    <span className="userCol">
                                        {
                                            user.accountableCareNetworks && user.accountableCareNetworks.length > 0 && 
                                            <>
                                                <div className="colHeader">{getLabel("user_list_acn_header")}</div>
                                                {user.accountableCareNetworks.map((acn: ACN) => <div key={acn.id}>{acn.acnName}</div>)}
                                            </>
                                        }
                                    </span>
                                </div>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("user_search_result_none")}</p>}
                    {canViewOtherUsers && totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
