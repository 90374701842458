import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent, TextField } from '@mui/material';
import { getLabel } from '../../../components/common/label/Label.library';
import { useDispatch } from 'react-redux';
import { SetACNActingForValueAction } from '../../../actions/acnActingForValueAction';
import './ActingFor.css';
import { getUserAcnName, hasPermissions, getIsViewOnly, getUserAcnId } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { useActingFor } from '../../../hooks/useActingFor';
import { TextFieldMaxLengths } from '../../../constants/TextFieldMaxLengths';
import { useNavigate, useParams } from 'react-router-dom';
import { useACNDropdownValues } from '../../../hooks/useACNDropdownValues';

interface Props {
    onChange?: any;
    forceFocusOnReason?: boolean;
}

export const ActingFor: React.FC<Props> = ({ onChange, forceFocusOnReason }) => {
    const dispatch = useDispatch();
    const acnValues = useACNDropdownValues();
    const acnActingFor = useActingFor();
    const isView = getIsViewOnly();
    const canActForAllACNs = hasPermissions(Permissions.CAN_ACT_FOR);
    const canActForOnlyMyACNs = hasPermissions(Permissions.CAN_ACT_FOR_ONLY_MY_ACN);
    const reasonRef = useRef<HTMLInputElement>(null);
    const [focus, setFocus] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    const removeActingForIdFromRoute = () => {
        // if ApplicationRoutes.ACTING_FOR_ID is present, we assume it's the LAST item in the path
        if (params.actingForId)
            navigate('..', { replace: true, relative: 'path' });
    };

    const handleFocus = () => {
        if ((acnActingFor.id !== -1 || focus) && reasonRef && reasonRef.current) {
            reasonRef.current.focus();
            setFocus(false);
        }
    };
    
    useEffect(() => {
        if (acnActingFor.id === -1 && (!getUserAcnId() || !getUserAcnName())) {
            setFocus(true);
        }
    }, [acnActingFor.id]);

    useEffect(() => {
        if (forceFocusOnReason || focus) handleFocus();
    }, [forceFocusOnReason, focus]);

    return (
        <>
            {
                (!getUserAcnName() && (canActForAllACNs || canActForOnlyMyACNs)) &&
                <div className="acting-for">
                    <span className="acting-for-label">{isView ? getLabel('viewing_as_label') : getLabel("acting_for_label")}</span>
                    <FormControl variant="outlined" className="acting-for-select" size="small">
                        <InputLabel id="label" htmlFor="AccountableCareNetwork">{getLabel("acting_for_acn_label")}</InputLabel>
                        <Select
                            native
                            labelId="label"
                            label={getLabel("acting_for_acn_label")}
                            value={acnActingFor.id}
                            onChange={(event: SelectChangeEvent<unknown>) => {
                                var id = +(event.target.value as number);
                                var acnName = acnValues.find((acn: any) => acn.id === id)?.acnName ?? "";
                                dispatch(SetACNActingForValueAction(id, acnName, ""));
                                removeActingForIdFromRoute();
                                handleFocus();
                                onChange?.(id, acnName);
                            }}
                            id="AccountableCareNetwork"
                            name="AccountableCareNetwork">
                            <option hidden>{getLabel("acting_for_acn_label")}</option>
                            {acnValues.map((e: any) =>
                                <option key={e.id} value={e.id}>
                                    {e.acnName}
                                </option>
                            )}
                        </Select>
                    </FormControl>
                    {!isView && <>
                        <span className="acting-for-because-label">{getLabel("acting_for_because_label")}</span>
                        <TextField
                            size="small"
                            className="acting-for-reason"
                            id="ActingForBecauseReason"
                            name="ActingForBecauseReason"
                            inputRef={reasonRef}
                            variant="outlined"
                            label={getLabel("acting_for_reason_label")}
                            value={acnActingFor.reason}
                            type="text"
                            onChange={(event) => {
                                dispatch(SetACNActingForValueAction(acnActingFor.id, acnActingFor.acnName, event.target.value));
                            }}
                            inputProps={{ maxLength: TextFieldMaxLengths.ACTING_FOR_REASON_MAX_LENGTH }}
                        /> </>}
                </div>
            }
        </>
    );
};
