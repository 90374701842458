import React, { useEffect } from 'react';
import { getLabel } from '../label/Label.library';
import { useField } from 'formik';
import './FileInput.css';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SetUserMessageErrorAction } from '../../../actions/userMessageAction';


interface Props {
    name: string;
    label?: string;
    clear: boolean;
    disabled?: boolean;
    multiple?: boolean;
    accept?: string
}

export const FileInput: React.FC<Props> = ({ name, label, clear = false, disabled, multiple, accept}) => {
    const [field, meta, helpers] = useField(name);
    const dispatch = useDispatch();

    useEffect(() => {
        if (meta.error !== undefined) dispatch(SetUserMessageErrorAction(meta.error));
    }, [meta.error]);

    return (
        <div className="file-input">
            <Button className="button" variant="contained" color="primary" id="file-upload"
                component="label" disabled={disabled}
            > {getLabel(label ?? 'import_choose_file')}
                <input
                    hidden
                    id={field.name}
                    name={field.name}
                    type="file"
                    value={clear ? "" : undefined}
                    disabled={disabled}
                    onChange={(event) => {
                        field.onChange(event);
                        field.onBlur(event);
                        helpers.setError(undefined);
                        if (!!event && !!event.currentTarget && !!event.currentTarget.files && event.currentTarget.files.length > 0) {
                            helpers.setValue(multiple ? Array.from(event.currentTarget.files) : event.currentTarget.files[0]);
                        }
                        event.target.value = '';
                    }}
                    multiple={multiple}
                    accept={accept}
                />
            </Button>
            <label htmlFor="file-upload">
            {!!field.value && (!!field.value.name || field.value.length > 0)
                ? multiple
                    ? field.value.map((file: any) => file.name).join(', ')
                    : field.value.name
                : getLabel('file_upload_no_file_chosen')
            }
            </label>
        </div>
    )
}