import React from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { getLabel } from '../../common/label/Label.library';
import { TextInput } from '../../common/text.input/TextInput';
import { StatesDropdown } from '../../common/states.dropdown/StatesDropdown';
import { CheckboxInput } from '../../common/checkbox.input/CheckboxInput';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import './AccountableCareNetworkAttributes.css';
import { SetACNDropdownValuesAction } from '../../../actions/acnDropdownValuesAction';
import { ACN } from '../../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { SetFetchNotificationsAction } from '../../../actions/fetchNotificationsAction';
import { getClientId } from '../../../services/client/client';
import { TextFieldMaxLengths } from '../../../constants/TextFieldMaxLengths';
import { SetACNActingForValueAction } from '../../../actions/acnActingForValueAction';
import { formatPhone } from '../../../services/format/format';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { SetSuccessMessageAction } from '../../../actions/successMessageAction';
import { isAllEmpty, isEmpty, isValidEmail } from '../../../services/validate/validate';

interface Props {
  acn: ACN
}

export const AccountableCareNetworkInfo: React.FC<Props> = ({acn}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const canUpdateAllFields = hasPermissions(Permissions.CAN_ACT_FOR);
  const canSeeStorageContainerName = hasPermissions(Permissions.CAN_SEE_ACN_CONTAINER_NAME);

  const validate = (values: any) => {
    const errors: { [key: string]: string } = {};

    if (isEmpty(values.acnName)) {
      errors.acnName = getLabel('validation_message_required');
    } else if (/[,'\"|\t]/.test(values.acnName)) {
      errors.acnName = getLabel('validation_message_special_characters');
    }
    if (!values.ein) {
      errors.ein = getLabel('validation_message_required');
    }
    if (!values.externalId) {
      errors.externalId = getLabel('validation_message_required');
    }

    const isFirstContactEmpty = isAllEmpty(
      values.contactName,
      values.email,
      values.telephone,
      values.extension,
      values.streetAddress1,
      values.streetAddress2,
      values.city,
      values.state,
      values.zip);

    const isSecondContactEmpty = isAllEmpty(
      values.secondContactName,
      values.secondContactEmail,
      values.secondContactTelephone,
      values.secondContactExtension,
      values.secondContactStreetAddress1,
      values.secondContactStreetAddress2,
      values.secondContactCity,
      values.secondContactState,
      values.secondContactZip);

    if (!isFirstContactEmpty || !isSecondContactEmpty) {
      if (isEmpty(values.contactName)) {
        errors.contactName = getLabel('validation_message_required');
      }
      if (isEmpty(values.email)) {
        errors.email = getLabel('validation_message_required');
      } else if (!isValidEmail(values.email)) {
        errors.email = getLabel('validation_message_email');
      }
      if (!values.telephone) {
        errors.telephone = getLabel('validation_message_required');
      } else if (formatPhone(values.telephone)?.length != TextFieldMaxLengths.FORMATTED_PHONE_NUMBER_LENGTH) {
        errors.telephone = getLabel('validation_message_telephone');
      }
      if (isEmpty(values.streetAddress1)) {
        errors.streetAddress1 = getLabel('validation_message_required');
      }
      if (isEmpty(values.city)) {
        errors.city = getLabel('validation_message_required');
      }
      if (isEmpty(values.state)) {
        errors.state = getLabel('validation_message_required');
      }
      if (isEmpty(values.zip)) {
        errors.zip = getLabel('validation_message_required');
      }
    }

    if (!isSecondContactEmpty) {
      if (isEmpty(values.secondContactName)) {
        errors.secondContactName = getLabel('validation_message_required');
      }
      if (isEmpty(values.secondContactEmail)) {
        errors.secondContactEmail = getLabel('validation_message_required');
      } else if (!isValidEmail(values.secondContactEmail)) {
        errors.secondContactEmail = getLabel('validation_message_email');
      }
      if (!values.secondContactTelephone) {
        errors.secondContactTelephone = getLabel('validation_message_required');
      } else if (formatPhone(values.secondContactTelephone)?.length != TextFieldMaxLengths.FORMATTED_PHONE_NUMBER_LENGTH) {
        errors.secondContactTelephone = getLabel('validation_message_telephone');
      }
      if (isEmpty(values.secondContactAddress1)) {
        errors.secondContactAddress1 = getLabel('validation_message_required');
      }
      if (isEmpty(values.secondContactCity)) {
        errors.secondContactCity = getLabel('validation_message_required');
      }
      if (isEmpty(values.secondContactState)) {
        errors.secondContactState = getLabel('validation_message_required');
      }
      if (isEmpty(values.secondContactZip)) {
        errors.secondContactZip = getLabel('validation_message_required');
      }
    }

    return errors;
  };

  const cancelEdit = () => {
    navigate(createRoute(ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS));
  };

  const saveACN = async (values: any, actions: any) => {
    const isAdd = acn.id === -1;
    const data = {
      ExternalId: values.externalId,
      ACNName: values.acnName,
      ContactName: values.contactName,
      Address1: values.streetAddress1,
      Address2: values.streetAddress2,
      City: values.city,
      State: values.state,
      ZipCode: values.zip,
      Telephone: values.telephone ? +(String(values.telephone).replace(/\D/g, '')) : null,
      Extension: values.extension,
      Email: values.email,
      SecondContactName: values.secondContactName,
      SecondContactAddress1: values.secondContactAddress1,
      SecondContactAddress2: values.secondContactAddress2,
      SecondContactCity: values.secondContactCity,
      SecondContactState: values.secondContactState,
      SecondContactZipCode: values.secondContactZip,
      SecondContactTelephone: values.secondContactTelephone ? +(String(values.secondContactTelephone).replace(/\D/g, '')) : null,
      SecondContactExtension: values.secondContactExtension,
      SecondContactEmail: values.secondContactEmail,
      Active: values.active,
      ein: values.ein,
      ApprovedForImport: values.approvedForImport,
      ClientId: getClientId()
    };
    try {
      const response = isAdd
        ? await makeJSONPostRequest(ApiUrls.CREATE_ACCOUNTABLE_CARE_NETWORK, dispatch, data)
        : await makeJSONPostRequest(createUrl(ApiUrls.EDIT_ACCOUNTABLE_CARE_NETWORK, {acnId: acn.id}), dispatch, data);

      dispatch(SetACNDropdownValuesAction([]));
      if (isAdd) dispatch(SetACNActingForValueAction(response.body.acnId, values.acnName, ''));
      dispatch(SetSuccessMessageAction(isAdd ? 'acn_attributes_success_add_text' : 'acn_attributes_success_edit_text'));
      dispatch(SetFetchNotificationsAction(true));
      navigate(createRoute(ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS));
    } catch (error: any) {
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Formik enableReinitialize={true}
          initialValues={{
            acnName: acn.acnName ?? '',
            contactName: acn.contactName ?? '',
            email: acn.email ?? '',
            telephone: acn.telephone ?? '',
            extension: acn.extension ?? '',
            streetAddress1: acn.address1 ?? '',
            streetAddress2: acn.address2 ?? '',
            city: acn.city ?? '',
            state: acn.state ?? '',
            zip: acn.zipCode ?? '',
            secondContactName: acn.secondContactName ?? '',
            secondContactEmail: acn.secondContactEmail ?? '',
            secondContactTelephone: acn.secondContactTelephone ?? '',
            secondContactExtension: acn.secondContactExtension ?? '',
            secondContactAddress1: acn.secondContactAddress1 ?? '',
            secondContactAddress2: acn.secondContactAddress2 ?? '',
            secondContactCity: acn.secondContactCity ?? '',
            secondContactState: acn.secondContactState ?? '',
            secondContactZip: acn.secondContactZipCode ?? '',
            active: acn.active ?? true,
            externalId: acn.externalId ?? '',
            ein: acn.ein ?? '',
            approvedForImport: acn.approvedForImport ?? false,
            storageContainerName: acn.storageContainerName ?? '',
          }}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            saveACN(values, actions).finally(() => {
              actions.setSubmitting(false);
            });
          }}
        >
          {(props) => (
            <form className="edit-acn-attributes" onSubmit={props.handleSubmit}>
              <div className="edit-acn-attributes-fields grid">
                <div>
                  <TextInput name="acnName" label="acn_attributes_name_label" disabled={!canUpdateAllFields} fullwidth={false} />
                  <TextInput name="contactName" label="acn_attributes_contact_name_label" fullwidth={false} />
                  <TextInput name="email" label="acn_attributes_email_label" fullwidth={false} />
                  <span className="half-width grid">
                    <TextInput name="telephone" label="acn_attributes_telephone_label" fullwidth={false} type="phone" maxLength={TextFieldMaxLengths.FORMATTED_PHONE_NUMBER_LENGTH}/>
                    <TextInput name="extension" label="acn_attributes_extension_label" type="digit" maxLength={TextFieldMaxLengths.PHONE_EXTENSION_MAX_LENGTH} fullwidth={false} />
                  </span>
                </div>
                <div>
                  <TextInput name="streetAddress1" label="acn_attributes_address1_label" fullwidth={false} />
                  <TextInput name="streetAddress2" label="acn_attributes_address2_label" fullwidth={false} />
                  <TextInput name="city" label="acn_attributes_city_label" fullwidth={false} />
                  <span className="half-width grid">
                    <StatesDropdown name="state" label="acn_attributes_state_label" halfWidth={true} />
                    <TextInput name="zip" label="acn_attributes_zip_label" fullwidth={false} />
                  </span>
                </div>
                <div>
                  <TextInput name="ein" type="number" label="acn_attributes_ein_label" fullwidth={false} />
                </div>
                <div>
                  <TextInput name="externalId" type ="number" label="acn_attributes_es_id_label" disabled={!canUpdateAllFields} fullwidth={false} />
                  {canSeeStorageContainerName &&
                    <TextInput name="storageContainerName" label="acn_attributes_storage_container_label" disabled={true} fullwidth={false} /> }
                </div>
              </div>
              <div className="edit-acn-attributes-fields grid">
                <div>
                  <TextInput name="secondContactName" label="acn_attributes_second_contact_name_label" fullwidth={false} />
                  <TextInput name="secondContactEmail" label="acn_attributes_second_email_label" fullwidth={false} />
                  <span className="half-width grid">
                    <TextInput name="secondContactTelephone" label="acn_attributes_second_telephone_label" fullwidth={false} type="phone" maxLength={TextFieldMaxLengths.FORMATTED_PHONE_NUMBER_LENGTH}/>
                    <TextInput name="secondContactExtension" label="acn_attributes_second_extension_label" type="digit" maxLength={TextFieldMaxLengths.PHONE_EXTENSION_MAX_LENGTH} fullwidth={false} />
                  </span>
                </div>
                <div>
                  <TextInput name="secondContactAddress1" label="acn_attributes_second_address1_label" fullwidth={false} />
                  <TextInput name="secondContactAddress2" label="acn_attributes_second_address2_label" fullwidth={false} />
                  <TextInput name="secondContactCity" label="acn_attributes_second_city_label" fullwidth={false} />
                  <span className="half-width grid">
                    <StatesDropdown name="secondContactState" label="acn_attributes_second_state_label" halfWidth={true} />
                    <TextInput name="secondContactZip" label="acn_attributes_second_zip_label" fullwidth={false} />
                  </span>
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="edit-acn-attributes-buttons">
                <CheckboxInput name="approvedForImport" label="acn_attributes_approved_label" disabled={!canUpdateAllFields}/>
                <CheckboxInput name="active" label="acn_attributes_active_label" disabled={!canUpdateAllFields} />
                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>
                  {getLabel('acn_attributes_cancel_button_label')}
                </Button>
                <Button className="button" type="submit" variant="contained" color="primary" disabled={!props.dirty || props.isSubmitting}>
                  {getLabel('acn_attributes_submit_button_label')}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};
