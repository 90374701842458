import { DesignationTypeDropdown, DesignationTypeDropdownAction, SET_DESIGNATION_TYPE_DROPDOWN } from "../actions/designationTypeDropdownAction";

const initialState: DesignationTypeDropdown = {
    designationTypes: []
};

export const designationTypeDropdown = (
    state: DesignationTypeDropdown = initialState,
    action: DesignationTypeDropdownAction
): DesignationTypeDropdown => {
    if (action.type === SET_DESIGNATION_TYPE_DROPDOWN) {
        return { designationTypes: action.designationTypeDropdown.designationTypes};
    }
    return state;

};

export const getDesignationTypeDropdown = (state: DesignationTypeDropdown) => state.designationTypes;