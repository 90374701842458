export interface GroupSearchValues {
  name: string,
  city: string,
  state: string,
  includeInactive: boolean
}

export const SET_GROUP_SEARCH = "SET_GROUP_SEARCH";
export const CLEAR_GROUP_SEARCH = "CLEAR_GROUP_SEARCH";

interface SetGroupSearch {
  type: typeof SET_GROUP_SEARCH;
  groupSearch: GroupSearchValues;
}

interface ClearGroupSearch {
  type: typeof CLEAR_GROUP_SEARCH;
}

export type GroupSearchAction = SetGroupSearch | ClearGroupSearch;

export const SetGroupSearchAction = (values: GroupSearchValues): SetGroupSearch => ({
  type: SET_GROUP_SEARCH,
  groupSearch: { ...values }
});

export const ClearGroupSearchAction = (): ClearGroupSearch => ({
  type: CLEAR_GROUP_SEARCH
});