export interface AuthStatus {
    isLoggedOut: boolean;
    startInactivityTimer: boolean;
    enableApiTokenTimeoutExpiry: boolean;
}

interface LogOut {
    isLoggedOut: boolean;
}

interface StartInactivityTimer {
    startInactivityTimer: boolean;
}
export const SET_LOG_OUT = 'SET_LOG_OUT';
export const SET_START_INACTIVITY_TIMER = 'SET_START_INACTIVITY_TIMER';
export const SUSPEND_API_TOKEN_TIMEOUT_EXPIRY = 'SUSPEND_API_TOKEN_TIMEOUT_EXPIRY';
export const REINSTATE_API_TOKEN_TIMEOUT_EXPIRY = 'REINSTATE_API_TOKEN_TIMEOUT_EXPIRY';

interface SetLogOut {
    type: typeof SET_LOG_OUT;
    authStatus: LogOut;
}
interface SetStartInactivityTimer {
    type: typeof SET_START_INACTIVITY_TIMER;
    authStatus: StartInactivityTimer;
}

interface SuspendApiTokenTimeoutExpiry {
    type: typeof SUSPEND_API_TOKEN_TIMEOUT_EXPIRY;
}
  
interface ReinstateApiTokenTimeoutExpiry {
    type: typeof REINSTATE_API_TOKEN_TIMEOUT_EXPIRY;
}

export type AuthStatusActionTypes = SetLogOut | SetStartInactivityTimer | SuspendApiTokenTimeoutExpiry | ReinstateApiTokenTimeoutExpiry;

export const SetLogOutAction = (isLoggedOut: boolean): SetLogOut => ({
    type: SET_LOG_OUT,
  authStatus: { isLoggedOut: isLoggedOut },
});

export const SetStartInactivityTimerAction = (startInactivityTimer: boolean): SetStartInactivityTimer => ({
    type: SET_START_INACTIVITY_TIMER,
    authStatus: { startInactivityTimer: startInactivityTimer },
});

export const SuspendApiTokenTimeoutExpiryAction = (): SuspendApiTokenTimeoutExpiry => ({
    type: SUSPEND_API_TOKEN_TIMEOUT_EXPIRY
});
  
export const ReinstateApiTokenTimeoutExpiryAction = (): ReinstateApiTokenTimeoutExpiry => ({
    type: REINSTATE_API_TOKEN_TIMEOUT_EXPIRY
});
