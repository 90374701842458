export interface ProductACNsSearch {
    acnName: string;
}

export const SET_PRODUCT_ACNS_SEARCH = "SET_PRODUCT_ACNS_SEARCH";
export const CLEAR_PRODUCT_ACNS_SEARCH = "CLEAR_PRODUCT_ACNS_SEARCH";

interface SetProductACNsSearch {
    type: typeof SET_PRODUCT_ACNS_SEARCH;
    productACNsSearch: ProductACNsSearch;
}

interface ClearProductACNsSearch {
    type: typeof CLEAR_PRODUCT_ACNS_SEARCH;
}

export type ProductACNsSearchAction = SetProductACNsSearch | ClearProductACNsSearch;

export const SetProductACNsSearchAction = (acnName: string): SetProductACNsSearch => ({
    type: SET_PRODUCT_ACNS_SEARCH,
    productACNsSearch: { acnName: acnName }
});

export const ClearProductACNsSearchAction = (): ClearProductACNsSearch => ({
    type: CLEAR_PRODUCT_ACNS_SEARCH
});