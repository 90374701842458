export interface SuccessMessage { message: string }

export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";

interface SetSuccessMessage {
    type: typeof SET_SUCCESS_MESSAGE;
    successMessage: SuccessMessage;
}

interface ClearSuccessMessage {
    type: typeof CLEAR_SUCCESS_MESSAGE;
}

export type SuccessMessageAction = SetSuccessMessage | ClearSuccessMessage;

//TODO: Replace with `SetUserMessageSuccessAction` (which now allows overrides).
//This action is a different type of "hack", originated and discussed in that PR:
// https://dev.azure.com/medadvgrp/Provider%20Roster%20App/_git/Provider%20Roster%20App/pullRequest/982#1619197156
export const SetSuccessMessageAction = (message: string): SetSuccessMessage => ({
    type: SET_SUCCESS_MESSAGE,
    successMessage: { message: message }
});

export const ClearSuccessMessageAction = (): ClearSuccessMessage => ({
    type: CLEAR_SUCCESS_MESSAGE
});