import React, { useEffect, useState } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Card, Button, List, Paper, ListItem, Link, AppBar, Tabs, Tab } from '@mui/material';
import './Providers.css';
import { getLabel } from '../../components/common/label/Label.library';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { myProviderSearchValues, allProviderSearchValues, successMessageValue } from '../../reducers/rootReducer';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { AllProviderSearch, ClearAllProviderSearchAction, SetAllProviderSearchAction } from '../../actions/allProviderSearchAction';
import { ClearMyProviderSearchAction, MyProviderSearch, SetMyProviderSearchAction } from '../../actions/myProviderSearchAction';
import { SetUserMessageSuccessAction, ClearUserMessageAction } from '../../actions/userMessageAction';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { ACN, SearchProvider } from '../../interfaces/ApiInterfaces';
import { TabValue } from '../../constants/TabValue';
import { AffiliationStatus } from '../../constants/AffiliationStatus';
import { SetFetchNotificationsAction } from '../../actions/fetchNotificationsAction';
import { useActingFor } from '../../hooks/useActingFor';
import { InfoTitles } from '../../constants/InfoTitles';
import { AllProvidersSearch } from '../../components/providers/provider.search/AllProviderSearch';
import { MyProvidersSearch } from '../../components/providers/provider.search/MyProviderSearch';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { ClearSuccessMessageAction } from '../../actions/successMessageAction';
import { AffiliationButton } from '../../components/providers/provider.affiliations/AffiliationButton';
import { DialogModal } from '../../components/common/dialog.modal/DialogModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const Providers: React.FC<any> = (props) => {
    const acnActingFor = useActingFor();
    const allSearchValues = useSelector<AppState, AllProviderSearch>(allProviderSearchValues);
    const mySearchValues = useSelector<AppState, MyProviderSearch>(myProviderSearchValues);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [providers, setProviders] = useState<SearchProvider[]>([]);
    const [totalResults, setTotalResults] = useState(-1);
    const [page, setPage] = useState(0);
    const canManageProviders = hasPermissions(Permissions.CAN_MANAGE_PROVIDERS);
    const [tabValue, setTabValue] = useState(0);
    const [notificationSearch, setNotificationSearch] = useState(false);
    const [getProvidersCount, setGetProvidersCount] = useState(0);
    const successMessage = useSelector<AppState, string>(successMessageValue);
    const [searchSuccessMessage, setSearchSuccessMessage] = useState({ message: '', tab: -1, providers: [], totalResults: 0, page: 1 });
    const [showAffiliationCycleParticipationDialog, setShowAffiliationCycleParticipationDialog] = useState(false);
    const [affiliationCycleParticipationDialogTitle, setAffiliationCycleParticipationDialogTitle] = useState('');

    const selectProvider = (provider: SearchProvider) => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: provider.id, tab: TabValue.PROVIDER_ATTRIBUTES }));
    }

    const getProviders = async (values: any, newPage: number, showWorkingMessage: boolean = true, showSucessMessage: boolean = true, id: number = acnActingFor.id, tab: number = tabValue) => {
        if (id === -1) return; // skip if actingForId is -1 (initial default value)
        setGetProvidersCount(getProvidersCount + 1);
        setProviders([]);
        setTotalResults(-1);
        const data: any = {
            AccountableCareNetworkId: id,
            IsMyProviderSearch: tab === 0,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE
        };

        if (tab === 0) {
            data.FirstName = values.firstName ? values.firstName : null;
            data.LastName = values.lastName ? values.lastName : null;
            data.GroupNPI = values.groupNPI ? values.groupNPI : null;
            data.AffiliationStatus = values.affiliationStatus ? values.affiliationStatus : null;
            data.IncludeInactive = values.includeInactive;
        }

        else if (tab === 1) {
            data.IncludeInactive = values.includeInactive;
            data.NationalProviderId = values.nationalProviderId ? values.nationalProviderId : null;
        }

        const response = await makeJSONPostRequest(ApiUrls.GET_PROVIDERS, dispatch, data, showWorkingMessage, showWorkingMessage);
        var providers = response.body.result;
        const providerIds = providers.map((p: SearchProvider) => p.id);
        const affiliations = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDER_AFFILIATION_STATUS, { acnId: id }), dispatch, providerIds, false, false);
        const releaseRequestCount = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDERS_ACTIVE_RELEASE_REQUEST_COUNT, { acnId: id }), dispatch, providerIds, false, false);
        providers.forEach((p: SearchProvider) => {
            p.affiliationStatus = affiliations.body.find((e: any) => e.providerId === p.id).status;
            p.hasActiveReleaseRequests = releaseRequestCount.body.find((e: any) => e.providerId === p.id).releaseRequestCount > 0;
        });

        if (showSucessMessage) {
            if (!!successMessage) {
                setSearchSuccessMessage({ message: successMessage, tab: tab, providers: response.body.result, totalResults: response.body.totalCount, page: newPage });
            }
            else if (response.body.totalCount === 0) {
                setSearchSuccessMessage({ message: getLabel('providers_search_result_none'), tab: tab, providers: response.body.result, totalResults: 0, page: newPage });
            }
            else if (response.body.totalCount === 1) {
                setSearchSuccessMessage({ message: getLabel('providers_search_result_one'), tab: tab, providers: response.body.result, totalResults: 1, page: newPage });
            }
            else {
                setSearchSuccessMessage({
                    message: getLabel('providers_search_result_many', {
                        totalCount: response.body.totalCount,
                    }), tab: tab, providers: response.body.result, totalResults: response.body.totalCount, page: newPage
                });
            }
            dispatch(ClearSuccessMessageAction());
        }
        else {
            setProviders(providers);
            setTotalResults(response.body.totalCount);
            setPage(newPage);
        }
    };

    useEffect(() => {
        if (searchSuccessMessage.tab === tabValue) {
            dispatch(SetUserMessageSuccessAction(searchSuccessMessage.message));
            setProviders(searchSuccessMessage.providers);
            setTotalResults(searchSuccessMessage.totalResults);
            setPage(searchSuccessMessage.page);
        }
    },[searchSuccessMessage])

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getProviders(tabValue == 0 ? mySearchValues : allSearchValues, page, true, false);
        dispatch(ClearUserMessageAction());
    };

    async function performSearch(values: any, tab: any = null) {
        if (tab == 0 || (tab == null && tabValue === 0)) {
            dispatch(SetMyProviderSearchAction(values.includeInactive, values.affiliationStatus, values.groupNPI, values.firstName, values.lastName));
        }
        else if (tabValue === 1) {
            dispatch(SetAllProviderSearchAction(values.nationalProviderId, values.includeInactive));
        }
        if (tab != null) {
            await getProviders(values, 1, true, true, acnActingFor.id, tab);
        }
        else {
            await getProviders(values, 1, true, true);
        }
    }

    async function clearSearchFilters() {
        if (tabValue === 0) {
            dispatch(ClearMyProviderSearchAction());
        }
        else if (tabValue === 1) {
            dispatch(ClearAllProviderSearchAction());
        }
        await getProviders({ nationalProviderId: null, includeInactive: false, affiliationStatus: '', groupNPI: null, firstName: '', lastName: '' }, 1, true, true);
    }

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_list_providers')}`;
    }, []);

    useEffect(() => {
        // acnActingFor.id === -1 and acnName === "" only on initial load of the app, before the values get stored in local storage and redux (noop if true to give time for values to update)
        if ((acnActingFor.id !== -1 || acnActingFor.acnName !== "") && !notificationSearch) {
            var status = null; // status used to determine if user followed a notification link 
            if (params.filter === 'release') status = AffiliationStatus.RELEASE_REQUESTED;
            else if (params.filter === 'approved') status = AffiliationStatus.RECENTLY_APPROVED;
            else if (params.filter === 'declined') status = AffiliationStatus.RECENTLY_DECLINED;
            if (!!status) {
                // set search values from notification's link
                navigate(createRoute(ApplicationRoutes.PROVIDERS));
                setTabValue(0);
                setNotificationSearch(true); // only set to true here to signify that we are about to perform a search and to avoid this useEffect running again because of the above call that alters params.match.filter
                performSearch({ includeInactive: false, affiliationStatus: status, groupNPI: null, firstName: '', lastName: '' }, 0);
            }
            else if (location.state && location.state.clearFilters) {
                clearSearchFilters();
            }
            else {
                // not notification search, so either manual navigation to this page or change in acting for acn
                if (getProvidersCount > 0) getProviders(tabValue == 0 ? mySearchValues : allSearchValues, 1, true, true);
                else getProviders(tabValue == 0 ? mySearchValues : allSearchValues, 1, true, true);
            }
        }
        else if (acnActingFor.id !== -1 || acnActingFor.acnName !== "") {
            // This path only executes on the second run of this useEffect, triggered by the previous run's call to navigate(createRoute(Routes.PROVIDERS));
            setNotificationSearch(false); // This essentially acts as another noop to reset flags and the url after following a notification link
        }
    }, [acnActingFor.id, params.filter])

    const changeTabs = (tab: number) => {
        setTabValue(tab);
        performSearch(tab == 0 ? mySearchValues : allSearchValues, tab);
    };

    const toProviderAffiliation = (event: React.ChangeEvent<unknown>, provider: SearchProvider) => {
        event.stopPropagation();
        navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: provider.id, tab: TabValue.PROVIDER_AFFILIATIONS }));
    }

    const toEditACN = (event: React.ChangeEvent<unknown>, acn: ACN) => {
        event.stopPropagation();
        navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acn.id, tab: TabValue.ACN_ATTRIBUTES }));
    }


    const handleAffiliationChanges = async () => {
        dispatch(SetFetchNotificationsAction(true));
        getProviders(tabValue == 0 ? mySearchValues : allSearchValues, page, false, false);
    }

    function calculateProviderStatus(provider: SearchProvider): string | null {
        if (provider.hasActiveReleaseRequests) {
            return AffiliationStatus.RELEASE_REQUESTED;
        }
        if (provider.affiliationStatus == AffiliationStatus.MIXED) {
            return null;
        }
        return provider.affiliationStatus;
    }

    function isAtLeastOneProviderAffiliatedWithAnotherACN(allProviders: SearchProvider[], curAcnId: Number) {
        return allProviders.some(provider => provider.affiliatedACNs.some(acn => acn.id !== curAcnId));
    }

    const dismissAffiliationCycleParticipationDialog = () => {
        setShowAffiliationCycleParticipationDialog(false);
        setAffiliationCycleParticipationDialogTitle('');
    };

    return (
        <>
            {acnActingFor.id && <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_PROVIDERS}>
                <DialogModal id="ProviderAffiliationCycleParticipationDialog" open={showAffiliationCycleParticipationDialog}
                    title={affiliationCycleParticipationDialogTitle} onClickRight={dismissAffiliationCycleParticipationDialog} labelRight={getLabel("providers_new_affiliation_dialog_okay")} />
                <Container maxWidth={false} className="providers">
                    <h2>{getLabel('providers_page_heading')}</h2>
                    <Card className="provider-panel">
                        <AppBar position="static" color="default">
                            <Tabs value={tabValue} onChange={(event, value) => changeTabs(value)} indicatorColor="primary" textColor="primary">
                                <Tab value={0} label={getLabel('tab_label_my_providers')} />
                                <Tab value={1} label={getLabel('tab_label_all_providers')} />
                            </Tabs>
                        </AppBar>
                        {tabValue === 0 && <MyProvidersSearch performSearch={performSearch} clearSearch={clearSearchFilters} disabled={acnActingFor.id === -1 || !acnActingFor.id} />}
                        {tabValue === 1 && <AllProvidersSearch performSearch={performSearch} clearSearch={clearSearchFilters} disabled={acnActingFor.id === -1 || !acnActingFor.id} />}
                    </Card>
                    <div className="import-buttons">
                        {hasPermissions(Permissions.CAN_VIEW_IMPORT_PROVIDER_DATA) && <>
                            <Button className="red-button" type="button" variant="contained" color="primary" onClick={() => { navigate(createRoute(ApplicationRoutes.IMPORT_FACILITY_SITES)) }}>
                                {getLabel('providers_import_facility_sites_button')}
                            </Button>
                            <Button className="red-button" type="button" variant="contained" color="primary" onClick={() => { navigate(createRoute(ApplicationRoutes.IMPORT_PROVIDERS)) }}>
                                {getLabel('providers_import_providers_button')}
                            </Button>
                        </>}
                        {hasPermissions(Permissions.CAN_VIEW_IMPORT_ROSTER_DATA) &&
                            <Button className="red-button" type="button" variant="contained" color="primary" onClick={() => { navigate(createRoute(ApplicationRoutes.IMPORT_ROSTER)) }}>
                                {getLabel('providers_import_roster_button')}
                            </Button>
                        }
                        {isAtLeastOneProviderAffiliatedWithAnotherACN(providers, acnActingFor.id) && 
                            <span className="followup-message">
                                {getLabel('providers_click_to_follow_up_message')}
                            </span>}
                    </div>
                    <div className="providers-list">
                        <List id="resultList">
                            {providers.map((provider) => <Paper key={provider.id}> <ListItem className="row" button onClick={() => selectProvider(provider)}>
                                <div className="provider-row truncate">
                                    <div>
                                        {provider.active && <div className="name">{provider.pcpFullName}</div>}
                                        {!provider.active && <div className="name inactive-result">{getLabel('inactive_name', { name: provider.pcpFullName })}</div>}
                                        <div className="provider-row-data truncate">
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_pcp_credentials_header")}</div>
                                                <div>{provider.pcpCredentials}</div>
                                            </span>
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_specialty_header")}</div>
                                                <div>{provider.specialty}</div>
                                            </span>
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_pcp_npi_header")}</div>
                                                <div>{provider.pcpnpi}</div>
                                            </span>
                                            <span>
                                                <div className="colHeader">{getLabel("providers_list_pp_npi_header")}</div>
                                                <div>{provider.groupNPI}</div>
                                            </span>
                                            {
                                                provider.affiliatedACNs.length > 0 &&
                                                <span>
                                                    <div className="colHeader">{getLabel("providers_list_affiliated_with_header")}</div>
                                                    <div>
                                                        {
                                                            provider.affiliatedACNs.length === 1
                                                                ? <Link className="provider-row-data-link link" onClick={(e: React.ChangeEvent<unknown>) => toEditACN(e, provider.affiliatedACNs[0])}>{provider.affiliatedACNs[0].acnName}</Link>
                                                                : <Link className="provider-row-data-link link" onClick={(e: React.ChangeEvent<unknown>) => toProviderAffiliation(e, provider)}>{getLabel("providers_list_mixed_button_text")}</Link>
                                                        }
                                                    </div>
                                                </span>
                                            }
                                            {
                                                provider.releaseRequestACNs.length > 0 &&
                                                <span>
                                                    <div className="colHeader">{getLabel("providers_list_requested_by_header")}</div>
                                                    <div>
                                                        {
                                                            provider.releaseRequestACNs.length === 1
                                                                ? <Link className="provider-row-data-link link" onClick={(e: React.ChangeEvent<unknown>) => toEditACN(e, provider.releaseRequestACNs[0])}>{provider.releaseRequestACNs[0].acnName}</Link>
                                                                : <Link className="provider-row-data-link link" onClick={(e: React.ChangeEvent<unknown>) => toProviderAffiliation(e, provider)}>{getLabel("providers_list_mixed_button_text")}</Link>
                                                        }
                                                    </div>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {provider.active && <AffiliationButton providerId={provider.id} currentStatus={calculateProviderStatus(provider)} setShowAffiliationCycleParticipationDialog={setShowAffiliationCycleParticipationDialog}
                                            onChange={handleAffiliationChanges} hasButtonInfo={false} providerActive={provider.active}
                                            disabled={!canManageProviders || provider.affiliationInProgress!} setIsAffiliationActionInProgress={function setInProgress(inProgress: boolean) {provider.affiliationInProgress = inProgress}}
                                            setAffiliationCycleParticipationDialogTitle={setAffiliationCycleParticipationDialogTitle} />
                                        }
                                        {provider.active && provider.affiliationStatus == AffiliationStatus.MIXED && canManageProviders && <Button className="button link" type="button" color="primary" onClick={(e) => toProviderAffiliation(e, provider)}>{getLabel("providers_list_mixed_button_text")}</Button>}
                                    </div>
                                </div>
                            </ListItem></Paper>)}
                        </List>
                        {totalResults === 0 && <p className="paging">{getLabel("providers_search_result_none")}</p>}
                        {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                    </div>
                </Container>
            </AuthenticatedLayout>}
        </>
    );
};
