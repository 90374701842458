export class Permissions {
    public static readonly CAN_CREATE_CYCLE = 'CAN_CREATE_CYCLE';
    public static readonly CAN_UPDATE_CYCLE = 'CAN_UPDATE_CYCLE';
    public static readonly CAN_VIEW_CYCLES = 'CAN_VIEW_CYCLES';
    public static readonly CAN_CREATE_GROUP = 'CAN_CREATE_GROUP';
    public static readonly CAN_UPDATE_GROUP = 'CAN_UPDATE_GROUP';
    public static readonly CAN_CREATE_PROGRAM = 'CAN_CREATE_PROGRAM';
    public static readonly CAN_UPDATE_PROGRAM = 'CAN_UPDATE_PROGRAM';
    public static readonly CAN_VIEW_PROGRAMS = 'CAN_VIEW_PROGRAMS';
    public static readonly CAN_JOIN_LEAVE_PROGRAMS = 'CAN_JOIN_LEAVE_PROGRAMS';
    public static readonly CAN_IMPORT_PROVIDER_DATA = 'CAN_IMPORT_PROVIDER_DATA';
    public static readonly CAN_IMPORT_ACN_DATA = 'CAN_IMPORT_ACN_DATA';
    public static readonly CAN_CREATE_ACN = 'CAN_CREATE_ACN';
    public static readonly CAN_UPDATE_ACN = 'CAN_UPDATE_ACN';
    public static readonly CAN_SEE_ACN_CONTAINER_NAME = 'CAN_SEE_ACN_CONTAINER_NAME';
    public static readonly CAN_VIEW_ACNS = 'CAN_VIEW_ACNS';
    public static readonly CAN_VIEW_ALL_ACNS = 'CAN_VIEW_ALL_ACNS';
    public static readonly CAN_VIEW_ONLY_MY_ACN = 'CAN_VIEW_ONLY_MY_ACN';
    public static readonly CAN_CREATE_USER = 'CAN_CREATE_USER';
    public static readonly CAN_UPDATE_USER = 'CAN_UPDATE_USER';
    public static readonly CAN_MANAGE_PROVIDERS = 'CAN_MANAGE_PROVIDERS';
    public static readonly CAN_UPDATE_PROVIDER_RECORD = 'CAN_UPDATE_PROVIDER_RECORD';
    public static readonly CAN_ACT_FOR = 'CAN_ACT_FOR';
    public static readonly CAN_ACT_FOR_ONLY_MY_ACN = 'CAN_ACT_FOR_ONLY_MY_ACN';
    public static readonly CAN_ATTEST_TO_PARTICIPATION = 'CAN_ATTEST_TO_PARTICIPATION';
    public static readonly CAN_VIEW_ALL_CYCLE_PARTICIPATION_DATA = 'CAN_VIEW_ALL_CYCLE_PARTICIPATION_DATA';
    public static readonly CAN_VIEW_CLIENTS_CYCLE_PARTICIPATION_DATA = 'CAN_VIEW_CLIENTS_CYCLE_PARTICIPATION_DATA';
    public static readonly CAN_VIEW_ACNS_CYCLE_PARTICIPATION_DATA = 'CAN_VIEW_ACNS_CYCLE_PARTICIPATION_DATA';
    public static readonly CAN_VIEW_ADMIN_REPORTS = 'CAN_VIEW_ADMIN_REPORTS';
    public static readonly CAN_VIEW_REPORTS = 'CAN_VIEW_REPORTS';
    public static readonly CAN_VIEW_USERS = 'CAN_VIEW_USERS';
    public static readonly CAN_VIEW_ONLY_MY_ACN_USERS = 'CAN_VIEW_ONLY_MY_ACN_USERS';
    public static readonly CAN_VIEW_PROVIDERS = 'CAN_VIEW_PROVIDERS';
    public static readonly CAN_VIEW_ALL_PROVIDERS = 'CAN_VIEW_ALL_PROVIDERS';
    public static readonly CAN_VIEW_GROUPS = 'CAN_VIEW_GROUPS';
    public static readonly CAN_LOCK_CYCLE = 'CAN_LOCK_CYCLE';
    public static readonly CAN_VIEW_IMPORT_PROVIDER_DATA = 'CAN_VIEW_IMPORT_PROVIDER_DATA';
    public static readonly CAN_VIEW_IMPORT_ROSTER_DATA = 'CAN_VIEW_IMPORT_ROSTER_DATA';
    public static readonly CAN_VIEW_IMPORT_ACN_DATA = 'CAN_VIEW_IMPORT_ACN_DATA';
    public static readonly CAN_ACT_FOR_WITHOUT_REASON = "CAN_ACT_FOR_WITHOUT_REASON";
    public static readonly CAN_VIEW_PROGRAM_EXTERNAL_SYSTEM_ID = "CAN_VIEW_PROGRAM_EXTERNAL_SYSTEM_ID";
    public static readonly CAN_VIEW_PROVIDER_EXTERNAL_SYSTEM_ID = "CAN_VIEW_PROVIDER_EXTERNAL_SYSTEM_ID";
    public static readonly CAN_VIEW_ALL_USERS = "CAN_VIEW_ALL_USERS";
    public static readonly CAN_VIEW_ONLY_CLIENT_AND_ACN_USERS = "CAN_VIEW_ONLY_CLIENT_AND_ACN_USERS";
    public static readonly CAN_MANAGE_PROGRAMS = "CAN_MANAGE_PROGRAMS";
    public static readonly CAN_REQUEST_PASSWORD_RESET = "CAN_REQUEST_PASSWORD_RESET";
    public static readonly CAN_EXPORT_PIPE_DELIMITED_SNAPSHOT = "CAN_EXPORT_PIPE_DELIMITED_SNAPSHOT";
    public static readonly CAN_CREATE_PRODUCT = "CAN_CREATE_PRODUCT";
    public static readonly CAN_UPDATE_PRODUCT = "CAN_UPDATE_PRODUCT";
    public static readonly CAN_VIEW_PRODUCTS = "CAN_VIEW_PRODUCTS";
    public static readonly CAN_MANAGE_PRODUCTS = "CAN_MANAGE_PRODUCTS";
    public static readonly CAN_VIEW_ALL_USER_PREFERENCES = "CAN_VIEW_ALL_USER_PREFERENCES";
    public static readonly CAN_MANAGE_ALL_USER_PREFERENCES = "CAN_MANAGE_ALL_USER_PREFERENCES";
    public static readonly CAN_MANAGE_OWN_PREFERENCES = "CAN_MANAGE_OWN_PREFERENCES";
    public static readonly CAN_CREATE_OR_UPDATE_DESIGNATION = "CAN_CREATE_OR_UPDATE_DESIGNATION";
    public static readonly CAN_VIEW_REMOVE_ATTESTATION_BUTTON = "CAN_VIEW_REMOVE_ATTESTATION_BUTTON";
    public static readonly CAN_SEE_PROGRAMS_PAGE = "CAN_SEE_PROGRAMS_PAGE";
    public static readonly CAN_SEE_PRODUCTS_PAGE = "CAN_SEE_PRODUCTS_PAGE";
    public static readonly CAN_VIEW_FILES = "CAN_VIEW_FILES";
    public static readonly CAN_WRITE_FILES = "CAN_WRITE_FILES";
    public static readonly CAN_ATTEST_FILES = "CAN_ATTEST_FILES";
    public static readonly CAN_REJECT_FILES = "CAN_REJECT_FILES";
    public static readonly CAN_SEND_WELCOME_EMAIL = "CAN_SEND_WELCOME_EMAIL";
}
