import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AffiliationStatus } from '../../../constants/AffiliationStatus';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { Product, Program, ProgramACN, Provider, ProviderAffiliationStatus, ProviderDetails, ProviderReleaseRequestStatus } from '../../../interfaces/ApiInterfaces';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { AffiliationACN, AffiliationButton } from './AffiliationButton';
import './ProviderAffiliations.css';
import { useActingFor } from '../../../hooks/useActingFor';
import { ClearUserMessageAction } from '../../../actions/userMessageAction';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { getLabel } from '../../../components/common/label/Label.library';
import { DialogModal } from '../../../components/common/dialog.modal/DialogModal';

interface Props {
    provider: Provider;
    setProvider: Function
}

interface MyProduct extends Product {
    disabled: boolean;
}

interface MyProgram extends Program {
    disabled: boolean;
}

export const ProviderAffiliations: React.FC<Props> = ({ provider, setProvider }) => {

    const acnActingFor = useActingFor();
    const dispatch = useDispatch();
    const [products, setProducts] = useState<MyProduct[] | null>(null);
    const [programs, setPrograms] = useState<MyProgram[] | null>(null);
    const [affiliations, setAffiliations] = useState<ProviderAffiliationStatus[] | null>(null);
    const [releaseRequests, setReleaseRequests] = useState<ProviderReleaseRequestStatus[] | null>(null);
    const [isAffiliationActionInProgress, setIsAffiliationActionInProgress] = useState(false);
    const canManageProviders = hasPermissions(Permissions.CAN_MANAGE_PROVIDERS);
    const [showAffiliationCycleParticipationDialog, setShowAffiliationCycleParticipationDialog] = useState(false);
    const [affiliationCycleParticipationDialogTitle, setAffiliationCycleParticipationDialogTitle] = useState('');

    useEffect(() => { loadPrograms(); }, [acnActingFor.id]);
    useEffect(() => { loadProducts(); }, [acnActingFor.id, programs]);
    useEffect(() => { loadAffiliations(); }, [acnActingFor.id, provider.affiliationStatus, provider.hasActiveReleaseRequests]);
    useEffect(() => { loadReleaseRequests(); }, [acnActingFor.id]);


    async function loadPrograms() {
        const data = {
            IncludeInactive: false,
            OrderBy: "Name"
        };
        const isInitialLoad = !programs;
        const acnPrograms: ProgramACN[] = (await makeJSONPostRequest(createUrl(ApiUrls.GET_ACN_PROGRAMS_AND_PRODUCTS, { acnId: acnActingFor.id }), dispatch, isInitialLoad, isInitialLoad)).body;
        const allActivePrograms: ProgramACN[]  = (await makeJSONPostRequest(ApiUrls.GET_PROGRAMS, dispatch, data, isInitialLoad, isInitialLoad)).body.result;
        const myPrograms: MyProgram[] = allActivePrograms.map(program => ({
            ...program,
            products: acnPrograms.find(pr => pr.id === program.id)?.products ?? [],
            disabled: !acnPrograms.some(pr => pr.id === program.id)
        }));
        setPrograms(myPrograms);
        if (isInitialLoad) dispatch(ClearUserMessageAction());
    }

    async function loadProducts() {
        const isInitialLoad = !products;
        const allProducts: Product[] = (await makeJSONGetRequest(ApiUrls.GET_ALL_PRODUCTS, dispatch, null, isInitialLoad, isInitialLoad)).body;
        const provProducts: Product[] = (await makeJSONGetRequest(createUrl(ApiUrls.GET_PROVIDER_PRODUCTS, { providerId: provider.id }), dispatch, null, isInitialLoad, isInitialLoad)).body;
        const myProducts: MyProduct[] = allProducts.map(product => ({
            ...product,
            disabled: !provProducts.some(p => p.id === product.id) || (!!programs && !programs.some(p => p.products.some(prod => prod.id === product.id) && !p.disabled))
        }));
        setProducts(myProducts);
        if (isInitialLoad) dispatch(ClearUserMessageAction());
    }
    async function loadAffiliations() {
        const url = createUrl(ApiUrls.ACN_AFFILIATION_STATUS, { acnId: acnActingFor.id });
        const data = [provider.id];
        const isInitialLoad = !affiliations;
        const response = await makeJSONPostRequest(url, dispatch, data, isInitialLoad, isInitialLoad);
        setAffiliations(response.body);
        if (isInitialLoad) dispatch(ClearUserMessageAction());
    }

    async function loadReleaseRequests() {
        const url = createUrl(ApiUrls.PROVIDER_ACTIVE_RELEASE_REQUESTS, { providerId: provider.id });
        const isInitialLoad = !releaseRequests;
        const response = await makeJSONGetRequest(url, dispatch, null, isInitialLoad, isInitialLoad);
        setReleaseRequests(response.body);
        if (isInitialLoad) dispatch(ClearUserMessageAction());
    }

    function getCellAffiliatedWith(product: MyProduct, program: MyProgram): AffiliationACN | null {
        if (product.disabled || program.disabled) {
            return null;
        }
        const aff = affiliations!.filter(a => a.productId === product.id && a.programId === program.id);
        return aff.length && (aff[0].status === AffiliationStatus.UNAVAILABLE || aff[0].status === AffiliationStatus.RELEASE_PENDING) 
            ? {id: aff[0].accountableCareNetworkId, acnName: aff[0].accountableCareNetworkName}
            : null;
    }

    function getCellRequestedBy(product: MyProduct, program: MyProgram): AffiliationACN[] {
        const cellStatus = getCellStatus(product, program);
        if (!cellStatus) {
            return [];
        }
        const cellReleaseRequests = releaseRequests!.filter(rr => rr.productId === product.id && rr.programId === program.id);
        return cellReleaseRequests.length && cellStatus === AffiliationStatus.RELEASE_REQUESTED 
            ? cellReleaseRequests.map(crr => ({id: crr.accountableCareNetworkId, acnName: crr.accountableCareNetworkName})) 
            : [];
    }

    function getCellStatus(product: MyProduct, program: MyProgram): string | null {
        if (product.disabled || program.disabled || !program.products.some(prod => prod.id == product.id)) {
            return null;
        }
        const aff = affiliations!.filter(a => a.productId === product.id && a.programId === program.id);
        return aff.length ? aff[0].status : AffiliationStatus.AVAILABLE;
    }

    function getColStatus(product: MyProduct): string | null {
        if (product.disabled) {
            return null;
        }
        const aff = affiliations!.filter(a => a.productId === product.id);
        return calculateStatus(aff);
    };

    function getRowStatus(program: MyProgram): string | null {
        if (program.disabled) {
            return null;
        }
        if (!program.products.some(prod => products!.some(product => prod.id == product.id && !product.disabled))) {
            return null;
        }

        const aff = affiliations!.filter(a => a.programId === program.id);
        return calculateStatus(aff);
    }

    function calculateStatus(allMatchingAffiliations: ProviderAffiliationStatus[]): string | null {
        const relevantAffiliations = allMatchingAffiliations.filter(a =>
            !products!.find(p => a.productId === p.id)?.disabled &&
            !programs!.find(p => a.programId === p.id)?.disabled);
        if (!allMatchingAffiliations.length) {
            return AffiliationStatus.AVAILABLE; // 'Affiliate'
        }
        if (relevantAffiliations.some(a => a.status === AffiliationStatus.AFFILIATED)) {
            return AffiliationStatus.AFFILIATED; // 'Remove'
        }
        if (relevantAffiliations.some(a => a.status === AffiliationStatus.UNAVAILABLE) && isSingleACN(allMatchingAffiliations)
            && !relevantAffiliations.some(a => a.status === AffiliationStatus.RELEASE_PENDING)) {
            return AffiliationStatus.UNAVAILABLE; // 'Request Release'
        }
        return null; // no button
    }

    function calculateProviderStatus(): string | null {
        if (provider.hasActiveReleaseRequests) {
            return AffiliationStatus.RELEASE_REQUESTED;
        }
        return provider.affiliationStatus;
    }

    function isSingleACN(affiliations: ProviderAffiliationStatus[]): boolean {
        const distinct = (value: any, index: number, arr: any[]) => arr.indexOf(value) === index;
        return affiliations.map(a => a.accountableCareNetworkId).filter(distinct).length === 1;
    }

    function cssDisabled(product?: MyProduct, program?: MyProgram): string {
        const isDisabled = !product ? program?.disabled : (!program ? product?.disabled : (getCellStatus(product, program) == null));
        return isDisabled ? 'disabled' : '';
    }

    async function handleChange() {
        await loadAffiliations();
        await loadReleaseRequests();
        await updateProvider();
    }

    const updateProvider = async () => {
        const providerResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_PROVIDER_WITH_OVERRIDE, { providerId: provider.id, acnId: acnActingFor.id }), dispatch, null, false, false);
        var pro: ProviderDetails = providerResponse.body;
        const id = [pro.id];
        const affiliations = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDER_AFFILIATION_STATUS, { acnId: acnActingFor.id }), dispatch, id, false, false);
        pro.affiliationStatus = affiliations.body.find((e: any) => e.providerId === pro.id).status;

        const releaseRequestCount = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDER_ACTIVE_RELEASE_REQUEST_COUNT, { acnId: acnActingFor.id, providerId: pro.id }), dispatch, null, false, false);
        pro.hasActiveReleaseRequests = releaseRequestCount.body > 0;
        setProvider(pro);
    }

    const dismissAffiliationCycleParticipationDialog = () => {
        setShowAffiliationCycleParticipationDialog(false);
        setAffiliationCycleParticipationDialogTitle('');
    };

    return (
        (!!products && !!programs && !!affiliations && !!releaseRequests) 
            ? <Card className="provider-affiliations">
                <DialogModal id="ProviderAffiliationCycleParticipationDialog" open={showAffiliationCycleParticipationDialog}
                    title={affiliationCycleParticipationDialogTitle} onClickRight={dismissAffiliationCycleParticipationDialog} labelRight={getLabel("providers_new_affiliation_dialog_okay")} />
                <CardContent>
                    <TableContainer className="affiliations-table">
                        <Table>
                            <TableHead>
                                <TableRow className="aff-col-header1">
                                    <TableCell colSpan={2} rowSpan={2} className="aff-col-row-header"></TableCell>
                                    {products.map(product => <TableCell key={product.id} className={cssDisabled(product)} align="center">
                                        {product.name}
                                    </TableCell>)}
                                </TableRow>
                                <TableRow className="aff-col-header2">
                                    {products.map(product => <TableCell key={product.id} className={cssDisabled(product)} align="center">
                                        <AffiliationButton providerId={provider.id} productId={product.id} currentStatus={getColStatus(product)}
                                            onChange={handleChange} providerActive={provider.active} disabled={isAffiliationActionInProgress}
                                            setIsAffiliationActionInProgress={setIsAffiliationActionInProgress}
                                            setShowAffiliationCycleParticipationDialog={setShowAffiliationCycleParticipationDialog}
                                            setAffiliationCycleParticipationDialogTitle={setAffiliationCycleParticipationDialogTitle} />
                                    </TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody className="truncate">
                                {programs.map(program => <TableRow key={program.id} className={cssDisabled(undefined, program)}>
                                    <TableCell component="th" variant="head" className="aff-row-header1">
                                        <div className="aff-program">{program.name}</div>
                                    </TableCell>
                                    <TableCell component="th" variant="head" className="aff-row-header2" align="center">
                                        <AffiliationButton providerId={provider.id} programId={program.id} currentStatus={getRowStatus(program)}
                                            onChange={handleChange} providerActive={provider.active} disabled={isAffiliationActionInProgress}
                                            setIsAffiliationActionInProgress={setIsAffiliationActionInProgress}
                                            setShowAffiliationCycleParticipationDialog={setShowAffiliationCycleParticipationDialog}
                                            setAffiliationCycleParticipationDialogTitle={setAffiliationCycleParticipationDialogTitle} />
                                    </TableCell>
                                    {products.map(product => <TableCell key={product.id} className={cssDisabled(product, program)} align="center">
                                        <AffiliationButton providerId={provider.id}
                                            productId={product.id} programId={program.id} currentStatus={getCellStatus(product, program)}
                                            onChange={handleChange} hasButtonInfo={true} affiliatedWith={getCellAffiliatedWith(product, program)}
                                            requestedBy={getCellRequestedBy(product, program)} providerActive={provider.active}
                                            disabled={isAffiliationActionInProgress} setIsAffiliationActionInProgress={setIsAffiliationActionInProgress}
                                            setShowAffiliationCycleParticipationDialog={setShowAffiliationCycleParticipationDialog}
                                            setAffiliationCycleParticipationDialogTitle={setAffiliationCycleParticipationDialogTitle} />
                                    </TableCell>)}
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <div className="flexRow">
                    <span className="flexFill"></span>
                    {provider.active && <AffiliationButton providerId={provider.id} currentStatus={calculateProviderStatus()}
                        onChange={handleChange} hasButtonInfo={false} providerActive={provider.active}
                        disabled={!canManageProviders || isAffiliationActionInProgress} setIsAffiliationActionInProgress={setIsAffiliationActionInProgress}
                        setShowAffiliationCycleParticipationDialog={setShowAffiliationCycleParticipationDialog}
                        setAffiliationCycleParticipationDialogTitle={setAffiliationCycleParticipationDialogTitle} />
                    }
                </div>
            </Card>
            : <></>
    );
};
