import React from 'react';
import { getLabel } from '../common/label/Label.library';
import { CardContent, Card } from '@mui/material';
import './ViewProgramAttributes.css';
import { Program } from '../../interfaces/ApiInterfaces';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';

interface Props {
    program: Program
}

export const ViewProgramInfo: React.FC<Props> = ({program}) => {

    return (
        <Card>
            <CardContent>
                <div className="view-only program-view-attributes grid">
                    <div><span className="programLabel"><div className="colHeader">{getLabel('program_attributes_name_label')}</div><div>{program.name}</div></span></div>
                    <div><span className="programLabel"><div className="colHeader">{getLabel('program_attributes_description_label')}</div><div>{program.description}</div></span></div>
                    {hasPermissions(Permissions.CAN_VIEW_PROGRAM_EXTERNAL_SYSTEM_ID) && <div><span className="programLabel"><div className="colHeader">{getLabel('program_attributes_external_system_id_label')}</div><div>{program.externalId}</div></span></div>}
                </div>
            </CardContent>
        </Card>
    )
}
