export interface FileSearch {
    filename: string;
    startDate: string;
    endDate: string;
    type: string;
    validationStatus: string;
    operationStatus: string;
    comment: string;
    orderBy: string;
}

export const SET_FILE_SEARCH = "SET_FILE_SEARCH";
export const CLEAR_FILE_SEARCH = "CLEAR_FILE_SEARCH";


interface SetFileSearch {
    type: typeof SET_FILE_SEARCH;
    fileSearch: FileSearch;
}

interface ClearFileSearch {
    type: typeof CLEAR_FILE_SEARCH;
}


export type FileSearchAction = SetFileSearch | ClearFileSearch;

export const SetFileSearchAction = (filename: string, startDate: string, endDate: string, type: string, validationStatus: string, operationStatus: string, comment: string, orderBy: string): SetFileSearch => ({
    type: SET_FILE_SEARCH,
    fileSearch: { filename: filename, startDate: startDate, endDate: endDate, type: type, validationStatus: validationStatus, operationStatus: operationStatus, comment: comment, orderBy: orderBy}
});

export const ClearFileSearchAction = (): ClearFileSearch => ({
    type: CLEAR_FILE_SEARCH
});