import { Container, Card, AppBar, Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiUrls, createUrl } from "../../../../constants/ApiUrls";
import { InfoTitles } from "../../../../constants/InfoTitles";
import { createRoute, ApplicationRoutes } from "../../../../constants/ApplicationRoutes";
import { TabValue } from "../../../../constants/TabValue";
import { DesignationSource, DesignationType, Provider, ProviderDesignation } from "../../../../interfaces/ApiInterfaces";
import { makeJSONGetRequest } from "../../../../services/ajax/ajax";
import { hasPermissions } from "../../../../services/auth/auth";
import { getLabel } from "../../../common/label/Label.library";
import { PageHeading } from "../../../common/page.heading/PageHeading";
import { AuthenticatedLayout } from "../../../layouts/authenticated.layout/AuthenticatedLayout";
import { Permissions } from '../../../../constants/Permissions';
import { useActingForACN } from "../../../../hooks/useActingFor";
import { DesignationAttributes } from "./DesignationAttributes";
import { ViewDesignationAttributes } from "./ViewDesignationAttributes";
import { SetDesignationTypeDropdownAction } from "../../../../actions/designationTypeDropdownAction";
import { SetDesignationSourceDropdownction } from "../../../../actions/designationSourceDropdownAction";
import { designationSourceDropdownValue, designationTypeDropdownValue } from "../../../../reducers/rootReducer";
import { AppState } from "../../../../store/configureStore";
import { useNavigate, useParams } from "react-router-dom";
import { formatEditDate } from "../../../../services/format/date";

export const EditProviderDesignation: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const providerId = +params.providerId!;
    const designationId = +params.id!;
    const acnActingFor = useActingForACN();
    const isAdd = designationId === -1;
    const [tabValue, setTabValue] = useState(0);
    const [provider, updateProvider] = useState<Provider>();
    const [designation, setDesignation] = useState<ProviderDesignation>();
    const canManageDesignations = hasPermissions(Permissions.CAN_CREATE_OR_UPDATE_DESIGNATION);
    const designationTypesValues = useSelector<AppState, DesignationType[]>(designationTypeDropdownValue);
    const designationSourcesValues = useSelector<AppState, DesignationSource[]>(designationSourceDropdownValue);
    
    useEffect(() => {
        document.title = isAdd
        ? `${getLabel('app_name')} ${getLabel('title_add_designation')}`
        : `${getLabel('app_name')} ${getLabel('title_edit_designation')}`;
    }, []);
    
    useEffect(() => {
        if (acnActingFor.id !== -1) {
            const getProvider = async () => {
                var providerResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_PROVIDER, { providerId, acnId: acnActingFor.id }), dispatch, null, false, false);
                updateProvider(providerResponse.body);
            }
            getProvider();
        }
    }, [providerId, acnActingFor.id]);

    useEffect(() => {
        if (!!provider) {
            if (!isAdd) {
                makeJSONGetRequest(createUrl(ApiUrls.GET_DESIGNATION, { designationId }), dispatch, null, false, false).then((designationResponse) => {
                    var designation = designationResponse.body;
                    setDesignation({
                        ...designation,
                        startDate: formatEditDate(designation.startDate),
                        endDate: !!designation.endDate ? formatEditDate(designation.endDate) : ''
                    });
                });
            }
            else {
                setDesignation({
                    id: -1,
                    provider: provider,
                    accountableCareNetwork: acnActingFor,
                    designatingUser: undefined,
                    designationSource: { id: -1, name: '', active: true },
                    designationType: { id: -1, name: '', active: true },
                    startDate: '',
                    endDate: '',
                    active: true
                });
            }
        }
    }, [designationId, provider, dispatch]);

    useEffect(() => {
        const getDesignationTypes = async () => {
            const result = await makeJSONGetRequest(ApiUrls.GET_DESIGNATION_TYPES, dispatch, null, false, false);
            var types = result.body;
            if (types.length === 0)
                throw 'No Designation Types found!?'; // prevent infinite loop
            dispatch(SetDesignationTypeDropdownAction(types));
        }
        if (designationTypesValues.length === 0) {
            getDesignationTypes();
        }
    }, [designationTypesValues, dispatch]);

    useEffect(() => {
        const getDesignationSources = async () => {
            const result = await makeJSONGetRequest(ApiUrls.GET_DESIGNATION_SOURCES, dispatch, null, false, false);
            var sources = result.body;
            if (sources.length === 0)
                throw 'No Designation Sources found!?'; // prevent infinite loop
            dispatch(SetDesignationSourceDropdownction(sources));
        }
        if (designationSourcesValues.length === 0) {
            getDesignationSources();
        }
    }, [designationSourcesValues, dispatch]);
    
    function getProviderAddEditViewLabel() {
        var label = getLabel(
            hasPermissions(Permissions.CAN_MANAGE_PROVIDERS)
                ? 'provider_edit_page_heading'
                : 'provider_view_page_heading', { displayName: `${provider!.pcpFirstName} ${provider!.pcpLastName}` });
        return label;
    }

    function getDesignationAddEditViewLabel() {
        return isAdd
            ? getLabel('provider_designation_add_page_heading')
            : getLabel(
                hasPermissions(Permissions.CAN_CREATE_OR_UPDATE_DESIGNATION) 
                    ? 'provider_designation_edit_page_heading'
                    : 'provider_designation_view_page_heading',
                { displayName: designation!.designationType.name }
            );
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_DESIGNATION, { providerId: provider!.id, id: designationId, tab: TabValue.DESIGNATIONS_ATTRIBUTES }))
        }
        setTabValue(value);
    }
    
    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.UPDATE_DESIGNATION}>
            <Container maxWidth={false} className="designation-attributes-container">
                {!!provider && !!designation &&
                    <>
                        <h2>
                            <PageHeading to={createRoute(ApplicationRoutes.PROVIDERS)} parentHeading={getLabel('providers_page_heading')}>
                                <PageHeading to={createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: designation.provider.id, tab: TabValue.PROVIDER_DESIGNATIONS })} parentHeading={getProviderAddEditViewLabel()}>
                                    {getDesignationAddEditViewLabel()}
                                </PageHeading>
                            </PageHeading>
                        </h2>
                        <Card>
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary" textColor="primary">
                                    <Tab label={getLabel('tab_label_attributes')} value={0} />
                                </Tabs>
                            </AppBar>
                            {tabValue === 0 && (canManageDesignations ? (<DesignationAttributes designation={designation} />) : (<ViewDesignationAttributes designation={designation} />))}
                        </Card>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};