export interface AllProviderSearch {
    nationalProviderId: number | null;
    includeInactive: boolean;
}

export const SET_ALL_PROVIDER_SEARCH = 'SET_ALL_PROVIDER_SEARCH';
export const CLEAR_ALL_PROVIDER_SEARCH = 'CLEAR_ALL_PROVIDER_SEARCH';

interface SetAllProviderSearch {
    type: typeof SET_ALL_PROVIDER_SEARCH;
    allProviderSearch: AllProviderSearch;
}

interface ClearAllProviderSearch {
    type: typeof CLEAR_ALL_PROVIDER_SEARCH;
}

export type AllProviderSearchAction = SetAllProviderSearch | ClearAllProviderSearch;

export const SetAllProviderSearchAction = (nationalProviderId: number | null, includeInactive: boolean

): SetAllProviderSearch => ({
    type: SET_ALL_PROVIDER_SEARCH,
    allProviderSearch: {
        nationalProviderId: nationalProviderId,
        includeInactive: includeInactive
    },
});

export const ClearAllProviderSearchAction = (): ClearAllProviderSearch => ({
    type: CLEAR_ALL_PROVIDER_SEARCH
});