import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Files.css';
import { Button, Card, CardContent, Container, List, ListItem, Paper } from '@mui/material';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Permissions } from '../../constants/Permissions';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { getLabel } from '../../components/common/label/Label.library';
import { hasPermissions } from '../../services/auth/auth';
import { InfoTitles } from '../../constants/InfoTitles';
import { File, FileType as FileTypeInterface } from '../../interfaces/ApiInterfaces';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { useActingFor } from '../../hooks/useActingFor';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { useDispatch, useSelector } from 'react-redux';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { Formik } from 'formik';
import { AppState } from '../../store/configureStore';
import { fileSearchValues } from '../../reducers/rootReducer';
import { TextInput } from '../../components/common/text.input/TextInput';
import { SelectInput } from '../../components/common/select.input/SelectInput';
import { ClearFileSearchAction, FileSearch, SetFileSearchAction } from '../../actions/fileSearchAction';
import { FileOrderBy } from '../../constants/FileOrderBy';
import queryString from 'query-string';
import { FileOperationStatus, FileValidationStatus, getValidationStatuses } from '../../constants/FileStatus';
import { FileDetailsModal } from '../../components/files/FileDetailsModal';
import { FileDetailsRow } from '../../components/files/FileDetailsRow';
import clone from 'lodash/clone';
import { PagingFooter } from '../../components/common/paging/PagingFooter';

export const Files: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [files, setFiles] = useState<File[]>([]);
    const [page, setPage] = useState(0);
    const [totalResults, setTotalResults] = useState(-1);
    const acnActingFor = useActingFor();
    const [isMisconfigured, setIsMisconfigured] = useState(false);
    const searchValues = useSelector<AppState, FileSearch>(fileSearchValues);
    const [fileTypes, setFileTypes] = useState<FileTypeInterface[]>([]);
    const [operationStatuses, setOperationStatuses] = useState<string[]>([]);
    const [showAttestationDialog, setShowAttestationDialog] = useState(false);
    const [modalFile, setModalFile] = useState<File>();

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_list_files')}`
    }, []);

    useEffect(() => {
        const getFileTypes = async () => {
            const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_FILE_OPTIONS, { acnId: acnActingFor.id }), dispatch, null, false, false);
            setFileTypes(response.body.fileTypes);
            setOperationStatuses(response.body.operationStatusCodes);
        }        
        if (acnActingFor.id !== -1) {
            getFileTypes();
        }
    }, [acnActingFor.id]);

    useEffect(() => {
        const checkConnectionAndGetFiles = async () => {
            try {
                let {filename, startDate, endDate, type, validationStatus, operationStatus, comment, orderBy} = searchValues;
                if (!!location.search) {
                    const queryParam = queryString.parse(location.search);
                    filename = Array.isArray(queryParam.file) ? '' : decodeURIComponent(queryParam.file ?? '');
                    validationStatus = Array.isArray(queryParam.validationStatus) ? '' : decodeURIComponent(queryParam.validationStatus ?? '');
                    operationStatus = Array.isArray(queryParam.operationStatus) ? '' : decodeURIComponent(queryParam.operationStatus ?? '');
                    if (!operationStatus && !!queryParam.attestationStatus)  // continue to support older links for a while...
                        operationStatus = Array.isArray(queryParam.attestationStatus) ? '' : decodeURIComponent(queryParam.attestationStatus);
                    startDate = '', endDate = '', type = '', comment = '', orderBy = FileOrderBy.ORDER_BY[0].value;
                }
                await performSearch({
                    Filename: filename,
                    StartDate: startDate,
                    EndDate: endDate,
                    Type: type,
                    ValidationStatus: validationStatus,
                    OperationStatus: operationStatus,
                    Comment: comment,
                    OrderBy: orderBy
                });
                setIsMisconfigured(false);
            } catch {
                setFiles([]);
                setIsMisconfigured(true);
                setTotalResults(-1);
            }
        }
        if (acnActingFor.id !== -1) {
            checkConnectionAndGetFiles();
        }
    }, [acnActingFor.id, location.search]);

    const performSearch = async (values: any) => {
        dispatch(SetFileSearchAction(values.Filename, values.StartDate, values.EndDate, values.Type, values.ValidationStatus, values.OperationStatus, values.Comment, values.OrderBy));
        await getFiles({ Filename: values.Filename, StartDate: values.StartDate, EndDate: values.EndDate, Type: values.Type, ValidationStatus: values.ValidationStatus, OperationStatus: values.OperationStatus, Comment: values.Comment, OrderBy: values.OrderBy }, 1);
    };

    const getFiles = async (values: any, newPage: number, showMessage: boolean = true) => {
        const data = {
            Filename: values.Filename ? values.Filename : null,
            StartDate: values.StartDate ? values.StartDate : null,
            EndDate: values.EndDate ? values.EndDate : null,
            Type: values.Type ? values.Type : null,
            ValidationStatus: values.ValidationStatus ? values.ValidationStatus : null,
            OperationStatus: values.OperationStatus ? values.OperationStatus : null,
            Comment: values.Comment ? values.Comment : null,
            AcnId: acnActingFor.id,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: values.OrderBy,
            OrderByDir: values.OrderBy == 'ModDate' ? 'DESC' : 'ASC'
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_FILES, dispatch, data, true, true);

        if (showMessage) {
            if (response.body.result.length === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('file_search_result_none')));
            }
            else if (response.body.result.length === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('file_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('file_search_result_many', { totalCount: response.body.totalCount })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setFiles(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const getFilesUsingSavedFilters = async (newPage: number) => {
        await getFiles({
            Filename: searchValues.filename,
            StartDate: searchValues.startDate,
            EndDate: searchValues.endDate,
            Type: searchValues.type,
            ValidationStatus: searchValues.validationStatus,
            OperationStatus: searchValues.operationStatus,
            Comment: searchValues.comment,
            OrderBy: searchValues.orderBy
        }, newPage, false);
    };

    const handlePaginationChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
        await getFilesUsingSavedFilters(newPage);
    };

    const clearSearchFilters = async () => {
        dispatch(ClearFileSearchAction());
        await getFiles({ Filename: '', StartDate: '', EndDate: '', Type: '', ValidationStatus: '', OperationStatus: '', Comment: '', OrderBy: FileOrderBy.ORDER_BY[0].value }, 1);
    }

    const getOrderByOptions = (): any => {
        return FileOrderBy.ORDER_BY.map((item: any) => {
            return <option key={item.value} value={item.value}>{item.name}</option>;
        });
    }

    const getTypeOptions = (): any => {
        return fileTypes.map((item: FileTypeInterface) => {
            return <option key={item.id} value={item.name}>{item.name}</option>;
        });
    };

    const getValidationStatusOptions = (): any => {
        return getValidationStatuses().map((status: any) => {
            return <option key={status.value} value={status.value}>{status.name}</option>;
        });
    }

    const getOperationStatusOptions = (): any => {
        return operationStatuses.map((statusCode: string) => {
            return <option key={statusCode} value={statusCode}>{FileOperationStatus.getLabel(statusCode)}</option>;
        });
    }

    const launchAttestationDialog = (file: File): void => {
        if (isFileResultClickable(file)) {
            setModalFile(file);
            setShowAttestationDialog(true);
            dispatch(ClearUserMessageAction());
        }
    }

    const isFileResultClickable = (file: File): boolean => {
        if (file.validationStatus === FileValidationStatus.PENDING) {
            return false;
        }
        const status = new FileOperationStatus(file.operationStatus);
        return status.isAttestable() || status.hasImportDetails();
    }

    const getFileTypeAttestationText = (file: File): string | undefined => {
        const fileType = !!file ? fileTypes.find(type => type.name === file.type) : undefined;
        return fileType?.attestationText;
    }

    const updateIndividualFileInList = (updatedFile: File): void => {
        var updatedFilesList = clone(files);
        const fileToUpdateIndex = updatedFilesList.findIndex(file => file.name === updatedFile.name);
        if (fileToUpdateIndex !== -1) {
            updatedFilesList[fileToUpdateIndex] = updatedFile;
            setFiles(updatedFilesList);
        }
        setModalFile(updatedFile);  // update also the FileDetailsRow inside the file modal
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_FILES}>
            <Container maxWidth={false} className="list-files">
                <FileDetailsModal open={showAttestationDialog}
                    file={modalFile!}
                    attestationText={getFileTypeAttestationText(modalFile!)!}
                    updateIndividualFileInList={updateIndividualFileInList}
                    onPostDelete={async () => { setShowAttestationDialog(false); await getFilesUsingSavedFilters(page); }}
                    onClickClose={() => { setShowAttestationDialog(false); dispatch(ClearUserMessageAction()); }}
                />
                <h2>{getLabel("file_page_heading")}</h2>
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ Filename: searchValues.filename, StartDate: searchValues.startDate, EndDate: searchValues.endDate, Type: searchValues.type, ValidationStatus: searchValues.validationStatus, OperationStatus: searchValues.operationStatus, Comment: searchValues.comment, OrderBy: searchValues.orderBy }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="search-filter-fields">
                                        <TextInput name="StartDate" type="date" label="file_filter_startdate" fullwidth={false} shrink={true} />
                                        <TextInput name="EndDate" type="date" label="file_filter_enddate" fullwidth={false} shrink={true} />
                                        <SelectInput name="Type" label="file_filter_type" values={getTypeOptions()} />
                                        <SelectInput name="ValidationStatus" label="file_filter_validation_status" values={getValidationStatusOptions()} />
                                        <TextInput name="Filename" label="file_filter_filename" fullwidth={false} />
                                        <TextInput name="Comment" label="file_filter_comment" fullwidth={false} />
                                        <SelectInput name="OrderBy" label="file_filter_sort_by" blankOption={false} values={getOrderByOptions()} />
                                        <SelectInput name="OperationStatus" label="file_filter_operation_status" values={getOperationStatusOptions()} />
                                    </div>
                                    <div className="search-filter-buttons">
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                <div>
                    <div className="upload-and-download-all-buttons-row">
                        <div>
                            {hasPermissions(Permissions.CAN_WRITE_FILES) &&
                                <Button type="button" className="upload-button" variant="contained" color="primary" onClick={() => { navigate(createRoute(ApplicationRoutes.UPLOAD_FILE)) }} disabled={isMisconfigured}>
                                    {getLabel('upload_file_button')}
                                </Button>}
                        </div>
                        <div>
                        </div>
                    </div>
                    <List>
                        {files.map((file: File) =>
                            <Paper key={file.name}>
                                <ListItem className={"row" + (!isFileResultClickable(file) ? " no-hand" : "")} button onClick={() => launchAttestationDialog(file)}>
                                    <FileDetailsRow file={file} onPostDelete={async () => await getFilesUsingSavedFilters(page)} />
                                </ListItem>
                            </Paper>
                        )}
                    </List>
                    {files.length < SearchDefaults.TAKE && <div className="paging">{getLabel('file_end_of_results')}</div>}
                    {totalResults === 0 && <p className="paging">{getLabel("file_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePaginationChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};