import React from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik } from 'formik';
import { Button, CardContent, Card } from '@mui/material';
import { AppState } from '../../store/configureStore';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch, useSelector } from 'react-redux';
import { groupSearchValues } from '../../reducers/rootReducer';
import { ClearGroupSearchAction, GroupSearchValues, SetGroupSearchAction } from '../../actions/groupSearchAction'
import { StatesDropdown } from '../../components/common/states.dropdown/StatesDropdown';
import './GroupAttributes.css';

interface Props {
  getGroups: (values: GroupSearchValues, page: number) => Promise<void>;
  disabledSearch: boolean;
}

export const GroupSearch: React.FC<Props> = ({ getGroups, disabledSearch }) => {

  const searchValues = useSelector<AppState, GroupSearchValues>(groupSearchValues);
  const dispatch = useDispatch();

  async function performSearch(values: GroupSearchValues) {
    dispatch(SetGroupSearchAction(values));
    await getGroups(values, 1);
  }

  async function clearGroupSearchFilters() {
    dispatch(ClearGroupSearchAction());
    await getGroups({ name: '', city: '', state: '', includeInactive: false }, 1);
  }

  return (
    <Card>
      <CardContent>
        <Formik enableReinitialize={true}
          initialValues={searchValues}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            performSearch(values).finally(() => {
              actions.setTouched({});
              actions.setSubmitting(false);
            });
          }}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className="search-filter-fields">
                <TextInput name="name" label="group_filter_name_label" fullwidth={false} />
                <TextInput name="city" label="group_filter_city_label" fullwidth={false} />
                <StatesDropdown name="state" label="group_filter_state_label" />
              </div>
              <div className="search-filter-buttons">
                <CheckboxInput name="includeInactive" label="group_filter_include_inactive" />
                <Button className="button" type="button" disabled={props.isSubmitting || disabledSearch} variant="contained" color="primary" onClick={() => { clearGroupSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                <Button className="button" type="submit" disabled={props.isSubmitting || disabledSearch} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
              </div>
            </form>)}
        </Formik>
      </CardContent>
    </Card>
  );
};