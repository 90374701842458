import React, { useState, useEffect, useRef } from 'react';
import { Badge, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { AppState } from '../../../store/configureStore';
import { fetchNotifications, notificationListValues } from '../../../reducers/rootReducer';
import { SetFetchNotificationsAction } from '../../../actions/fetchNotificationsAction';
import { SetNotificationsAction } from '../../../actions/notificationsAction';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import './Notifications.css';
import { CONTEXT_PATH } from '../../../constants/ApplicationRoutes';
import { NotificationType } from '../../../constants/NotificationType';
import { Notification } from '../../../interfaces/ApiInterfaces';
import { useActingFor } from '../../../hooks/useActingFor';

export const Notifications: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const fetch = useSelector<AppState, any>(fetchNotifications);
    const anchorRef = useRef<HTMLDivElement>(null);
    const acnActingFor = useActingFor();
    const notifications = useSelector<AppState, any>(notificationListValues);

    const onMenuClick = async (e: any, id: number, type: string, link: string, requestIds: number[]) => {
      dispatch(SetFetchNotificationsAction(true));
      const navigationTo = link.startsWith(CONTEXT_PATH) ? link : CONTEXT_PATH + link;
      navigate(navigationTo);
      if (type === NotificationType.ProviderReleaseApproved || type === NotificationType.ProviderReleaseDeclined) {
        const data:any = {
          RequestIds: !!requestIds ? requestIds : []
        };
        await makeJSONPostRequest(ApiUrls.DISMISS_RELEASE_REQUEST_NOTIFICATION, dispatch, data, false, false);
      }
      else if (type === NotificationType.DesignationPracticeLocationChanged ||
        type === NotificationType.ExpiringDesignation ||
        type === NotificationType.FileFailedValidation ||
        type === NotificationType.FileAttested ||
        type === NotificationType.FileRejected
      ) {
        await makeJSONPostRequest(createUrl(ApiUrls.DISMISS_ACN_NOTIFICATION, { notificationId: id }), dispatch, null, false, false);
      }
      onClose(e);
    };

    const onClose = (e: any) => {
      if (anchorRef.current && anchorRef.current.contains(e.target)) return;
      setOpen(false);
    };

    const onToggle = () => {
        setOpen((prevOpen) => notifications.length !== 0 && !prevOpen);
    };
  
    function onKeyDown(e: any) {
      if (e.key === 'Tab') {
        e.preventDefault();
        setOpen(false);
      }
    }
  
    useEffect(() => {
        const getNotifications = async () => {
            var acnResult = await makeJSONGetRequest(createUrl(ApiUrls.GET_ACN_NOTIFICATIONS, { acnId: acnActingFor.id }), dispatch, null, false, false);
            var body = acnResult.body;
            body.sort(function (a:any , b: any) {
                return b.id - a.id;
            });
            dispatch(SetNotificationsAction(body));
            
        };
        
        if (acnActingFor.id !== -1) {
          getNotifications();
        }
        dispatch(SetFetchNotificationsAction(false));
    }, [acnActingFor.id, fetch === true]);

    return (
        <>
            <Badge
              className={"notification-badge" + (notifications.length === 0 ? " empty" : "")}
              color="error"
              overlap="circular"
              onClick={onToggle}
              badgeContent={
                <div
                  className="notification-badge-number"
                  ref={anchorRef}
                  aria-controls={isOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true">
                  {notifications.length}
                </div>
              }
            />
            <Popper open={isOpen} placement="bottom-end" anchorEl={anchorRef.current}
              className="notifications-popper" role={undefined} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
                  <Paper>
                    <ClickAwayListener onClickAway={onClose}>
                      <MenuList autoFocusItem={isOpen} id="menu-list-grow" onKeyDown={onKeyDown}>
                        {notifications.map((n: Notification, index: number) =>
                          <MenuItem key={index} onClick={(e) => onMenuClick(e, n.id, n.type, n.link, n.requestIds)}>
                            {n.message}
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
        </>
    );
};
