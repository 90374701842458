import React from 'react';
import { ProviderDetails } from '../../../interfaces/ApiInterfaces';
import { hasPermissions } from '../../../services/auth/auth';
import { getLabel } from '../../common/label/Label.library';
import { Permissions } from '../../../constants/Permissions';
import './ViewProvider.css';
import { useFeatureFlagCheck } from '../../../hooks/useFeatureFlags';
import { FeatureFlags } from '../../../constants/FeatureFlags';

interface Props {
    provider: ProviderDetails;
    isOverride?: boolean;
}

export const ViewProvider: React.FC<Props> = ({ provider, isOverride = false }) => {

    const isPanelStatusEnabled = useFeatureFlagCheck(FeatureFlags.PANEL_STATUS);

    return (
        <div className="view-only provider-view-attributes grid">
            <div>
                <div><div className="colHeader">{getLabel('provider_attributes_pcp_last_name_label')}</div><div>{provider.pcpLastName}</div></div>
                <div><div className="colHeader">{getLabel('provider_attributes_pcp_first_name_label')}</div><div>{provider.pcpFirstName}</div></div>
                {!isOverride &&
                    <div><div className="colHeader">{getLabel('provider_attributes_pcp_npi_label')}</div><div>{provider.pcpnpi != -1 ? provider.pcpnpi : ''}</div></div>}
                <div><div className="colHeader">{getLabel('provider_attributes_pcp_credentials_label')}</div><div>{provider.pcpCredentials}</div></div>
            </div>
            <div>
                <div><div className="colHeader">{getLabel('provider_attributes_specialty_label')}</div><div>{provider.specialty}</div></div>
                {hasPermissions(Permissions.CAN_VIEW_PROVIDER_EXTERNAL_SYSTEM_ID)
                    ? <div><div className="colHeader">{getLabel('provider_attributes_es_id_label')}</div><div>{provider.externalId}</div></div>
                    : <div />}
                {isOverride && isPanelStatusEnabled &&
                    <div><div className="colHeader">{getLabel('provider_attributes_panel_status_label')}</div>
                        <div>{provider.providerOverride?.isPanelClosed ? getLabel("provider_panel_status_closed") : getLabel("provider_panel_status_open")}</div></div>}
            </div>
            <div>
                <div><div className="colHeader">{getLabel('provider_attributes_pp_name_label')}</div><div>{provider.groupNPIName}</div></div>
                <div><div className="colHeader">{getLabel('provider_attributes_pp_npi_label')}</div><div>{provider.groupNPI != -1 ? provider.groupNPI : ''}</div></div>
            </div>
        </div>
    );
};
