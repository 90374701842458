import { ApplicationInfo, ApplicationInfoActionTypes, SET_CURRENT_APP_VERSION, SET_APP_FEATURE_FLAGS, SET_APP_INACTIVITY_TIMEOUT, SET_APP_UPLOAD_RESTRICTIONS } from "../actions/applicationInfoAction";

const initialState: ApplicationInfo = {
    version: '',
    featureFlags: null,
    inactivityTimeoutInMinutes: null,
    uploadRestrictions: null
}

export const applicationInfo = (state: ApplicationInfo = initialState, action: ApplicationInfoActionTypes): ApplicationInfo => {
    switch (action.type) {
        case SET_CURRENT_APP_VERSION:
            return { version: action.applicationInfo.version, featureFlags: state.featureFlags, inactivityTimeoutInMinutes: state.inactivityTimeoutInMinutes, uploadRestrictions: state.uploadRestrictions };
        case SET_APP_FEATURE_FLAGS:
            return { version: state.version, featureFlags: action.applicationInfo.featureFlags, inactivityTimeoutInMinutes: state.inactivityTimeoutInMinutes, uploadRestrictions: state.uploadRestrictions };
        case SET_APP_INACTIVITY_TIMEOUT:
            return { version: state.version, featureFlags: state.featureFlags, inactivityTimeoutInMinutes: action.applicationInfo.inactivityTimeoutInMinutes, uploadRestrictions: state.uploadRestrictions };
        case SET_APP_UPLOAD_RESTRICTIONS:
            return { version: state.version, featureFlags: state.featureFlags, inactivityTimeoutInMinutes: state.inactivityTimeoutInMinutes, uploadRestrictions: action.applicationInfo.uploadRestrictions };
        default: {
            return state;
        }
    }
}

export const getApplicationVersion = (state: ApplicationInfo) => state.version;
export const getApplicationFeatureFlags = (state: ApplicationInfo) => state.featureFlags;
export const getApplicationInactivityTimeoutInMinutes = (state: ApplicationInfo) => state.inactivityTimeoutInMinutes;
export const getApplicationUploadRestrictions = (state: ApplicationInfo) => state.uploadRestrictions;