import { DesignationSource } from "../interfaces/ApiInterfaces";

export interface DesignationSourceDropdown {
    designationSources: DesignationSource[]
}

export const SET_DESIGNATION_SOURCE_DROPDOWN = "SET_DESIGNATION_SOURCE_DROPDOWN";

interface SetDesignationSourceDropdown {
    type: typeof SET_DESIGNATION_SOURCE_DROPDOWN;
    designationSourceDropdown: DesignationSourceDropdown;
}

export type DesignationSourceDropdownAction = SetDesignationSourceDropdown;

export const SetDesignationSourceDropdownction = (designationSources: DesignationSource[]): SetDesignationSourceDropdown => ({
    type: SET_DESIGNATION_SOURCE_DROPDOWN,
    designationSourceDropdown: { designationSources: designationSources }
});
