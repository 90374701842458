export interface CycleDropdown {
    cycles: any
}

export const SET_CYCLE_DROPDOWN = "SET_CYCLE_DROPDOWN";

interface SetCycleDropdown {
    type: typeof SET_CYCLE_DROPDOWN;
    cycleDropdown: CycleDropdown;
}

export type CycleDropdownAction = SetCycleDropdown;

export const SetCycleDropdownAction = (cycles: any): SetCycleDropdown => ({
    type: SET_CYCLE_DROPDOWN,
    cycleDropdown: { cycles: cycles}
});
