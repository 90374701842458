import { useSelector } from "react-redux";
import { ACNActingForValue } from "../actions/acnActingForValueAction";
import { ACN } from "../interfaces/ApiInterfaces";
import { acnActingForValue } from "../reducers/rootReducer";
import { getUserAcnId } from "../services/auth/auth";
import { AppState } from "../store/configureStore";
import { useACNDropdownValues } from "./useACNDropdownValues";

/**
 * Watch out for returned id == -1... Possible temporarily, depending on timing (loading ACNs in the background).
 */
export function useActingFor() {
    return useSelector<AppState, ACNActingForValue>(acnActingForValue);
}

export function useActingForId(): number {
    const actingFor = useActingFor();
    return actingFor.id;
}

export function useActingForACN(): ACN {
    const actingFor = useActingFor();
    const acnValues = useACNDropdownValues();
    var acnIndex = acnValues.findIndex((acn: ACN) => acn.id === actingFor.id);
    return acnIndex !== -1
        ? acnValues[acnIndex]
        : {id: -1, acnName: '', contactName: '', email: '', telephone: null, address1: '', address2: '', city: '', state: '', zipCode: '', active: false, externalId: '', ein: null, approvedForImport: false};
}

/**
 * Use to add the `ActingForDetails` class fields to an API request payload.
 * @param actingFor Current value which you can get from `useActingFor` hook.
 */
export function getActingForDetails(actingFor: ACNActingForValue) {
    const isActingFor = !getUserAcnId();
    return {
        IsUserActingForACN: isActingFor,
        UserActingForACNReason: isActingFor ? actingFor.reason : undefined
    };
}