import { SuccessMessage, SuccessMessageAction, SET_SUCCESS_MESSAGE, CLEAR_SUCCESS_MESSAGE } from '../actions/successMessageAction';

const initialState: SuccessMessage = { message: '' };

export const successMessage = (
    state: SuccessMessage = initialState,
    action: SuccessMessageAction
): SuccessMessage => {
    if (action.type === SET_SUCCESS_MESSAGE) {
        return {
            message: action.successMessage.message
        };
    } else if (action.type === CLEAR_SUCCESS_MESSAGE) {
        return initialState;
    }
    return state;

};

export const getSuccessMessage = (state: SuccessMessage) => state.message;
