export interface MyProviderSearch {
    firstName: string;
    lastName: string;
    groupNPI: number | null;
    affiliationStatus: string;
    includeInactive: boolean;
}

export const SET_MY_PROVIDER_SEARCH = 'SET_MY_PROVIDER_SEARCH';
export const CLEAR_MY_PROVIDER_SEARCH = 'CLEAR_MY_PROVIDER_SEARCH';

interface SetMyProviderSearch {
    type: typeof SET_MY_PROVIDER_SEARCH;
    myProviderSearch: MyProviderSearch;
}

interface ClearMyProviderSearch {
    type: typeof CLEAR_MY_PROVIDER_SEARCH;
}

export type MyProviderSearchAction = SetMyProviderSearch | ClearMyProviderSearch;

export const SetMyProviderSearchAction = (includeInactive: boolean, affiliationStatus: string, groupNPI: number | null, firstName: string, lastName: string

): SetMyProviderSearch => ({
    type: SET_MY_PROVIDER_SEARCH,
    myProviderSearch: {
        includeInactive: includeInactive,
        affiliationStatus: affiliationStatus,
        groupNPI: groupNPI,
        firstName: firstName,
        lastName: lastName
    },
});

export const ClearMyProviderSearchAction = (): ClearMyProviderSearch => ({
    type: CLEAR_MY_PROVIDER_SEARCH
});