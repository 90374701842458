import React from 'react';
import { Header } from '../../header/Header';
import { Footer } from '../../footer/Footer';
import { UserMessagePanel } from '../../user.message.panel/UserMessagePanel';
import './UnauthenticatedLayout.css';

export const UnauthenticatedLayout: React.FC<any> = (props) => {

  return (
    <div className="unauthenticated-layout">
      <Header {...props} />
      <UserMessagePanel />
      <div className="registration-content">{props.children}</div>
      <Footer />
    </div>
  );
};
