export interface ProgramACNsSearch {
    acnName: string;
}

export const SET_PROGRAM_ACNS_SEARCH = "SET_PROGRAM_ACNS_SEARCH";
export const CLEAR_PROGRAM_ACNS_SEARCH = "CLEAR_PROGRAM_ACNS_SEARCH";

interface SetProgramACNsSearch {
    type: typeof SET_PROGRAM_ACNS_SEARCH;
    programACNsSearch: ProgramACNsSearch;
}

interface ClearProgramACNsSearch {
    type: typeof CLEAR_PROGRAM_ACNS_SEARCH;
}

export type ProgramACNsSearchAction = SetProgramACNsSearch | ClearProgramACNsSearch;

export const SetProgramACNsSearchAction = (acnName: string): SetProgramACNsSearch => ({
    type: SET_PROGRAM_ACNS_SEARCH,
    programACNsSearch: { acnName: acnName }
});

export const ClearProgramACNsSearchAction = (): ClearProgramACNsSearch => ({
    type: CLEAR_PROGRAM_ACNS_SEARCH
});