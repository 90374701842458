export interface ACNDropdownValues { acns: any }

export const SET_ACN_DROPDOWN_VALUES = "SET_ACN_DROPDOWN_VALUES";

interface SetACNDropdownValues {
    type: typeof SET_ACN_DROPDOWN_VALUES;
    acnDropdownValues: ACNDropdownValues;
}

export type ACNDropdownValuesAction = SetACNDropdownValues;

export const SetACNDropdownValuesAction = (acns: any): SetACNDropdownValues => ({
    type: SET_ACN_DROPDOWN_VALUES,
    acnDropdownValues: { acns: acns }
});
