import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper } from '@mui/material';
import './Programs.css';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { ClearProgramSearchAction, ProgramSearch, SetProgramSearchAction } from '../../actions/programSearchAction';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { programSearchValues, successMessageValue } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { TabValue } from '../../constants/TabValue';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { useActingFor } from '../../hooks/useActingFor';
import { InfoTitles } from '../../constants/InfoTitles';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { ClearSuccessMessageAction } from '../../actions/successMessageAction';
import { useLocation, useNavigate } from 'react-router-dom';

export const Programs: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [programs, setPrograms] = useState<any>([]);
    const searchValues = useSelector<AppState, ProgramSearch>(programSearchValues);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [page, setPage] = useState(0);
    const acnActingFor = useActingFor();
    const canEdit = hasPermissions(Permissions.CAN_UPDATE_PROGRAM);
    const successMessage = useSelector<AppState, string>(successMessageValue);

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_list_programs')}`
    }, []);

    useEffect(() => {
        if (location.state && location.state.clearFilters) {
            clearSearchFilters();
        }
        else {
            getPrograms(searchValues, true, true);
        }
    }, []);

    async function performSearch(values: any) {
        dispatch(SetProgramSearchAction(values.Name, values.Description, values.IncludeInactive));
        await getPrograms({ name: values.Name, description: values.Description, includeInactive: values.IncludeInactive }, true, true);
    }

    async function clearSearchFilters() {
        dispatch(ClearProgramSearchAction());
        await getPrograms({ name: '', description: '', includeInactive: false }, true, true);
    }

    async function getPrograms(values: any, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) {
        const data: any = {
            Name: values.name,
            Description: values.description,
            IncludeInactive: values.includeInactive,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        if (!data.Description) delete data.Description;
        const response = await makeJSONPostRequest(ApiUrls.GET_PROGRAMS, dispatch, data, showSucessMessage, showWorkingMessage);

        if (showSucessMessage) {
            if (!!successMessage) {
                dispatch(SetUserMessageSuccessAction(successMessage));
            }
            else if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('program_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('program_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('program_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        }
        dispatch(ClearSuccessMessageAction());
        setPrograms(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        setPage(1);
    }

    async function updatePage(value: number) {
        setPage(value);
        setPrograms([]);
        setTotalResults(-1);
        const data: any = {
            Name: searchValues.name,
            Description: searchValues.description,
            IncludeInactive: searchValues.includeInactive,
            skip: skip + ((value - page) * take),
            take: take,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        if (!data.Description) delete data.Description;
        const response = await makeJSONPostRequest(ApiUrls.GET_PROGRAMS, dispatch, data);
        dispatch(ClearUserMessageAction());
        setPrograms(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        console.log(programs);
    };

    const selectProgram = (id: number) => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: id, tab: TabValue.PROGRAM_ATTRIBUTES }));
    };

    const createNewProgram = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: -1, tab: TabValue.PROGRAM_ATTRIBUTES }));
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await updatePage(value);
    };

    const updateProgramsJoined = (programId: number) => {
        var program = programs.find((p: any) => p.id === programId);
        if (program) program.acnIds.push(acnActingFor.id);
        setPrograms(programs);
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_PROGRAMS}>
            <Container maxWidth={false} className="view-programs">
                <h2>{getLabel("program_page_heading")}</h2>
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{
                                Name: searchValues.name,
                                Description: searchValues.description,
                                IncludeInactive: searchValues.includeInactive
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="search-filter-fields">
                                        <TextInput name="Name" label="program_filter_name" fullwidth={false} />
                                        <TextInput name="Description" label="program_filter_description" fullwidth={false} />
                                        <div className="search-filter-buttons">
                                            <CheckboxInput name="IncludeInactive" label="program_filter_include_inactive" />
                                            <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>
                                                {getLabel('clear_search_filter_button_label')}
                                            </Button>
                                            <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">
                                                {getLabel('perform_search_button_label')}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
                {canEdit && <Button id="addButton" variant="contained" color="primary" onClick={createNewProgram}>{getLabel("program_list_add")}</Button>}
                <div>
                    <List id="resultList">
                        {programs.map((program: { id: number; name: string; description: string; active: boolean; acnIds: any }) =>
                            <Paper key={program.id}>
                                <ListItem className="row" button onClick={() => selectProgram(program.id)}>
                                    <div className="flexCenter program-list-row truncate">
                                        <div>
                                            {program.active && <div className="name">{program.name}</div>}
                                            {!program.active && <div className="name inactive-result">{getLabel('inactive_name', { name: program.name })}</div>}
                                            <div>
                                                <span>
                                                    <div className="colHeader">{getLabel("program_list_description_header")}</div>
                                                    <div>{program.description}</div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </ListItem>
                            </Paper>
                        )}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("program_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
