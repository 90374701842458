import { Card, CardContent, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetRoleDropdownAction } from '../../../actions/roleDropdownAction';
import { ApiUrls } from '../../../constants/ApiUrls';
import { User } from '../../../interfaces/ApiInterfaces';
import { roleDropdownValues } from '../../../reducers/rootReducer';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { AppState } from '../../../store/configureStore';
import { getLabel } from '../../common/label/Label.library';
import './ViewUserAttributes.css';
import { getAllowLoginLabel } from '../UserAttributesUtil';
import { useFeatureFlagCheck } from '../../../hooks/useFeatureFlags';
import { FeatureFlags } from '../../../constants/FeatureFlags';

interface Props {
  user: User;
}

export const ViewUserInfo: React.FC<Props> = ({ user }) => {
  const [roles, setRoles] = useState<Array<any>>([]);
  const roleValues = useSelector<AppState, any>(roleDropdownValues);
  const dispatch = useDispatch();
  const isSSOFeatureEnabled = useFeatureFlagCheck(FeatureFlags.SSOLogIn);

  useEffect(() => {
    const getRoles = async () => {
      var result;
      if (!roleValues) {
        result = await makeJSONGetRequest(ApiUrls.GET_ROLES, dispatch, null, false, false);
        dispatch(SetRoleDropdownAction(result));
      } else {
        result = roleValues;
      }
      const body = result.body as Array<any>;
      setRoles(body);
    };
    if (roles.length <= 0) {
      getRoles();
    }
  }, [roles, dispatch]);

  return (
    <Card>
      <CardContent>
        <div className='view-only edit-user-view-attributes grid'>
          <div>
            <span><div className='colHeader'>{getLabel('user_attributes_displayName_label')}</div><div className='user-data'>{user.name}</div></span>
          </div>
          <div>
            <span><div className='colHeader'>{getLabel('user_attributes_email_label')}</div><div className='user-data'>{user.email}</div></span>
          </div>
          <div>
            <span className='user-checkboxes'>
              <div className='colHeader'>{getLabel('user_attributes_roles_label')}</div>
              <div className='user-data'>{roles != null && roles.map((role: any) => {
                if (!role.code.includes('View-Only'))
                  return (
                    <span className='user-attributes-checkbox'>
                      <Checkbox name={role.name} checked={user.roleIds.some((x: number) => x === role.id || x === role.readRoleId)} disabled={true} />
                      {getLabel(role.name)}
                    </span>)
              })}</div>
            </span>
          </div>
          <div>
            <span className='user-checkboxes'>
              <div className='colHeader'>{getLabel('user_attributes_access_label')}</div>
              <div className='user-data flexColumn'>
                <span className='user-attributes-checkbox'>
                  <Checkbox checked={user.viewOnly} disabled />{getLabel('user_attributes_viewOnly_label')}
                </span>
                <span className="flexFill"></span>
                {isSSOFeatureEnabled && <>
                  <span className='user-attributes-checkbox'>
                    <Checkbox checked={!!user.allowPwdLogin} disabled />{getAllowLoginLabel(user, 'Pwd')}
                  </span>
                  <span className='user-attributes-checkbox'>
                    <Checkbox checked={!!user.allowSsoLogin} disabled />{getAllowLoginLabel(user, 'Sso')}
                  </span>
                </>}
              </div>
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}