import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper } from '@mui/material';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { ClearProductSearchAction, ProductSearch, SetProductSearchAction } from '../../actions/productSearchAction';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { productSearchValues, successMessageValue } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { TabValue } from '../../constants/TabValue';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { InfoTitles } from '../../constants/InfoTitles';
import { Product } from '../../interfaces/ApiInterfaces';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { ClearSuccessMessageAction } from '../../actions/successMessageAction';
import './Products.css';
import { useNavigate } from 'react-router-dom';

export const Products: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [products, setProducts] = useState<any>([]);
    const searchValues = useSelector<AppState, ProductSearch>(productSearchValues);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [page, setPage] = useState(0);
    const successMessage = useSelector<AppState, string>(successMessageValue);

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_list_products')}`
    }, []);

    useEffect(() => {
        getProducts(searchValues, true, true);
    }, []);

    async function performSearch(values: any) {
        dispatch(SetProductSearchAction(values.Name, values.IncludeInactive));
        await getProducts({ name: values.Name, includeInactive: values.IncludeInactive }, true, true);
    }

    async function clearSearchFilters() {
        dispatch(ClearProductSearchAction());
        await getProducts({ name: '', includeInactive: false }, true, true);
    }

    async function getProducts(values: any, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) {
        const data: any = {
            Name: values.name,
            IncludeInactive: values.includeInactive,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        const response = await makeJSONPostRequest(ApiUrls.GET_PRODUCTS, dispatch, data, showSucessMessage, showWorkingMessage);

        dispatch(ClearUserMessageAction());

        if (showSucessMessage) {
            if (!!successMessage) {
                dispatch(SetUserMessageSuccessAction(successMessage));
            }
            else if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('product_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('product_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('product_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        }
        dispatch(ClearSuccessMessageAction());
        setProducts(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        setPage(1);
    }

    async function updatePage(value: number) {
        setPage(value);
        setProducts([]);
        setTotalResults(-1);
        const data: any = {
            Name: searchValues.name,
            IncludeInactive: searchValues.includeInactive,
            skip: skip + ((value - page) * take),
            take: take,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        if (!data.Description) delete data.Description;
        const response = await makeJSONPostRequest(ApiUrls.GET_PRODUCTS, dispatch, data);
        dispatch(ClearUserMessageAction());
        setProducts(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
    };

    const selectProduct = (id: number) => {
        navigate(createRoute(ApplicationRoutes.EDIT_PRODUCT, { id: id, tab: TabValue.PRODUCT_ATTRIBUTES }));
    };

    const createNewProduct = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_PRODUCT, { id: -1, tab: TabValue.PRODUCT_ATTRIBUTES }));
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await updatePage(value);
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_PRODUCTS}>
            <Container maxWidth={false} className="view-products">
                <h2>{getLabel("product_page_heading")}</h2>
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{
                                Name: searchValues.name,
                                IncludeInactive: searchValues.includeInactive
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="search-filter-fields">
                                        <TextInput name="Name" label="product_filter_name" fullwidth={false} />
                                        <div className="search-filter-buttons">
                                            <CheckboxInput name="IncludeInactive" label="product_filter_include_inactive" />
                                            <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>
                                                {getLabel('clear_search_filter_button_label')}
                                            </Button>
                                            <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">
                                                {getLabel('perform_search_button_label')}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
                {hasPermissions(Permissions.CAN_CREATE_PRODUCT) && <Button id="addButton" variant="contained" color="primary" onClick={createNewProduct}>{getLabel("product_list_add")}</Button>}
                <div>
                    <List id="resultList">
                        {products.map((product: Product) => <Paper key={product.id}> <ListItem className="row" button onClick={() => selectProduct(product.id)}>
                            {product.active && <div className="name">{product.name}</div>}
                            {!product.active && <div className="name inactive-result">{getLabel('inactive_name', { name: product.name })}</div>}
                            <div className="product-row-data truncate grid">
                                <span><div className="colHeader">{getLabel("product_list_exportName_header")}</div><div>{product.exportName}</div></span>
                                <span><div className="colHeader">{getLabel("product_list_rosterImportName_header")}</div><div>{product.rosterImportName}</div></span>
                                <span><div className="colHeader">{getLabel("product_list_providerImportName_header")}</div><div>{product.providerImportName}</div></span>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("product_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
