import { DesignationType } from "../interfaces/ApiInterfaces";

export interface DesignationTypeDropdown {
    designationTypes: DesignationType[]
}

export const SET_DESIGNATION_TYPE_DROPDOWN = "SET_DESIGNATION_TYPE_DROPDOWN";

interface SetDesignationTypeDropdown {
    type: typeof SET_DESIGNATION_TYPE_DROPDOWN;
    designationTypeDropdown: DesignationTypeDropdown;
}

export type DesignationTypeDropdownAction = SetDesignationTypeDropdown;

export const SetDesignationTypeDropdownAction = (designationTypes: DesignationType[]): SetDesignationTypeDropdown => ({
    type: SET_DESIGNATION_TYPE_DROPDOWN,
    designationTypeDropdown: { designationTypes: designationTypes }
});
