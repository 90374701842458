import React from 'react';
import { getLabel } from '../common/label/Label.library';
import { CardContent, Card } from '@mui/material';
import './ViewProductAttributes.css';
import { Product } from '../../interfaces/ApiInterfaces';

interface Props {
    product: Product
}

export const ViewProductAttributes: React.FC<Props> = ({product}) => {

    return (
        <Card>
            <CardContent>
                <div className="view-only product-view-attributes grid">
                    <div><span className="productLabel"><div className="colHeader">{getLabel('product_attributes_name_label')}</div><div>{product.name}</div></span></div>
                    <div><span className="productLabel"><div className="colHeader">{getLabel('product_attributes_export_name_label')}</div><div>{product.exportName}</div></span></div>
                    <div><span className="productLabel"><div className="colHeader">{getLabel('product_attributes_roster_import_name_label')}</div><div>{product.rosterImportName}</div></span></div>
                    <div><span className="productLabel"><div className="colHeader">{getLabel('product_attributes_provider_import_name_label')}</div><div>{product.providerImportName}</div></span></div>
                </div>
            </CardContent>
        </Card>
    )
}
