import { Button, Card, CardContent } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { CheckboxInput } from "../../common/checkbox.input/CheckboxInput";
import { getLabel } from "../../common/label/Label.library";
import { TextInput } from "../../common/text.input/TextInput";
import { MyProviderSearch } from "../../../actions/myProviderSearchAction";
import { myProviderSearchValues } from "../../../reducers/rootReducer";
import { SelectInput } from "../../common/select.input/SelectInput";
import { getStatuses } from "../../../constants/AffiliationStatus";
import { hasPermissions } from "../../../services/auth/auth";
import { Permissions } from '../../../constants/Permissions';

interface Props {
    performSearch: any;
    clearSearch: any;
    disabled: boolean;
}

export const MyProvidersSearch: React.FC<Props> = ({ performSearch, clearSearch, disabled }) => {
    const searchValues = useSelector<AppState, MyProviderSearch>(myProviderSearchValues);

    return (
        <Card>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        firstName: searchValues.firstName,
                        lastName: searchValues.lastName,
                        groupNPI: searchValues.groupNPI ?? '',
                        affiliationStatus: searchValues.affiliationStatus,
                        includeInactive: searchValues.includeInactive
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        performSearch(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form className="providers-search" onSubmit={props.handleSubmit}>
                            <div className="search-filter-fields">
                                <TextInput name="firstName" type="string" label="providers_filter_first_name" fullwidth={false} />
                                <TextInput name="lastName" type="string" label="providers_filter_last_name" fullwidth={false} />
                                <TextInput name="groupNPI" type="number" label="providers_filter_group_npi" fullwidth={false} />
                                <SelectInput name="affiliationStatus" label="providers_filter_affiliation_status" values={getStatuses().map((p: any) =>
                                    <option key={p.name} value={p.name}>
                                        {p.value}
                                    </option>
                                )} />
                            </div>
                            <div className="search-filter-buttons">
                                {hasPermissions(Permissions.CAN_VIEW_ALL_PROVIDERS) && <CheckboxInput name="includeInactive" label="providers_filter_include_inactive" />}
                                <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearch(); props.resetForm(); }}>
                                    {getLabel('clear_search_filter_button_label')}
                                </Button>
                                <Button className="button" type="submit" disabled={props.isSubmitting || disabled} variant="contained" color="primary">
                                    {getLabel('perform_search_button_label')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};
