import React from "react";
import DOMPurify from "dompurify";
import './ImportResultFileDetails.css';

interface Props {
    importDetail: string;
}

export const ImportResultFileDetails : React.FC<Props> = ({ importDetail }) => {
    return (
        <div className="file-import-result"
            dangerouslySetInnerHTML={{ __html: !!importDetail ? DOMPurify.sanitize(importDetail, { ALLOWED_TAGS: ['b', 'br'] }) : ''}}
        />
    );
};