export const formatPhone = (value: any) => {
    value = String(value)
    value = value.replace(/\D/g, '')
    var first = value.substring(0, 3);
    var second = value.substring(3, 6);
    var third = value.substring(6);
    var returnString = '';
    if (!!first) {
        returnString = '(' + first + ')';
        if (!!second) {
            returnString += ' ' + second;
        }
        if (!!third) {
            returnString += '-' + third;
        }
        return returnString;
    }
};

export const formatDigits = (value: any) => {
    value = String(value)
    return value.replace(/\D/g, '');
};

