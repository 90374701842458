import { Button, Card, CardContent } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiUrls } from "../../constants/ApiUrls";
import { createRoute, ApplicationRoutes } from "../../constants/ApplicationRoutes";
import { useActingFor, useActingForId, getActingForDetails } from "../../hooks/useActingFor";
import { ProviderDetails } from "../../interfaces/ApiInterfaces";
import { makeJSONPostRequest } from "../../services/ajax/ajax";
import { hasPermissions } from "../../services/auth/auth";
import { getLabel } from "../common/label/Label.library";
import { TextInput } from "../common/text.input/TextInput";
import { ViewProvider } from "./provider.view/ViewProvider";
import { Permissions } from '../../constants/Permissions';
import './ProviderAttributes.css';
import { SetSuccessMessageAction } from "../../actions/successMessageAction";
import { SelectInput } from "../common/select.input/SelectInput";
import { FeatureFlags } from "../../constants/FeatureFlags";
import { useFeatureFlagCheck } from "../../hooks/useFeatureFlags";

interface Props {
    provider: ProviderDetails;
}

enum PanelStatus {
    Open = "open",
    Closed = "closed"
}

export const ProviderAttributes: React.FC<Props> = ({ provider }) => {
    const acnActingFor = useActingFor();
    const acnId = useActingForId();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isPanelStatusEnabled = useFeatureFlagCheck(FeatureFlags.PANEL_STATUS);

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.PROVIDERS));
    };

    const saveProviderOverride = async (values: any, actions: any) => {
        const data = {
            ProviderId: provider.id,
            AccountableCareNetworkId: acnId,
            PCPLastName: values.pcpLastName ? values.pcpLastName : null,
            PCPFirstName: values.pcpFirstName ? values.pcpFirstName : null,
            PCPCredentials: values.pcpCredentials ? values.pcpCredentials : null,
            Specialty: values.specialty ? values.specialty : null,
            ExternalId: values.esId ? values.esId : null,
            GroupNPI: values.groupNPI ? values.groupNPI : null,
            IsPanelClosed: values.panelStatus === PanelStatus.Closed,
            ...getActingForDetails(acnActingFor)
        };

        try {
            await makeJSONPostRequest(ApiUrls.EDIT_PROVIDER, dispatch, data);
            dispatch(SetSuccessMessageAction('provider_override_attributes_success_edit_text'));
            navigate(createRoute(ApplicationRoutes.PROVIDERS));
        } catch (error: any) {
            actions.setSubmitting(false);
        }
    };

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (values.pcpLastName.trim() && /[,'\"|\t]/.test(values.pcpLastName)) {
            errors.pcpLastName = getLabel('validation_message_special_characters');
        }
        if (values.pcpFirstName.trim() && /[,'\"|\t]/.test(values.pcpFirstName)) {
            errors.pcpFirstName = getLabel('validation_message_special_characters');
        }
        if (values.pcpCredentials.trim() && /[,'\"|\t]/.test(values.pcpCredentials)) {
            errors.pcpCredentials = getLabel('validation_message_special_characters');
        }
        return errors;
    };

    return (
        <Card>
            <CardContent>
                <div className="provider-attributes-label provider-imported-label">{getLabel('provider_imported')}</div>
                <ViewProvider provider={provider} />
                <div className="provider-attributes-label provider-edited-label">{getLabel('provider_edited')}</div>
                <Formik enableReinitialize={true}
                    initialValues={{
                        pcpLastName: provider.providerOverride?.pcpLastName ?? '',
                        pcpFirstName: provider.providerOverride?.pcpFirstName ?? '',
                        pcpCredentials: provider.providerOverride?.pcpCredentials ?? '',
                        specialty: provider.providerOverride?.specialty ?? '',
                        esId: provider.providerOverride?.externalId ?? '',
                        groupNPI: provider.providerOverride?.groupNPI ?? '',
                        panelStatus: provider.providerOverride?.isPanelClosed ? PanelStatus.Closed : PanelStatus.Open
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveProviderOverride(values, actions);
                    }}
                >
                    {(props) => (
                        <form className="edit-provider-attributes" onSubmit={props.handleSubmit}>
                            <div className="edit-provider-attributes-fields grid">
                                <div>
                                    <TextInput name="pcpLastName" label="provider_attributes_pcp_last_name_label" fullwidth={false} />
                                    <TextInput name="pcpFirstName" label="provider_attributes_pcp_first_name_label" fullwidth={false} />
                                    <TextInput name="pcpCredentials" label="provider_attributes_pcp_credentials_label" fullwidth={false} />
                                </div>
                                <div>
                                    <TextInput name="specialty" label="provider_attributes_specialty_label" fullwidth={false} />
                                    {hasPermissions(Permissions.CAN_VIEW_PROVIDER_EXTERNAL_SYSTEM_ID)
                                        ? <TextInput name="esId" label="provider_attributes_es_id_label" fullwidth={false} />
                                        : <div className="text-field" />}
                                    {isPanelStatusEnabled &&
                                        <SelectInput name="panelStatus" label="provider_attributes_panel_status_label" values={[
                                            <option key="1" value={PanelStatus.Open}>{getLabel("provider_panel_status_open")}</option>,
                                            <option key="2" value={PanelStatus.Closed} >{getLabel("provider_panel_status_closed")}</option>
                                        ]} blankOption={false} />
                                    }
                                </div>
                                <div>
                                    <div className="text-field">
                                        <div className="colHeader">{getLabel('provider_attributes_pp_name_label')}</div>
                                        <div>{provider.providerOverride?.groupNPIName ?? ''}</div>
                                    </div>
                                    <TextInput name="groupNPI" type ="number" label="provider_attributes_pp_npi_label" fullwidth={false} />
                                </div>
                            </div>
                            <div className="edit-provider-attributes-buttons">
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>
                                    {getLabel('provider_attributes_cancel_button_label')}
                                </Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={!props.dirty || props.isSubmitting}>
                                    {getLabel('provider_attributes_submit_button_label')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};
