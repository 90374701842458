import { Card, CardContent, Button } from "@mui/material";
import { Formik } from "formik";
import moment from 'moment';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetSuccessMessageAction } from "../../../../actions/successMessageAction";
import { ApiUrls, createUrl } from "../../../../constants/ApiUrls";
import { createRoute, ApplicationRoutes } from "../../../../constants/ApplicationRoutes";
import { TabValue } from "../../../../constants/TabValue";
import { getActingForDetails, useActingFor } from "../../../../hooks/useActingFor";
import { DesignationSource, DesignationType, ProviderDesignation } from "../../../../interfaces/ApiInterfaces";
import { designationSourceDropdownValue, designationTypeDropdownValue } from "../../../../reducers/rootReducer";
import { makeJSONPostRequest } from "../../../../services/ajax/ajax";
import { AppState } from "../../../../store/configureStore";
import { CheckboxInput } from "../../../common/checkbox.input/CheckboxInput";
import { getLabel } from "../../../common/label/Label.library";
import { SelectInput } from "../../../common/select.input/SelectInput";
import { TextInput } from "../../../common/text.input/TextInput";

interface Props {
    designation: ProviderDesignation;
}

export const DesignationAttributes: React.FC<Props> = ({ designation }) => {
    const dispatch = useDispatch();
    const designationTypesValues = useSelector<AppState, DesignationType[]>(designationTypeDropdownValue);
    const designationSourcesValues = useSelector<AppState, DesignationSource[]>(designationSourceDropdownValue);
    const navigate = useNavigate();
    const acnActingFor = useActingFor();
    const isAdd = designation.id === -1;

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (values.Type === -1 || !values.Type) {
            errors.Type = getLabel('validation_message_required');
        }
        if (values.Source === -1 || !values.Source) {
            errors.Source = getLabel('validation_message_required');
        }
        if (!values.StartDate) {
            errors.StartDate = getLabel('validation_message_required');
        }
        if (!values.EndDate) {
            errors.EndDate = getLabel('validation_message_required');
        }
        if (values.StartDate && values.EndDate && moment(values.StartDate).isSameOrAfter(moment(values.EndDate))) {
            errors.StartDate = getLabel('validation_message_end_after_start');
            errors.EndDate = getLabel('validation_message_end_after_start');
        }
        return errors;
    };
    
    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: designation.provider.id, tab: TabValue.PROVIDER_DESIGNATIONS }));
    };

    const saveDesignation = async (values: any, actions: any) => {
        try {
            const data: any = {
                ProviderId: designation.provider.id,
                AccountableCareNetworkId: designation.accountableCareNetwork.id,
                DesignationTypeId: +values.Type,
                DesignationSourceId: +values.Source,
                StartDate: values.StartDate,
                EndDate: values.EndDate,
                Active: values.Active,
                ...getActingForDetails(acnActingFor),
            };

            isAdd 
                ? await makeJSONPostRequest(ApiUrls.CREATE_DESIGNATION, dispatch, data)
                : await makeJSONPostRequest(createUrl(ApiUrls.UPDATE_DESIGNATION, { designationId: designation.id }), dispatch, data);

            dispatch(SetSuccessMessageAction(isAdd ? 'provider_designation_success_add_text' : 'provider_designation_success_edit_text'));
            navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: designation.provider.id, tab: TabValue.PROVIDER_DESIGNATIONS }));
        } catch {
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const getTypeOptions = (): any => {
        return designationTypesValues.map((type) =>
            <option key={type.id} value={type.id}>
                {type.name}
            </option>
        );
    };

    const getSourceOptions = (): any => {
        return designationSourcesValues.map((source) =>
            <option key={source.id} value={source.id}>
                {source.name}
            </option>
        );
    };

    return (
        <Card>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        Type: designation.designationType.id,
                        Source: designation.designationSource.id,
                        StartDate: designation.startDate,
                        EndDate: designation.endDate,
                        Active: designation.active
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveDesignation(values, actions);
                    }}>
                    {(props) => (
                        <form className="edit-user-attributes" onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <SelectInput name="Type" label="provider_designation_attributes_type_label" values={getTypeOptions()} />
                                <SelectInput name="Source" label="provider_designation_attributes_source_label" values={getSourceOptions()} />
                                <TextInput name="StartDate" label="provider_designation_attributes_start_label" fullwidth={false} type="date" shrink={true} />
                                <TextInput name="EndDate" label="provider_designation_attributes_end_label" fullwidth={false} type="date" shrink={true} />
                            </div>
                            <div className="flexRow">
                                <span className="flexFill"></span>
                                <CheckboxInput name="Active" label="provider_designation_attributes_active_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('provider_designation_attributes_cancel_button_label')}</Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={!props.dirty || props.isSubmitting}>{getLabel('provider_designation_attributes_submit_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
            </CardContent>
        </Card>
    )
}