import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { UserInfo } from '../../../components/users/UserAttributes';
import { makeJSONPostRequest, makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import './EditUser.css';
import { getLabel } from '../../../components/common/label/Label.library';
import { TabValue } from '../../../constants/TabValue';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { ACN, User, UserRole } from '../../../interfaces/ApiInterfaces';
import { Permissions } from '../../../constants/Permissions';
import { getUserId, hasPermissions } from '../../../services/auth/auth';
import { ViewUserInfo } from '../../../components/users/user.info.view/ViewUserAttributes';
import { UserAccountableCareNetworks } from '../../../components/users/user.accountable.care.networks/UserAccountableCareNetworks';
import { APIButton } from '../../../components/common/button/APIButton';
import { InfoTitles } from '../../../constants/InfoTitles';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import { UserPreferences } from '../../../components/users/user.preferences/UserPreferences';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogModal } from '../../../components/common/dialog.modal/DialogModal';
import { useFeatureFlagCheck } from '../../../hooks/useFeatureFlags';
import { FeatureFlags } from '../../../constants/FeatureFlags';

export const EditUser: React.FC<any> = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const userId = +params.id!;
    const isAdd = userId === -1;
    const [isResetPwdDialog, setResetPwdDialog] = useState(false);
    const [user, updateUser] = useState<User>();
    const [hasACNRole, updateHasACNRole] = useState(false);
    const canManageUsers = hasPermissions(Permissions.CAN_CREATE_USER) || hasPermissions(Permissions.CAN_UPDATE_USER);
    const isSSOFeatureEnabled = useFeatureFlagCheck(FeatureFlags.SSOLogIn);

    useEffect(() => {
        document.title = isAdd ? `${getLabel('app_name')} ${getLabel('title_add_user')}` : `${getLabel('app_name')} ${getLabel('title_edit_user')}`
    }, [])

    useEffect(() => {
        getUser();
    }, [userId, dispatch]);

    useEffect(() => {
        if (params.tab === TabValue.USER_ATTRIBUTES) {
            setTabIndex(0);
        }
        else if (params.tab === TabValue.USER_PREFERENCES) {
            setTabIndex(1);
        }
        else if (params.tab === TabValue.USER_ACN && !isAdd) {
            setTabIndex(2);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_ATTRIBUTES }))
            setTabIndex(0);
        }
    }, [params.tab, userId]);

    const getUser = async () => {
        if (!isAdd) {
            let userResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_USER, { userId }), dispatch, null, false, false);
            var user = userResponse.body;
            var roleIds: number[] = user.roles.map((role: UserRole) => role.id);
            var acnIds: number[] = user.accountableCareNetworks.map((acn: ACN) => acn.id);
            updateUser({ ...user, acnIds, roleIds });
            updateHasACNRole(user.roles.some((role: UserRole) => role.permissions.some(permission => permission.code === Permissions.CAN_VIEW_ONLY_MY_ACN)));
        }
        else {
            updateUser({
                id: -1, name: '', email: '', active: true,
                allowPwdLogin: null, allowSsoLogin: null, lastPwdLogin: null, lastSsoLogin: null,
                acnIds: [], roleIds: [], viewOnly: false, isCreator: true
            });
        }
    }

    function getAddEditViewLabel() {
        var label;
        if (isAdd) {
            label = getLabel('user_add_page_heading');
        } else {
            label = getLabel(canManageUsers ? 'user_edit_page_heading' : 'user_view_page_heading', { displayName: user!.name });
        }
        return label;
    }

    const getInfoTitle = () => {
        if (tabIndex === 0) {
            return InfoTitles.UPDATE_USER;
        } else {
            return InfoTitles.UPDATE_USER;
        }
    }

    const resetPasswordBegin = () => { setResetPwdDialog(true); }
    const resetPasswordCancel = () => { setResetPwdDialog(false); }
    const resetPasswordEnd = async () => {
        setResetPwdDialog(false);
        const data = { Email: user!.email };
        await makeJSONPostRequest(ApiUrls.SEND_RESET_PASSWORD_EMAIL, dispatch, data);
        dispatch(SetUserMessageSuccessAction('user_password_reset_success_text'));
    }

    const sendOnboardingEmail = async (mode: 'Pwd' | 'Sso') => {
        const data = { Email: user!.email, LoginMode: mode };
        await makeJSONPostRequest(ApiUrls.SEND_ONBOARDING_EMAIL, dispatch, data);
        await getUser();
        dispatch(SetUserMessageSuccessAction('user_onboarding_email_success_text'));
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_ATTRIBUTES }))
        } else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_PREFERENCES }))
        } else if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_ACN }))
        }
        setTabIndex(value);
    }

    const isMissingACNs = () => {
        return hasACNRole && user!.acnIds.length === 0;
    }

    const getUserMisconfiguredMsg = () => {
        if (isAdd || !user) return;
        if (isMissingACNs())
            return getLabel('user_misconfigured_needAssignACN');
        if (user.allowPwdLogin === null && user.allowSsoLogin === null)
            return getLabel('user_misconfigured_needSendInvite');
        if (user.allowPwdLogin !== true && user.allowSsoLogin !== true)
            return getLabel('user_misconfigured_needLoginMethod');
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="user-attributes-container">
                {!!user &&
                    <>
                        <h2><PageHeading to={createRoute(ApplicationRoutes.USERS)} parentHeading={getLabel('user_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="user-attributes-panel">
                            <DialogModal id="resetPwdDialog" open={isResetPwdDialog} title={getLabel('user_resetPwdDialog_message', { email: user.email })}
                                onClickLeft={resetPasswordCancel} labelLeft={getLabel('user_resetPwdDialog_cancel_button')}
                                onClickRight={resetPasswordEnd} labelRight={getLabel('user_resetPwdDialog_confirm_button')} />
                            <AppBar position="static" color="default">
                                <Tabs value={tabIndex} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary" textColor="primary">
                                    <Tab label={getLabel("tab_label_attributes")} value={0} />
                                <Tab label={getLabel("tab_label_preferences")} value={1} disabled={isAdd || (userId !== getUserId() && !hasPermissions(Permissions.CAN_VIEW_ALL_USER_PREFERENCES))} />
                                    <Tab label={getLabel("tab_label_accountable_care_networks")} value={2} disabled={isAdd || !hasACNRole} />
                                </Tabs>
                            </AppBar>
                            {tabIndex === 0 && (canManageUsers 
                                ? (<UserInfo user={user} reloadUser={getUser} />) 
                                : <ViewUserInfo user={user} />
                            )}
                            {tabIndex === 1 && (!isAdd && (userId === getUserId() || hasPermissions(Permissions.CAN_VIEW_ALL_USER_PREFERENCES)) && <UserPreferences user={user} />)}
                            {tabIndex === 2 && (<UserAccountableCareNetworks user={user} updateUser={updateUser} />)}
                        </Card>
                        <div className="flexRow">
                            <span className="flexFill"></span>
                            <span className="user-misconfigured-msg">{getUserMisconfiguredMsg()}</span>
                            {!isAdd && user.allowPwdLogin === null && hasPermissions(Permissions.CAN_SEND_WELCOME_EMAIL) && (
                                <APIButton className="button" type="button" variant="contained" color="primary" onClick={() => sendOnboardingEmail('Pwd')} disabled={isMissingACNs()}>
                                    {getLabel('user_attributes_sendPwdInvite_label')}
                                </APIButton>
                            )}
                            {!isAdd && user.allowSsoLogin === null && hasPermissions(Permissions.CAN_SEND_WELCOME_EMAIL) && isSSOFeatureEnabled && (
                                <APIButton className="button" type="button" variant="contained" color="primary" onClick={() => sendOnboardingEmail('Sso')} disabled={isMissingACNs()}>
                                    {getLabel('user_attributes_sendSsoInvite_label')}
                                </APIButton>
                            )}
                            {!isAdd && tabIndex == 0 && hasPermissions(Permissions.CAN_REQUEST_PASSWORD_RESET) && 
                                <APIButton className="button red-button" type="button" variant="contained" color="primary" onClick={resetPasswordBegin} disabled={!user.allowPwdLogin}>
                                    {getLabel('user_attributes_resetPassword_label')}
                                </APIButton>
                            }
                        </div>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};