import { getLabel } from "../components/common/label/Label.library";

export class FileValidationStatus {
    public static readonly OK = "ok";
    public static readonly FAILED = "failed";
    public static readonly PENDING = "pending";
}

export function getValidationStatuses(): any {
    return [
        { name: "Ok", value: FileValidationStatus.OK },
        { name: "Failed", value: FileValidationStatus.FAILED },
        { name: "Pending", value: FileValidationStatus.PENDING }
    ];
}

export class FileOperationStatus {
    public static readonly NOT_ATTESTED = "unattested";
    public static readonly ATTESTED_IN_USE = "attested";
    public static readonly ATTESTED_REJECTED = "rejected";
    public static readonly IMPORT_IN_PROGRESS = "importInProgress";
    public static readonly IMPORT_FINISHED = "importFinished";
    public static readonly IMPORT_ABORTED = "importAborted";

    public static getLabel(code: string): string {
        return getLabel(`file_operationStatus_${code}`);
    }

    public constructor(value: string) {
        // value can be: null, or "code only" (i.e. 'attested'), or "code+message" (i.e. 'importFinished Import completed.')
        this.code = value?.split(' ', 1)[0];
        this.message = value?.substring(this.code.length + 1);
        if (!!this.code && !this.message)
            this.message = FileOperationStatus.getLabel(this.code);
    }

    public code: string;
    public message: string;

    public isAttestable(): boolean {
        return [
            FileOperationStatus.NOT_ATTESTED,
            FileOperationStatus.ATTESTED_IN_USE,
            FileOperationStatus.ATTESTED_REJECTED
        ].includes(this.code);
    }

    public hasImportDetails(): boolean {
        return this.code?.startsWith("import");
    }
}