import React, { useState } from "react";
import { Pagination } from '@mui/material';
import { SearchDefaults } from "../../../constants/SearchDefaults";

interface Props {
    totalResults: number;
    page: number;
    onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

export const PagingFooter : React.FC<Props> = ({totalResults, page, onPageChange}) => {
    const [isProcessingEvent, setIsProcessingEvent] = useState(false);

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        setIsProcessingEvent(true);
        try {
            await onPageChange(event, page);
        } finally {
            setIsProcessingEvent(false);
        }
    }

    return(
        <Pagination 
            className="paging"
            count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0}
            page={page}
            onChange={(event: React.ChangeEvent<unknown>, page: number) => handlePageChange(event, page)}
            disabled={isProcessingEvent}
        />
    );
}