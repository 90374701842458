import React from 'react';
import { Link } from 'react-router-dom';
import { getLabel } from '../label/Label.library';

interface Props {
    to: string;
    parentHeading: string;
    children: React.ReactNode;
}

export const PageHeading: React.FC<Props> = ({to, parentHeading, children }) => {
    return <span className='page-heading'><Link className='link' to={to}>{parentHeading}</Link>{getLabel('em_dash')}{children}</span>
}