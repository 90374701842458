import { displayApiError, tryParseJson } from '../error/error';
import { ClearUserMessageAction, SetUserMessageWaitingAction, SetUserMessageErrorAction } from '../../actions/userMessageAction';
import { SetStartInactivityTimerAction } from '../../actions/authAction';

function createFetchOptions(method, isJson = false) {
  return () => {
    const headers = new Headers();
    if (isJson)
      headers.append('Content-Type', 'application/json');
    headers.append('X-Antiforgery', '1');
    return {
      headers,
      method,
      cache: 'no-cache',
    };
  };
}

function createNetworkCall(options) {
  return async (url, dispatcher, body, showMessage = true, showWaiting = true) => {
    const newOptions = options();
    if (body) {
      newOptions.body = body;
    }
    try {
      if (showMessage) {
        if (showWaiting) {
          dispatcher(SetUserMessageWaitingAction("working_text"));
        } else {
          dispatcher(ClearUserMessageAction());
        }
      }
      return await fetch(new Request(url, newOptions));
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function createCallWithErrorHandling(options) {
  const networkCall = createNetworkCall(options);
  return async (url, dispatcher, body, showMessage = true, showWaiting = true) => {
    const response = await networkCall(url, dispatcher, body, showMessage, showWaiting);
    dispatcher(SetStartInactivityTimerAction(true));
    if (!response.ok) {
      await displayApiError(response, dispatcher);
      return Promise.reject(response);
    }
    return response;
  };
}

function createCallWithParsedJsonResponse(requestOptions) {
  const networkCall = createCallWithErrorHandling(requestOptions);
  return async (url, dispatcher, body, showMessage = true, showWaiting = true) => {
    if (!!body && !(body instanceof FormData)) {
      body = JSON.stringify(body);
    }
    const response = await networkCall(url, dispatcher, body, showMessage, showWaiting);
    const json = await tryParseJson(response);  // `undefined` if empty or contains malformed JSON
    return {
      body: json ?? null,  //TODO: we should distinguish between invalid response and actual "null" in response
      response
    };
  };
}

function createCallWithSavedFileResponse(requestOptions) {
  const networkCall = createCallWithErrorHandling(requestOptions);
  return async (url, dispatcher, filename, body, showMessage = true, showWaiting = true) => {
    body = JSON.stringify(body);
    const response = await networkCall(url, dispatcher, body, showMessage, showWaiting);
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
      .catch((error) => {
        dispatcher(SetUserMessageErrorAction("file_download_failed_text"));
      });
  }
}

const createGetOptions = createFetchOptions('GET');
const createPostJsonOptions = createFetchOptions('POST', true);
const createPostFileOptions = createFetchOptions('POST');

//TODO: better, less confusing names for all these exported methods
export const makePlainPostRequestNoErrorHandling = createNetworkCall(createPostJsonOptions);

export const makeJSONPostRequest = createCallWithParsedJsonResponse(createPostJsonOptions);
export const makeJSONGetRequest = createCallWithParsedJsonResponse(createGetOptions);
export const makeJSONPostFileRequest = createCallWithParsedJsonResponse(createPostFileOptions);
export const makePostBlobRequest = createCallWithSavedFileResponse(createPostJsonOptions)
