import React, { useEffect, useState } from 'react';
import { AuthenticatedLayout } from '../../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, CardContent, Card, List, Paper, ListItem, Button } from '@mui/material';
import './ImportRoster.css';
import { getLabel } from '../../../../components/common/label/Label.library';
import { Formik } from 'formik';
import { createRoute, ApplicationRoutes } from '../../../../constants/ApplicationRoutes';
import { makeJSONPostRequest, makePostBlobRequest } from '../../../../services/ajax/ajax';
import { useDispatch } from 'react-redux';
import { ApiUrls } from '../../../../constants/ApiUrls';
import { SetUserMessageSuccessAction, ClearUserMessageAction } from '../../../../actions/userMessageAction';
import { RosterImportResult } from '../../../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../../../constants/SearchDefaults';
import { Permissions } from '../../../../constants/Permissions';
import { useActingFor } from '../../../../hooks/useActingFor';
import { InfoTitles } from '../../../../constants/InfoTitles';
import { PageHeading } from '../../../../components/common/page.heading/PageHeading';
import { hasPermissions } from '../../../../services/auth/auth';
import { PagingFooter } from '../../../../components/common/paging/PagingFooter';
import { APIButton } from '../../../../components/common/button/APIButton';
import { OutputFiles } from '../../../../constants/OutputFiles';
import { SelectInput } from '../../../../components/common/select.input/SelectInput';
import { useACNDropdownValues } from '../../../../hooks/useACNDropdownValues';
import { useNavigate } from 'react-router-dom';
import { formatLongDateTime } from '../../../../services/format/date';

export const ImportRoster: React.FC<any> = (props) => {
  const [importResults, setImportResults] = useState<RosterImportResult[]>([]);
  const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
  const [page, setPage] = useState(0);
  const actingFor = useActingFor();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const canViewAllAcnImportResults = hasPermissions(Permissions.CAN_VIEW_ALL_ACNS);
  const acnValues = useACNDropdownValues();
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);

  const selectImportResult = (event: React.ChangeEvent<any>, importResult: RosterImportResult) => {
    navigate(createRoute(ApplicationRoutes.VIEW_IMPORT_ROSTER_RESULT, { id: importResult.id }));
  };

  const getImportResults = async (newPage: number, showWorkingMessage: boolean = true, filterAcnId: number | null = null) => {
    setImportResults([]);
    setTotalResults(-1);
    const data = {
      Skip: (newPage - 1) * SearchDefaults.TAKE,
      Take: SearchDefaults.TAKE,
      OrderBy: "ImportStartedAt",
      OrderByDir: "DESC",
      AcnId: canViewAllAcnImportResults ? filterAcnId : actingFor.id
    };

    const response = await makeJSONPostRequest(ApiUrls.GET_IMPORT_ROSTER_RESULTS, dispatch, data, showWorkingMessage);
    setImportResults(response.body.result);
    setTotalResults(response.body.totalCount);
    setPage(newPage);
    return response.body.result.length;
  };

  const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
    await getImportResults(page, true);
    dispatch(ClearUserMessageAction());
  };

  const getFilterAcnOptions = (): any => {
    return acnValues.map((e: any) =>
        <option key={e.id} value={e.id}>
          {e.acnName}
        </option>
    );
  };

  const filterImportResults  = async (values: any, actions: any) => {
    await getImportResults(1, true, parseInt(values.FilterAcnId));
    dispatch(ClearUserMessageAction());
    actions.setSubmitting(false);
  };

  const clearSearchFilters = async () => {
    await getImportResults(1, true);
    dispatch(ClearUserMessageAction());
  };

  const getInitialImportResults = async () => {
    const resultLength = await getImportResults(1, false);
    setDisableDownloadButton(resultLength === 0);
  };

  const downloadRosterImportSummary = async () => {
    const data = {
      ...(!canViewAllAcnImportResults && {ActingForId: actingFor.id})
    }
    await makePostBlobRequest(ApiUrls.DOWNLOAD_IMPORT_ROSTER_SUMMARY, dispatch, OutputFiles.ROSTER_IMPORT_SUMMARY, data, true, true);
    dispatch(SetUserMessageSuccessAction('roster_import_summary_download_prepared'));
  };

  useEffect(() => {
    document.title =`${getLabel('app_name')} ${getLabel('title_import_roster')}`
    if (canViewAllAcnImportResults) {
      getInitialImportResults();
    }
  }, []);

  useEffect(() => {
    if (actingFor.id != -1 && !canViewAllAcnImportResults) {
      getInitialImportResults();
    }
  }, [actingFor.id]);

  return (
    <AuthenticatedLayout {...props} infoTitle={InfoTitles.IMPORT_ROSTER}>
      <Container maxWidth={false} className="import-roster">
        <h2><PageHeading to={createRoute(ApplicationRoutes.PROVIDERS)} parentHeading={getLabel('providers_page_heading')}>{getLabel('roster_import_page_heading')}</PageHeading></h2>
        <div className="download-button">
          <APIButton className="green-button" type="button" variant="contained" color="primary" disabled={disableDownloadButton} onClick={downloadRosterImportSummary}>{getLabel('roster_import_download_import_summary_label')}</APIButton>
        </div>
        {canViewAllAcnImportResults && 
          (<Card>
            <CardContent>
              <Formik enableReinitialize={true}
                initialValues={{ FilterAcnId: "" }}
                onSubmit={(values, actions) => {
                  filterImportResults(values, actions);
                }}>
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <div className="roster-search-filter-fields">
                      <SelectInput name="FilterAcnId" label="acting_for_acn_label" values={getFilterAcnOptions()} blankOption={true} />
                      <div className="search-filter-buttons">
                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>
                          {getLabel('clear_search_filter_button_label')}
                        </Button>
                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">
                          {getLabel('perform_search_button_label')}
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>)}
        <div className="import-result-list">
          <List id="resultList">{importResults.map((result) =>
            <Paper key={result.id}> <ListItem className="row" button onClick={(e) => selectImportResult(e, result)} disabled={!result.isComplete}>
              <div className="import-result-row">
                <div className="name">{!!result.importedByACN ? result.importedByACN.acnName : ""}</div>
                <div className="import-result-row-data roster-import-result-row-data">
                  <span>
                    <div className="colHeader">{getLabel("import_results_list_imported_by_header")}</div>
                    <div>{result.importedBy.name}</div>
                  </span>
                  <span>
                    <div className="colHeader">{getLabel("import_results_list_filename_header")}</div>
                    <div>{result.importFilename}</div>
                  </span>
                  <span>
                    <div className="colHeader">{getLabel("import_results_list_program_header")}</div>
                    <div>{result.importProgram?.name}</div>
                  </span>
                  <span>
                    <div className="colHeader">{getLabel("roster_import_results_list_date_header")}</div>
                    <div>{formatLongDateTime(result.importStartedAt)}</div>
                  </span>
                  {
                    result.isComplete
                      ? <>
                        <span>
                          <div className="colHeader center">{getLabel("roster_import_results_list_error_lines_header")}</div>
                          <div className="center">{result.errorCount}</div>
                        </span>
                        <span>
                          <div className="colHeader center">{getLabel("roster_import_results_list_affiliated_header")}</div>
                          <div className="center">{result.newCount}</div>
                        </span>
                        <span>
                          <div className="colHeader center">{getLabel("roster_import_results_list_release_requested_header")}</div>
                          <div className="center">{result.releaseRequestedCount}</div>
                        </span>
                        <span>
                          <div className="colHeader center">{getLabel("roster_import_results_list_unchanged_header")}</div>
                          <div className="center">{result.unchangedCount}</div>
                        </span>
                        <span>
                          <div className="colHeader center">{getLabel('roster_import_results_list_removed_header')}</div>
                          <div className="center">{result.deletedCount}</div>
                        </span>
                      </>
                      : <span className="import-incomplete">
                        <div>{getLabel("import_results_list_import_incomplete")}</div>
                      </span>
                  }
                </div>
              </div>
            </ListItem></Paper>)}
          </List>
          {totalResults === 0 && <p className="paging">{getLabel("roster_import_result_none")}</p>}
          {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
        </div>
      </Container>
    </AuthenticatedLayout>
  );
};
