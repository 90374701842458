export interface SubgroupSearchValues { 
  id: string,
  name: string,
  city: string, 
  state: string, 
  includeInactive: boolean
}

export const SET_SUBGROUP_SEARCH = "SET_SUBGROUP_SEARCH";
export const CLEAR_SUBGROUP_SEARCH = "CLEAR_SUBGROUP_SEARCH";

interface SetSubgroupSearch {
  type: typeof SET_SUBGROUP_SEARCH;
  subgroupSearch: SubgroupSearchValues;
}

interface ClearSubgroupSearch {
  type: typeof CLEAR_SUBGROUP_SEARCH;
}

export type SubgroupSearchAction = SetSubgroupSearch | ClearSubgroupSearch;

export const SetSubgroupSearchAction = (values: SubgroupSearchValues): SetSubgroupSearch => ({
  type: SET_SUBGROUP_SEARCH,
  subgroupSearch: { ...values }
});

export const ClearSubgroupSearchAction = (): ClearSubgroupSearch => ({
  type: CLEAR_SUBGROUP_SEARCH
});