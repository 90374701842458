import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GroupInfo } from '../../components/groups/GroupAttributes';
import './Groups.css';
import { getLabel } from '../../components/common/label/Label.library';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { TabValue } from '../../constants/TabValue';
import { Group } from '../../interfaces/ApiInterfaces';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { ViewGroupInfo } from '../../components/groups/group.info.view/ViewGroupAttributes';
import { useActingForACN } from '../../hooks/useActingFor';
import { GroupProviders } from '../../components/groups/GroupProviders';
import { Subgroups } from './Subgroups';
import { InfoTitles } from '../../constants/InfoTitles';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';

export const EditGroup: React.FC<any> = (props) => {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const groupId = +params.id!;
    const acnActingFor = useActingForACN();
    const [group, updateGroup] = useState<Group>();
    const canManageGroups = hasPermissions(Permissions.CAN_CREATE_GROUP) || hasPermissions(Permissions.CAN_UPDATE_GROUP);

    useEffect(() => {
        document.title = groupId === -1 
            ? `${getLabel('app_name')} ${getLabel('title_add_group')}`
            : `${getLabel('app_name')} ${getLabel('title_edit_group')}`
    }, [])

    useEffect(() => {
        const getGroup = async () => {
            if  (groupId !== -1) {
                var groupResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_GROUP, { groupId }), dispatch, null, false, false);
                var groupResponseBody = groupResponse.body
                updateGroup(groupResponseBody);
            }
            else {
                updateGroup({ id: -1, name: '', email: '', active: true, accountableCareNetworkId: acnActingFor.id, parentId: -1, parentGroup: undefined, address1: '', address2: '', city: '', state: '', zipCode: '', telephone: '', extension: '', externalId: '', description: ''});
            }
        };

        if (acnActingFor.id !== -1) {
            getGroup();
        }
        else {
            navigate(createRoute(ApplicationRoutes.GROUPS));
        }
        
    }, [dispatch, groupId, acnActingFor.id]);

    useEffect(() => {
        if (params.tab === TabValue.GROUP_ATTRIBUTES) {
            setTabValue(0);
        }
        else if (params.tab === TabValue.GROUP_PROVIDERS && groupId !== -1) {
            setTabValue(1);
        }
        else if (params.tab === TabValue.GROUP_SUBGROUP && groupId !== -1) {
            setTabValue(2);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_ATTRIBUTES }));
            setTabValue(0);
        }
    },[groupId, acnActingFor.id, params.tab]);

    function getAddEditViewLabel() {
        var label;
        if (groupId === -1) {
            label = getLabel('group_add_page_heading');
        } else {
            label = getLabel(canManageGroups
                ? 'group_edit_page_heading'
                : 'group_view_page_heading', { name: group!.name });
        }
        return label;
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_ATTRIBUTES }))
        }
        else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_PROVIDERS }))
        }
        else if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_SUBGROUP }))
        }
        setTabValue(value);
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_GROUP;
        } else if (tabValue === 1) {
            return InfoTitles.GROUP_PROVIDERS;
        } else {
            return InfoTitles.LIST_SUBGROUPS;
        }
    }

    const returnToGroups = () => {
        navigate(createRoute(ApplicationRoutes.GROUPS));
    }
    
    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()} actingForOnChange={returnToGroups}>
            <Container maxWidth={false} className="group-attributes-container">
                {!!group &&
                    <>
                    <h2><PageHeading to={createRoute(ApplicationRoutes.GROUPS)} parentHeading={getLabel('group_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="edit-group-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary"
                                    textColor="primary">
                                    <Tab value={0} label={getLabel('tab_label_attributes')} />
                                    <Tab value={1} label={getLabel('tab_label_providers')} disabled={group.id === -1} />
                                    <Tab value={2} label={getLabel('tab_label_subgroups')} disabled={group.id === -1} />
                                </Tabs>
                            </AppBar>
                            {tabValue === 0 && (canManageGroups ? (<GroupInfo group={group} />) : (<ViewGroupInfo group={group} />))}
                            {tabValue === 1 && <GroupProviders group={group}/>}
                            {tabValue === 2 && <Subgroups group={group}/>}
                        </Card>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};