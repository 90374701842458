export interface GroupProviderSearch {
    firstName: string;
    lastName: string;
    groupNPI: number | null;
}

export const SET_GROUP_PROVIDER_SEARCH = 'SET_GROUP_PROVIDER_SEARCH';
export const CLEAR_GROUP_PROVIDER_SEARCH = 'CLEAR_GROUP_PROVIDER_SEARCH';

interface SetGroupProviderSearch {
    type: typeof SET_GROUP_PROVIDER_SEARCH;
    groupProviderSearch: GroupProviderSearch;
}

interface ClearGroupProviderSearch {
    type: typeof CLEAR_GROUP_PROVIDER_SEARCH;
}

export type GroupProviderSearchAction = SetGroupProviderSearch | ClearGroupProviderSearch;

export const SetGroupProviderSearchAction = (firstName: string, lastName: string, groupNPI: number | null): SetGroupProviderSearch => ({
    type: SET_GROUP_PROVIDER_SEARCH,
    groupProviderSearch: {
        firstName: firstName,
        lastName: lastName,
        groupNPI: groupNPI
    },
});

export const ClearGroupProviderSearchAction = (): ClearGroupProviderSearch => ({
    type: CLEAR_GROUP_PROVIDER_SEARCH
});