import { Permissions } from "../../constants/Permissions";
import { RoleIds } from "../../constants/RoleIds";
import { UserRole } from "../../interfaces/ApiInterfaces";
import { hasPermissions } from "../../services/auth/auth";

const acnRoleIds = [RoleIds.ACN_NETWORK_MAINTENANCE, RoleIds.ACN_NETWORK_MAINTENANCE_READ_ONLY,
    RoleIds.ACN_PRA_ADMIN, RoleIds.ACN_PRA_ADMIN_READ_ONLY,
    RoleIds.ACN_ROSTER_IMPORT, RoleIds.ACN_ROSTER_IMPORT_READ_ONLY,
    RoleIds.ACN_FILES_EXCHANGE, RoleIds.ACN_FILES_EXCHANGE_READ_ONLY
]
const clientAndAcnRoleIds = [RoleIds.CLIENT, RoleIds.CLIENT_READ_ONLY, ...acnRoleIds]

export function getFilteredRoles(roles: UserRole[]): UserRole[] {
    if (hasPermissions(Permissions.CAN_VIEW_ALL_USERS)) {
        return roles;
    } else if (hasPermissions(Permissions.CAN_VIEW_ONLY_CLIENT_AND_ACN_USERS)) {
        return roles.filter(role => clientAndAcnRoleIds.includes(role.id));
    } else {
        return roles.filter(role => acnRoleIds.includes(role.id));
    }
}