import React from 'react';
import { Card, CardContent } from '@mui/material';
import { getLabel } from '../../common/label/Label.library';
import './AccountableCareNetworkAttributes.css';
import { ACN } from '../../../interfaces/ApiInterfaces';
import { formatPhone } from '../../../services/format/format';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';

interface Props {
    acn: ACN
}

export const ViewAccountableCareNetworkInfo: React.FC<Props> = ({acn}) => {
    const firstContactExists = !!acn.contactName;
    const secondContactExists = !!acn.secondContactName;
    const canSeeStorageContainerName = hasPermissions(Permissions.CAN_SEE_ACN_CONTAINER_NAME);

    return (
        <Card>
            <CardContent>
                <div className="view-only viewACN grid">
                    <div>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_name_label')}</div><div className='acn-data'>{acn.acnName}</div></span>
                        {firstContactExists && 
                        <>
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_contact_name_label')}</div><div className='acn-data'>{acn.contactName}</div></span>
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_email_label')}</div><div className='acn-data'>{acn.email}</div></span>
                            <span className="view-half-width grid">
                                <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_telephone_label')}</div><div className='acn-data'>{formatPhone(acn.telephone)}</div></span>
                                <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_extension_label')}</div><div className='acn-data'>{acn.extension}</div></span>
                            </span>
                        </>}
                    </div>
                    {firstContactExists &&
                    <div>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_address1_label')}</div><div className='acn-data'>{acn.address1}</div></span>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_address2_label')}</div><div className='acn-data'>{acn.address2}</div></span>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_city_label')}</div><div className='acn-data'>{acn.city}</div></span>
                        <span className="view-half-width grid">
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_state_label')}</div><div className='acn-data'>{acn.state}</div></span>
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_zip_label')}</div><div className='acn-data'>{acn.zipCode}</div></span>
                        </span>
                    </div>}
                    <div>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_es_id_label')}</div><div className='acn-data'>{acn.externalId}</div></span>
                        {canSeeStorageContainerName &&
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_storage_container_label')}</div><div className='acn-data'>{acn.storageContainerName}</div></span>}
                    </div>
                </div>
                {secondContactExists &&
                <div className="view-only viewACN grid">
                    <div>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_contact_name_label')}</div><div className='acn-data'>{acn.secondContactName}</div></span>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_email_label')}</div><div className='acn-data'>{acn.secondContactEmail}</div></span>
                        <span className="view-half-width grid">
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_telephone_label')}</div><div className='acn-data'>{formatPhone(acn.secondContactTelephone)}</div></span>
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_extension_label')}</div><div className='acn-data'>{acn.secondContactExtension}</div></span>
                        </span>
                    </div>
                    <div>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_address1_label')}</div><div className='acn-data'>{acn.secondContactAddress1}</div></span>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_address2_label')}</div><div className='acn-data'>{acn.secondContactAddress2}</div></span>
                        <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_city_label')}</div><div className='acn-data'>{acn.secondContactCity}</div></span>
                        <span className="view-half-width grid">
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_state_label')}</div><div className='acn-data'>{acn.secondContactState}</div></span>
                            <span className="acnLabel"><div className="colHeader">{getLabel('acn_attributes_second_zip_label')}</div><div className='acn-data'>{acn.secondContactZipCode}</div></span>
                        </span>
                    </div>
                    <div></div>
                </div>}
            </CardContent>
        </Card>
    );
};
