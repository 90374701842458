import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Program, ProgramRule } from '../../interfaces/ApiInterfaces';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { getLabel } from '../common/label/Label.library';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { useNavigate } from 'react-router-dom';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import './ProgramRules.css';
import { ProgramRuleTypes } from '../../constants/ProgramRuleTypes';

interface Props {
    program: Program
}

export const ProgramRules: React.FC<Props> = ({ program }) => {
    const [programs, setPrograms] = useState<Program[]>([]);
    const [programRulesDict, setProgramRulesDict] = useState(new Map());
    const [isDirty, setIsDirty] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const canEdit = hasPermissions(Permissions.CAN_UPDATE_PROGRAM) && program.active;

    async function getPrograms() {
        const response = await makeJSONPostRequest(ApiUrls.GET_PROGRAMS, dispatch, {}, false, false);
        var otherPrograms = response.body.result.filter((p: { id: number; }) => p.id != program.id);
        setPrograms(otherPrograms);
    };

    async function getProgramRules() {
        const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_PROGRAM_RULES, { programId: program.id }), dispatch, null, false, false);

        var programRules: ProgramRule[] = response.body;
        var rulesDict = new Map();

        for (var rule of programRules) {
            var key = rule.value;
            var ruleTypeProgramIds = rulesDict.get(key);

            if (!ruleTypeProgramIds) {
                ruleTypeProgramIds = new Array();
                rulesDict.set(key, ruleTypeProgramIds);
            }
            var ruleAssociatedProgramId = rule.programId === program.id ? rule.otherProgramId : rule.programId;
            ruleTypeProgramIds.push(ruleAssociatedProgramId);
        }
        setProgramRulesDict(rulesDict);
    };

    useEffect(() => {
        getPrograms();
        getProgramRules();
    }, [program.id])

    const handleChange = (event: any, programId: number, ruleType: string) => {
        setIsDirty(true);
        var rules = new Map(programRulesDict);
        var values = rules.get(ruleType);

        if (!values) {
            values = new Array();
            rules.set(ruleType, values);
        }

        if (event.target.checked) {
            values.push(programId);
        }
        else {
            values = values.filter((id: number) => id !== programId);
            rules.set(ruleType, values);
        }
        setProgramRulesDict(rules);
    };

    async function onSubmit() {
        try {
            var data = new Array();
            programRulesDict.forEach((value, key) => {
                for (const programId of value) {
                    var rule = {
                        value: key,
                        programId: programId,
                        otherProgramId: program.id
                    }
                    data.push(rule)
                }
            });

            await makeJSONPostRequest(createUrl(ApiUrls.SET_PROGRAM_RULES, { programId: program.id }), dispatch, data);
            dispatch(SetUserMessageSuccessAction('program_rules_success_update_text'));
            navigate(createRoute(ApplicationRoutes.PROGRAMS));
        } catch {
            dispatch(SetUserMessageErrorAction('program_rules_failed_update_text'));
        }
    }

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.PROGRAMS));
    }

    return (
        <Card className='program-rules'>
            <CardContent>
                <Table className='table-container'>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Program</strong></TableCell>
                            <TableCell><strong>{ProgramRuleTypes.MUTUALLY_EXCLUSIVE.name}</strong></TableCell>
                            {/*
                            <TableCell><strong>{ProgramRuleTypes.EXCLUSIVE_PER_LOCATION.name}</strong></TableCell>
                            */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {programs.map((pr) => (
                            <TableRow key={pr.name}>
                                <TableCell>{pr.name}</TableCell>
                                <TableCell>
                                    <Checkbox
                                        color="primary"
                                        disabled={!canEdit}
                                        checked={programRulesDict.get(ProgramRuleTypes.MUTUALLY_EXCLUSIVE.value)?.includes(pr.id) || false}
                                        onChange={(event) => handleChange(event, pr.id, ProgramRuleTypes.MUTUALLY_EXCLUSIVE.value)}
                                    />
                                </TableCell>
                                {/*
                                <TableCell>
                                    <Checkbox
                                        color="primary"
                                        disabled={!canEdit}
                                        checked={programRulesDict.get(ProgramRuleTypes.EXCLUSIVE_PER_LOCATION.value)?.includes(pr.id) || false}
                                        onChange={(event) => handleChange(event, pr.id, ProgramRuleTypes.EXCLUSIVE_PER_LOCATION.value)}
                                    />
                                </TableCell>
                                */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {(hasPermissions(Permissions.CAN_UPDATE_PROGRAM) && program.active) &&
                    <div className="edit-buttons">
                        <Button className="button" type="button" variant="contained" color="primary" onClick={() => cancelEdit()}>
                            {getLabel('program_rules_cancel_button_label')}
                        </Button>
                        <Button className="edit-button button" type="submit" variant="contained" color="primary" disabled={!isDirty} onClick={() => onSubmit()}>
                            {getLabel('program_rules_submit_button_label')}
                        </Button>
                    </div>}
            </CardContent>
        </Card>
    )
}