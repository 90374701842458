import React from 'react';
import { getLabel } from '../label/Label.library';
import { TextField, FormHelperText } from '@mui/material';
import './TextInput.css';
import { useField } from 'formik';
import { TextFieldMaxLengths } from '../../../constants/TextFieldMaxLengths';
import { formatDigits, formatPhone } from '../../../services/format/format';

interface Props {
    name: string;
    label: string;
    type?: string;
    fullwidth?: boolean;
    multiline?: boolean | number;
    shrink?: boolean;
    onBlur?: any;
    disabled?: boolean;
    maxLength?: number | null;
}

export const TextInput: React.FC<Props> = ({ name, label, type, fullwidth, multiline, shrink, onBlur, disabled, maxLength = TextFieldMaxLengths.DEFAULT }) => {
    const [field, meta] = useField(name);

    const handleKeyDown = (e: { key: string; preventDefault: () => void; }) => {
        if (!!type && type === 'number' && ["-", ".", "e", "E", "+", "-"].includes(e.key)) {
                e.preventDefault();
        }
    };

    const formatText = (e: any) => {
        if (!!type && type === 'phone') {
            return formatPhone(e);
        }
        if (!!type && type === 'digit') {
            return formatDigits(e);
        }
        return e;
    }

    return (
        <div className="text-input">
            <TextField
                className={
                    fullwidth != null && fullwidth ? 'text-input-field' : 'text-input-field-padded'
                }
                InputLabelProps={{ shrink: shrink }}
                fullWidth={fullwidth != null ? fullwidth : true}
                multiline={multiline != null ? !!multiline : false}
                minRows={typeof multiline == 'number' ? multiline : undefined}
                variant="outlined"
                label={getLabel(label)}
                id={field.name}
                name={field.name}
                error={meta.touched && Boolean(meta.error)}
                value={formatText(field.value)}
                type={type ? type : 'text'}
                onChange={(event) => {
                    field.onChange(event);
                    field.onBlur(event);
                }}
                onBlur={(event) => {
                    onBlur?.(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                inputProps={maxLength ? { maxLength: maxLength } : undefined}
            />
            {meta.touched && Boolean(meta.error) && (
                <FormHelperText className="validateText">{meta.error}</FormHelperText>
            )}
        </div>
    );
};
