import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ClearNextCycleAction } from "../../actions/nextCycleAction";
import { SetUserMessageSuccessAction } from "../../actions/userMessageAction";
import { ApiUrls } from "../../constants/ApiUrls";
import { getActingForDetails, useActingFor } from "../../hooks/useActingFor";
import { ACN, Product, Program } from "../../interfaces/ApiInterfaces";
import { makeJSONPostRequest } from "../../services/ajax/ajax";
import { APIButton } from "../common/button/APIButton";
import { DialogModal } from "../common/dialog.modal/DialogModal";
import { getLabel } from "../common/label/Label.library";

interface Props {
    acn: ACN;
    program: Program;
    product: Product;
    isAssociated: boolean | null;
    onChange?: Function;
    disabled?: boolean;
    setIsAssociationActionInProgress: Function;
}

export const ACNProgramAssociationButton: React.FC<Props> = ({ acn, program, product, isAssociated, onChange, disabled, setIsAssociationActionInProgress }) => {
    const dispatch = useDispatch();
    const acnActingFor = useActingFor();
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);

    const handleJoinProgram = async () => {
        setIsAssociationActionInProgress(true);
        setShowRemoveDialog(false);
        try {
            const data = {
                programId: program.id,
                productId: product.id,
                ACNId: acn.id,
                ...getActingForDetails(acnActingFor)
            };
            await makeJSONPostRequest(ApiUrls.ADD_PROGRAM_AND_PRODUCT_TO_ACN, dispatch, data);
            if (onChange) {
                await onChange();
            }
            dispatch(SetUserMessageSuccessAction(getLabel("product_success_included_text")));
            dispatch(ClearNextCycleAction());
        }
        finally {
            setIsAssociationActionInProgress(false);
        }
    };

    const handleLeaveProgram = () => {
        setShowRemoveDialog(true);
    };
    
    const completeRemoveProgram = async () => {
        setIsAssociationActionInProgress(true);
        setShowRemoveDialog(false);
        try {
            const data = {
                programId: program.id,
                productId: product.id,
                ACNId: acn.id,
                ...getActingForDetails(acnActingFor)
            };
            await makeJSONPostRequest(ApiUrls.REMOVE_PROGRAM_AND_PRODUCT_FROM_ACN, dispatch, data);
            if (onChange) {
                await onChange();
            }
            dispatch(SetUserMessageSuccessAction(getLabel("product_success_excluded_text")));
            dispatch(ClearNextCycleAction());
        }
        finally {
            setIsAssociationActionInProgress(false);
        }
    };

    const cancelRemoveProgram = () => {
        setShowRemoveDialog(false);
    };

    return (
        isAssociated !== null
        ? <div className="acn-program-association-button-container">
            <DialogModal id="removeProgramDialog" title={getLabel("acn_program_remove_dialog_title")} onClickLeft={cancelRemoveProgram} onClickRight={completeRemoveProgram}
                open={showRemoveDialog} labelLeft={getLabel("acn_program_remove_dialog_cancel")} labelRight={getLabel("acn_program_remove_dialog_remove")} 
            />
            <div className="association-button">
                {isAssociated
                    ? <APIButton type="button" variant="contained" color="primary" disabled={disabled} className="red-button" onClick={handleLeaveProgram}>{getLabel("acn_program_remove_button")}</APIButton>
                    : <APIButton type="button" variant="contained" color="primary" disabled={disabled} className="green-button" onClick={handleJoinProgram}>{getLabel("acn_program_add_button")}</APIButton>
                }
            </div>
        </div>
        : <></>
    );
};