export interface Notifications {
    notifications: any
}

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

interface SetNotifications {
    type: typeof SET_NOTIFICATIONS;
    notifications: Notifications;
}

export type NotificationsAction = SetNotifications;

export const SetNotificationsAction = (notifications: any): SetNotifications => ({
    type: SET_NOTIFICATIONS,
    notifications
});