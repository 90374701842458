import { Card, CardContent, List, Paper, ListItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createRoute, ApplicationRoutes } from "../../constants/ApplicationRoutes";
import { Group, ListResultProvider } from "../../interfaces/ApiInterfaces";
import { hasPermissions } from "../../services/auth/auth";
import { APIButton } from "../common/button/APIButton";
import { getLabel } from "../common/label/Label.library";
import { PagingFooter } from "../common/paging/PagingFooter";
import { Permissions } from '../../constants/Permissions';
import { SearchDefaults } from "../../constants/SearchDefaults";
import { ApiUrls } from "../../constants/ApiUrls";
import { makeJSONPostRequest } from "../../services/ajax/ajax";
import { ClearUserMessageAction, SetUserMessageErrorAction, SetUserMessageSuccessAction } from "../../actions/userMessageAction";
import { TabValue } from "../../constants/TabValue";
import { getActingForDetails, useActingFor } from "../../hooks/useActingFor";
import './GroupProviders.css';
import { ClearGroupProviderSearchAction, GroupProviderSearch, SetGroupProviderSearchAction } from "../../actions/groupProviderSearchAction";
import { groupProviderSearchValues } from "../../reducers/rootReducer";
import { AppState } from "../../store/configureStore";
import { GroupProvidersSearch } from "../providers/provider.search/GroupProvidersSearch";

interface Props {
    group: Group;
}

export const GroupProviders: React.FC<Props> = ({group}) => {
    const providerSearchValues = useSelector<AppState, GroupProviderSearch>(groupProviderSearchValues);
    const [providers, setProviders] = useState<ListResultProvider[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acnActingFor = useActingFor();
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);

    const getProviders = async (values: any, newPage: number, showSucessMessage: boolean = true) => {
        const data = {
            GroupId: group.id,
            AccountableCareNetworkId: acnActingFor.id,
            FirstName: values.firstName ? values.firstName : null,
            LastName: values.lastName ? values.lastName : null,
            GroupNPI: values.groupNPI ? values.groupNPI : null,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_GROUP_PROVIDERS, dispatch, data, true, true);
        
        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_many', { totalCount: response.body.totalCount })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setProviders(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const selectProvider = (providerId: number) => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: providerId, tab: TabValue.PROVIDER_ATTRIBUTES }));
    };

    const removeProvider = async (event: React.ChangeEvent<unknown>, providerId: number) => {
        event.stopPropagation();
        try {
            const data = {
                GroupId: group.id,
                ProviderId: providerId,
                AccountableCareNetworkId: acnActingFor.id,
                ...getActingForDetails(acnActingFor)
            };
            const response = await makeJSONPostRequest(ApiUrls.GROUP_END_MEMBERSHIP, dispatch, data);
            await getProviders(providerSearchValues, page, false);
            if (response.response.ok) {
                dispatch(SetUserMessageSuccessAction('group_remove_provider_success_text'));
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('group_remove_provider_unauthorized_text'));
        }
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
        await getProviders(providerSearchValues, newPage, false);
    };

    const performSearch = async (values: any) => {
        dispatch(SetGroupProviderSearchAction(values.firstName, values.lastName, values.groupNPI));
        await getProviders(values, 1, true);
    };

    const clearSearchFilters = async () => {
        dispatch(ClearGroupProviderSearchAction());
        await getProviders({ firstName: '', lastName: '', groupNPI: null }, 1, true);
    };

    useEffect(() => {
        getProviders(providerSearchValues, 1);
    }, [group.id]);

    return (
        <Card>
            <CardContent>
                <GroupProvidersSearch performSearch={performSearch} clearSearch={clearSearchFilters} disabled={acnActingFor.id === -1 || !acnActingFor.id} />
                <div className="groupProviders">
                    <List id="resultList">
                        {providers.map((provider) => <Paper key={provider.id} elevation={2}> <ListItem className="row" button onClick={() => selectProvider(provider.id)}>
                            <div className="flexCenter provider-list-row">
                                <div>
                                    <div className="name">{provider.pcpFullName}</div>
                                    <div className="provider-row-data">
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_pcp_credentials_header")}</div>
                                            <div>{provider.pcpCredentials}</div>
                                        </span>
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_specialty_header")}</div>
                                            <div>{provider.specialty}</div>
                                        </span>
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_pcp_npi_header")}</div>
                                            <div>{provider.pcpnpi}</div>
                                        </span>
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_pp_npi_header")}</div>
                                            <div>{provider.groupNPI}</div>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <APIButton className="red-button" type="button" variant="contained" color="primary" disabled={!hasPermissions(Permissions.CAN_UPDATE_GROUP)} onClick={(event: any) => removeProvider(event, provider.id)}>{getLabel('group_provider_remove_label')}</APIButton>
                                </div>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("group_provider_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </CardContent>
        </Card>
    )
}
