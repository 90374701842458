import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper } from '@mui/material';
import { makeJSONPostRequest, makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { ClearReportSearchAction, SetReportSearchAction } from '../../actions/reportSearchAction';
import { reportSearchValues } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import './Reports.css';
import { Report } from '../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { SelectInput } from '../../components/common/select.input/SelectInput';
import { InfoTitles } from '../../constants/InfoTitles';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { useNavigate } from 'react-router-dom';

export const Reports: React.FC<any> = (props) => {
    const [reports, setReports] = useState<Report[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, { name: string, category: string, includeInactive: boolean }>(reportSearchValues);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [categories, setCategories] = useState("");

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_list_reports')}`
    }, [])

    useEffect(() => {
        getReports(searchValues, true, true);
    }, [])

    async function performSearch(values: any) {
        var name = values.Name ?? values.name;
        var category = values.Category ?? values.category;
        var includeInactive = values.IncludeInactive ?? values.includeInactive;
        dispatch(SetReportSearchAction(name, category, includeInactive));
        await getReports({name: name, category: category, includeInactive: includeInactive});
    }

    async function clearSearchFilters() {
        dispatch(ClearReportSearchAction());
        await getReports({name: '', category: '', includeInactive: false});
    }

    async function getReports(values: any, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) {
        const data = {
            Name: values.name,
            Category: values.category,
            IncludeInactive: values.includeInactive,
            Skip: 0,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        if (!data.Category) delete data.Category;
        const response = await makeJSONPostRequest(ApiUrls.GET_REPORTS, dispatch, data, showSucessMessage, showWorkingMessage);

        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        }
        setReports(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        setPage(1);
    }

    const selectReport = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, report: Report) => {
        navigate(createRoute(ApplicationRoutes.VIEW_REPORT, { id: report.id }))
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await updatePage(value);
    };

    async function updatePage(value: number) {
        setPage(value);
        setReports([]);
        setTotalResults(-1);
        const data: any = {
            Name: searchValues.name,
            Category: searchValues.category,
            IncludeInactive: searchValues.includeInactive,
            skip: skip + ((value - page) * take),
            take: take,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        if (!data.Category) delete data.Category;
        const response = await makeJSONPostRequest(ApiUrls.GET_REPORTS, dispatch, data);
        dispatch(ClearUserMessageAction());
        setReports(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
    };

    useEffect(() => {
        const getCategories = async () => {
            const Category =  searchValues.category;
            dispatch(SetReportSearchAction(searchValues.name, '', searchValues.includeInactive));
            var result = await makeJSONGetRequest(ApiUrls.GET_REPORT_CATEGORIES, dispatch, null, false, false);
            const body = result.body;
            setCategories(body.map((e: any) => {
                return <option key={e.name} value={e.name}>{e.name}</option>;
            }));
            dispatch(SetReportSearchAction(searchValues.name, Category, searchValues.includeInactive));
        }
        getCategories();
    }, []);

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_REPORTS}>
            <Container maxWidth={false} className="view-reports">
                <h2>{getLabel("report_page_heading")}</h2>
                <Card id = "reportSearchCard">
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ Name: searchValues.name, Category: searchValues.category, IncludeInactive: searchValues.includeInactive }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="search-filter-fields">
                                        <TextInput name="Name" label="report_filter_name" fullwidth={false} />
                                        <SelectInput name="Category" label="report_filter_category" values={categories} />
                                        <div className="search-filter-buttons">
                                            <CheckboxInput name="IncludeInactive" label="report_filter_include_inactive" />
                                            <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => {clearSearchFilters(); props.resetForm();}}>{getLabel('clear_search_filter_button_label')}</Button>
                                            <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('report_filter_search_button')}</Button>
                                        </div>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                <div>
                    <List id="resultList">
                        {reports.map((report: Report) => <Paper key={report.id} > <ListItem className="row truncate" button onClick={(event) => selectReport(event, report)}>
                            {report.active && <div className="name">{report.name}</div>}
                            {!report.active && <div className="name inactive-result">{getLabel('inactive_name', { name: report.name })}</div>}
                            <div className="truncate grid">
                                <span className="reportCol"><div className="colHeader">{getLabel("report_list_category_header")}</div><div>{(report.categories.map((category: { name: any; }) => category.name)).join(', ')}</div></span>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("report_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
