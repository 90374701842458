import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ProgramInfo } from '../../components/programs/ProgramAttributes';
import { ProgramCycles } from '../../components/programs/ProgramCycles';
import { ProgramRules } from '../../components/programs/ProgramRules';
import { ViewProgramInfo } from '../../components/programs/ViewProgramAttributes';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import './Programs.css';
import { getLabel } from '../../components/common/label/Label.library';
import { TabValue } from '../../constants/TabValue';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { Program } from '../../interfaces/ApiInterfaces';
import { InfoTitles } from '../../constants/InfoTitles';
import { ProgramProducts } from '../../components/programs/ProgramProducts';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';

export const EditProgram: React.FC<any> = (props) => {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const programId = +params.id!;
    const isAdd = programId === -1;
    const [program, setProgram] = useState<Program>();
    const canEdit = hasPermissions(Permissions.CAN_UPDATE_PROGRAM);

    useEffect(() => {
        document.title = isAdd ? `${getLabel('app_name')} ${getLabel('title_add_program')}` : `${getLabel('app_name')} ${getLabel('title_edit_program')}`
    }, [])

    useEffect(() => {
        if (!isAdd) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_PROGRAM, { programId }), dispatch, null, false, false).then((programResponse) => {
                setProgram(programResponse.body);
            });
        }
        else {
            setProgram({id: -1, active: true, externalId: '', name: '', description: '', products: []});
        }
    }, [programId]);

    useEffect(() => {
        if (params.tab === TabValue.PROGRAM_ATTRIBUTES) {
            setTabValue(0);
        }
        else if (params.tab === TabValue.PROGRAM_PRODUCTS && !isAdd) {
            setTabValue(1);
        }
        else if (params.tab === TabValue.PROGRAM_RULES && !isAdd) {
            setTabValue(2);
        }
        else if (params.tab === TabValue.PROGRAM_CYCLES && !isAdd) {
            setTabValue(3);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: programId, tab: TabValue.PROGRAM_ATTRIBUTES }))
            setTabValue(0);
        }
    }, [programId, params.tab]);

    function getAddEditViewLabel() {
        var label;
        if (isAdd) {
            label = getLabel('program_add_page_heading');
        } else {
            label = getLabel(canEdit
                ? 'program_edit_page_heading'
                : 'program_view_page_heading', { name: program!.name });
        }
        return label;
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_PROGRAM;
        } else if (tabValue == 1) {
            return InfoTitles.PROGRAM_PRODUCTS;
        } else if (tabValue == 2) {
            return InfoTitles.PROGRAM_RULES;
        } else {
            return InfoTitles.PROGRAM_CYCLES;
        }
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: programId, tab: TabValue.PROGRAM_ATTRIBUTES }))
        }
        else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: programId, tab: TabValue.PROGRAM_PRODUCTS }))
        }
        else if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: programId, tab: TabValue.PROGRAM_RULES }))
        }
        else if (value === 3) {
            navigate(createRoute(ApplicationRoutes.EDIT_PROGRAM, { id: programId, tab: TabValue.PROGRAM_CYCLES }))
        }
        setTabValue(value);
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="user-attributes-container">
                {!!program &&
                    <>
                        <h2><PageHeading to={createRoute(ApplicationRoutes.PROGRAMS)} parentHeading={getLabel('program_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="user-attributes-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} aria-label="simple tabs example" indicatorColor="primary"
                                    textColor="primary">
                                    <Tab label={getLabel('tab_label_attributes')} value={0} />
                                    <Tab label={getLabel('tab_label_product')} value={1} disabled={isAdd}/>
                                    <Tab label={getLabel('tab_label_rules')} value={2} disabled={isAdd}/>
                                    <Tab label={getLabel('tab_label_cycles')} value={3} disabled={isAdd}/>
                                </Tabs>
                            </AppBar>
                            {tabValue === 0 && (canEdit ? (<ProgramInfo program={program} />) : (<ViewProgramInfo program={program} />))}
                            {tabValue === 1 && !isAdd && <ProgramProducts program={program}/>}
                            {tabValue === 2 && !isAdd && <ProgramRules program={program}/>}
                            {tabValue === 3 && !isAdd && <ProgramCycles program={program}/>}
                        </Card>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};