export class TabValue {
    public static readonly CYCLE_ATTRIBUTES = "attributes";
    public static readonly CYCLE_PROGRAMS = "programs";
    public static readonly CYCLE_ATTESTATION = "attestation";
    public static readonly GROUP_ATTRIBUTES = "attributes";
    public static readonly GROUP_PROVIDERS = "providers";
    public static readonly GROUP_SUBGROUP = "subgroups";
    public static readonly SUBGROUP_ATTRIBUTES = "attributes";
    public static readonly SUBGROUP_PROVIDERS = "providers";
    public static readonly PROGRAM_ATTRIBUTES = "attributes";
    public static readonly PROGRAM_CYCLES = "cycles";
    public static readonly PROGRAM_PRODUCTS = "products";
    public static readonly PROGRAM_RULES = "rules";
    public static readonly ACN_ATTRIBUTES = "attributes";
    public static readonly ACN_PROVIDERS = "providers";
    public static readonly ACN_PROGRAMS = "programs";
    public static readonly ACN_PRODUCTS = "products";
    public static readonly PROVIDER_ATTRIBUTES = "attributes";
    public static readonly PROVIDER_AFFILIATIONS = "affiliations";
    public static readonly PROVIDER_DESIGNATIONS = "designations";
    public static readonly DESIGNATIONS_ATTRIBUTES = "attributes";
    public static readonly USER_ATTRIBUTES = "attributes";
    public static readonly USER_ACN = "accountable-care-networks";
    public static readonly USER_PREFERENCES = "preferences";
    public static readonly PRODUCT_ATTRIBUTES = "attributes";
    public static readonly PRODUCT_ACNS = "accountable-care-networks";
}
