import React, { useEffect, useState } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Card, CardContent, Button, List, Paper, ListItem } from '@mui/material';
import './Cycles.css';
import { Permissions } from '../../constants/Permissions';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { SetUserMessageSuccessAction, ClearUserMessageAction } from '../../actions/userMessageAction';
import { getLabel } from '../../components/common/label/Label.library';
import { Formik } from 'formik';
import { TextInput } from '../../components/common/text.input/TextInput';
import { ClearCycleSearchAction, CycleSearch, SetCycleSearchAction } from '../../actions/cycleSearchAction';
import { cycleSearchValues, successMessageValue } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { hasPermissions } from '../../services/auth/auth';
import { TabValue } from '../../constants/TabValue';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { Cycle } from '../../interfaces/ApiInterfaces';
import { InfoTitles } from '../../constants/InfoTitles';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { ClearSuccessMessageAction } from '../../actions/successMessageAction';
import { useNavigate } from 'react-router-dom';
import { formatLongDate } from '../../services/format/date';

export const Cycles: React.FC<any> = (props) => {
    const [cycles, setCycles] = useState([]);
    const searchValues = useSelector<AppState, CycleSearch>(cycleSearchValues);
    const [totalResults, setTotalResults] = useState(-1);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();
    const successMessage = useSelector<AppState, string>(successMessageValue);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_list_cycles')}`
    }, [])

    useEffect(() => {
        dispatch(ClearUserMessageAction());
        getCycles(searchValues, true, true);
    }, [])

    async function performSearch(values: any) {
        dispatch(SetCycleSearchAction(values.StartDate, values.EndDate));
        await getCycles({startDate: values.StartDate, endDate: values.EndDate}, true, true);
    }

    async function clearSearchFilters() {
        dispatch(ClearCycleSearchAction());
        await getCycles({startDate: '', endDate: ''}, true, true);
    }

    async function getCycles(values: any, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) {
        const data = {
            StartDate: values.startDate,
            EndDate: values.endDate,
            Take: SearchDefaults.TAKE,
            OrderBy: "SnapshotAt",
            OrderByDir: "DESC"
        };
        if (!data.StartDate) delete data.StartDate;
        if (!data.EndDate) delete data.EndDate;
        const response = await makeJSONPostRequest(ApiUrls.GET_CYCLES, dispatch, data, showSucessMessage, showWorkingMessage);

        if (showSucessMessage) {
            if (!!successMessage) {
                dispatch(SetUserMessageSuccessAction(successMessage));
            }
            else if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('cycle_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('cycle_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('cycle_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        }
        dispatch(ClearSuccessMessageAction());
        setCycles(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        setPage(1);
    }

    const selectCycle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        var cycle: any = cycles.find((user: any) => user.id == index);
        navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycle.id, tab: TabValue.CYCLE_ATTRIBUTES }));
    }

    const createNewCycle = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: -1, tab: TabValue.CYCLE_ATTRIBUTES }));
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await updatePage(value);
    };

    async function updatePage(value: number) {
        setPage(value);
        setCycles([]);
        setTotalResults(-1);
        const data:any = {
            StartDate: searchValues.startDate,
            EndDate: searchValues.endDate,
            skip: skip + ((value - page) * take),
            take: take,
            OrderBy: "SnapshotAt",
            OrderByDir: "DESC"
        };
        if (!data.StartDate) delete data.StartDate;
        if (!data.EndDate) delete data.EndDate;
        const response = await makeJSONPostRequest(ApiUrls.GET_CYCLES, dispatch, data);
        dispatch(ClearUserMessageAction());
        setCycles(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
    };

    const getStatus = (cycle: Cycle) => {
        if (!cycle.isOpen)
            return getLabel('cycle_list_status_closed');
        else if (cycle.locked)
            return getLabel('cycle_list_status_locked');
        else
            return getLabel('cycle_list_status_unlocked');
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_CYCLES}>
            <Container maxWidth={false} className="cycles">
                <h2>{getLabel("cycle_page_heading")}</h2>
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ StartDate: searchValues.startDate, EndDate: searchValues.endDate }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="search-filter-fields">
                                        <TextInput type="date" name="StartDate" label="cycle_filter_start" fullwidth={false} shrink={true}  />
                                        <TextInput type="date" name="EndDate" label="cycle_filter_end" fullwidth={false} shrink={true}  />
                                        <div className="search-filter-buttons">
                                            <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => {clearSearchFilters(); props.resetForm();}}>{getLabel('clear_search_filter_button_label')}</Button>
                                            <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')} </Button>
                                        </div>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                {hasPermissions(Permissions.CAN_UPDATE_CYCLE) && <Button id="addButton" variant="contained" color="primary" onClick={createNewCycle}>Add</Button>}
                <div>
                    <List id="resultList">
                        {cycles.map((cycle: Cycle) => <Paper key={cycle.id}> <ListItem className="row truncate" button onClick={(event) => selectCycle(event, cycle.id)}>
                            <div className="name">{cycle.name}</div>
                            <div className="truncate grid">
                                <div><div className="colHeader">{getLabel("cycle_list_snapshot_header")}</div><div>{formatLongDate(cycle.snapshotAt)}</div></div>
                                <div><div className="colHeader">{getLabel("cycle_list_start_header")}</div><div>{formatLongDate(cycle.startAt)}</div></div>
                                <div><div className="colHeader">{getLabel("cycle_list_end_header")}</div><div>{formatLongDate(cycle.endAt)}</div></div>
                                <div><div className="colHeader">{getLabel("cycle_list_status_header")}</div><div>{getStatus(cycle)}</div></div>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("cycle_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
