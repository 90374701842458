import { SetLogOutAction } from '../../actions/authAction';
import { SetUserMessageErrorAction } from '../../actions/userMessageAction';

export async function displayApiError(response, dispatcher) {
  if (response.status === 401) {
      dispatcher(SetLogOutAction(true));
    return;
  }
  var message = await getErrorMessage(response);
  dispatcher(SetUserMessageErrorAction(message));
}

export async function getErrorMessage(response) {
  if (isRequestBlockedByFirewall(response)) {
    return 'api_rejected_text';
  }

  var json = await tryParseJson(response);
  // The response could be:
  // - our custom error response (an object with a `message` field)
  // - default ASPNET error from std model validation (with `title` and `errors`)
  // - any other JSON (containing neither `message` nor `title`)... or not even JSON format at all
  return json?.message ?? json?.title ?? 'api_failed_text';
}

function isRequestBlockedByFirewall(response) {
  var server = response.headers.get('server');
  return server?.startsWith('Microsoft-Azure-Application-Gateway');
}

export async function tryParseJson(response) {
  try {
    return await response.json();
  } catch (error) {
    return undefined;
  }
}