import { UserPreference } from "../interfaces/ApiInterfaces";

export class UserPreferenceIds {
    public static readonly PRR_EMAILS = 1;
    public static readonly SUMMARY_EMAILS = 2;
}

export function setUserPreferenceValue(preference: UserPreference | undefined, value: any): void {
    if (preference === undefined) return;
    const dataType = preference.dataType;
    switch(dataType) {
        case "boolean":
        case "bit":
             preference.preferenceValue = getStringFromBoolean(value);
             preference.preferenceBooleanValue = value;
    }
}

function getStringFromBoolean(value: boolean): string {
        return !!value ? '1' : '0';
}
