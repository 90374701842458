import { FetchNotifications, FetchNotificationsAction, TOGGLE_FETCH_NOTIFICATIONS } from '../actions/fetchNotificationsAction';

const initialState: FetchNotifications = {
    toggle: false
};

export const shouldFetchNotifications = (
    state: FetchNotifications = initialState,
    action: FetchNotificationsAction
): FetchNotifications => {
    if (action.type === TOGGLE_FETCH_NOTIFICATIONS) {
        return { toggle: action.toggleNotifications.toggle };
    }
    return state;
};

export const getFetchNotifications = (state: FetchNotifications) => state.toggle;
