export class RoleIds {
    public static readonly ADMIN = 1;
    public static readonly MAG = 2;
    public static readonly CLIENT = 3;
    public static readonly ACN_NETWORK_MAINTENANCE = 4;
    public static readonly ADMIN_READ_ONLY = 5;
    public static readonly MAG_READ_ONLY = 6;
    public static readonly CLIENT_READ_ONLY = 7;
    public static readonly ACN_NETWORK_MAINTENANCE_READ_ONLY = 8;
    public static readonly ACN_PRA_ADMIN = 9;
    public static readonly ACN_PRA_ADMIN_READ_ONLY = 10;
    public static readonly ACN_ROSTER_IMPORT = 11;
    public static readonly ACN_ROSTER_IMPORT_READ_ONLY = 12;
    public static readonly ACN_FILES_EXCHANGE = 13;
    public static readonly ACN_FILES_EXCHANGE_READ_ONLY = 14;
}