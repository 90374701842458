import React, { useState, useEffect } from 'react';
import { getLabel } from '../common/label/Label.library';
import { Button, CardContent, Card, List, ListItem, Paper, Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import './AccountableCareNetworkProviders.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { SetUserMessageSuccessAction, ClearUserMessageAction } from '../../actions/userMessageAction';
import { ACN } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { ACNProvider } from '../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { AffiliationStatus } from '../../constants/AffiliationStatus';
import { SetFetchNotificationsAction } from '../../actions/fetchNotificationsAction';
import { Permissions } from '../../constants/Permissions'
import { hasPermissions } from '../../services/auth/auth';
import { TabValue } from '../../constants/TabValue';
import { PagingFooter } from '../common/paging/PagingFooter';
import { AffiliationButton } from '../providers/provider.affiliations/AffiliationButton';

interface Props {
    acn: ACN;
    canMakeChangesForACN: boolean;
}

export const AccountableCareNetworkProviders: React.FC<Props> = ({acn, canMakeChangesForACN}) => {
    const [providers, setProviders] = useState<ACNProvider[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const canManageACNProviders = hasPermissions(Permissions.CAN_MANAGE_PROVIDERS) && canMakeChangesForACN;
    const skip = (page - 1) * SearchDefaults.TAKE;

    useEffect(() => {
        getProviders(1, true);
    }, [acn.id])

    const getProviders = async (newPage: number, showSucessMessage: boolean = true) => {
        setProviders([]);
        setTotalResults(-1);
        const data = {
            Id: acn.id,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE
        };
        const response = await makeJSONPostRequest(ApiUrls.ACN_AFFILIATIONS, dispatch, data, true, true);

        var pro = response.body.result;
        const ids = pro.map((p: ACNProvider) => p.id);
        if (canMakeChangesForACN) {
            const affiliations = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDER_AFFILIATION_STATUS, { acnId: acn.id }), dispatch, ids, true, true);
            const releaseRequestCount = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDERS_ACTIVE_RELEASE_REQUEST_COUNT, { acnId: acn.id }), dispatch, ids, false, false);
            pro.forEach((p: ACNProvider) => {
                p.affiliationStatus = affiliations.body.find((e: any) => e.providerId === p.id).status;
                p.hasActiveReleaseRequests = releaseRequestCount.body.find((e: any) => e.providerId === p.id).releaseRequestCount > 0;
            });
        }

        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('providers_search_result_many', { totalCount: response.body.totalCount })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setProviders(pro);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const findMoreProviders = () => {
        navigate(
            createRoute(ApplicationRoutes.PROVIDERS),
            { state: { clearFilters : true }}
        );
    }

    const selectProvider = (providerId: number) => {
        navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: providerId, tab: TabValue.PROVIDER_ATTRIBUTES }));
    }

    const toProviderAffiliation = (event: React.ChangeEvent<unknown>, provider: ACNProvider) => {
        event.stopPropagation();
        navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: provider.id, tab: TabValue.PROVIDER_AFFILIATIONS }));
    }

    const toEditACN = (event: React.ChangeEvent<unknown>, acn: ACN) => {
        event.stopPropagation();
        navigate(createRoute(ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, { id: acn.id, tab: TabValue.ACN_ATTRIBUTES }));
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getProviders(page, false);
    };

    function calculateProviderStatus(provider: ACNProvider): string | null {
        if (provider.hasActiveReleaseRequests) {
            return AffiliationStatus.RELEASE_REQUESTED;
        }
        return provider.affiliationStatus;
    }

    const handleAffiliationChanges = async () => {
        dispatch(SetFetchNotificationsAction(true));
        await getProviders(page, false);
    }

    return (
        <Card>
            <CardContent>
                <div className="acnProviders">
                    <Button id="toProviders" className="link" color="primary" onClick={findMoreProviders}>{getLabel('find_more_providers_label')}</Button>
                    <List id="resultList">
                        {providers.map((provider) => <Paper key={provider.id} elevation={2}> <ListItem className="row" button onClick={() => selectProvider(provider.id)}>
                            <div className="flexCenter provider-list-row truncate">
                                <div>
                                    <div className="name">{provider.pcpFullName}</div>
                                    <div className="provider-row-data truncate">
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_pcp_credentials_header")}</div>
                                            <div>{provider.pcpCredentials}</div>
                                        </span>
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_specialty_header")}</div>
                                            <div>{provider.specialty}</div>
                                        </span>
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_pcp_npi_header")}</div>
                                            <div>{provider.pcpnpi}</div>
                                        </span>
                                        <span>
                                            <div className="colHeader">{getLabel("providers_list_pp_npi_header")}</div>
                                            <div>{provider.groupNPI}</div>
                                        </span>
                                        {
                                            provider.releaseRequestACNs.length > 0 &&
                                                <span>
                                                    <div className="colHeader">{getLabel("providers_list_requested_by_header")}</div>
                                                    <div>
                                                        {
                                                            provider.releaseRequestACNs.length === 1
                                                            ? <Link className="provider-row-data-link" onClick={(e: React.ChangeEvent<unknown>) => toEditACN(e, provider.releaseRequestACNs[0])}>{provider.releaseRequestACNs[0].acnName}</Link>
                                                            : <Link className="provider-row-data-link" onClick={(e: React.ChangeEvent<unknown>) => toProviderAffiliation(e, provider)}>{getLabel("providers_list_mixed_button_text")}</Link>
                                                        }
                                                    </div>
                                                </span>
                                        }
                                    </div>
                                </div>
                                <div>
                                    {canMakeChangesForACN && <AffiliationButton providerId={provider.id} currentStatus={calculateProviderStatus(provider)}
                                        onChange={handleAffiliationChanges} hasButtonInfo={false} providerActive={true}
                                        disabled={!canManageACNProviders || provider.affiliationInProgress!} setIsAffiliationActionInProgress={function setInProgress(inProgress: boolean) { provider.affiliationInProgress = inProgress }} />
                                    }
                                </div>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("acn_provider_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </CardContent>
        </Card>
    )
}
