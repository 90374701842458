export interface ProductSearch {
    name: string;
    includeInactive: boolean;
}

export const SET_PRODUCT_SEARCH = "SET_PRODUCT_SEARCH";
export const CLEAR_PRODUCT_SEARCH = "CLEAR_PRODUCT_SEARCH";

interface SetProductSearch {
    type: typeof SET_PRODUCT_SEARCH;
    productSearch: ProductSearch;
}

interface ClearProductSearch {
    type: typeof CLEAR_PRODUCT_SEARCH;
}

export type ProductSearchAction = SetProductSearch | ClearProductSearch;

export const SetProductSearchAction = (
    name: string,
    includeInactive: boolean
): SetProductSearch => ({
    type: SET_PRODUCT_SEARCH,
    productSearch: {
        name: name,
        includeInactive: includeInactive
    }
});

export const ClearProductSearchAction = (): ClearProductSearch => ({
    type: CLEAR_PRODUCT_SEARCH
});