import React, { useLayoutEffect, useEffect } from 'react';
import './ResetPassword.css';
import { Container, Card, CardHeader, CardContent, Button } from '@mui/material';
import { getLabel } from '../../components/common/label/Label.library';
import { UnauthenticatedLayout } from '../../components/layouts/unauthenticated.layout/UnauthenticatedLayout';
import { TextInput } from '../../components/common/text.input/TextInput';
import { Formik } from 'formik';
import { makePlainPostRequestNoErrorHandling } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { isAuthenticated, getAuthenticatedHomePage} from '../../services/auth/auth';
import { useDispatch } from 'react-redux';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isValidEmail } from '../../services/validate/validate';

export const ResetPassword: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_reset_password')}`
    }, [])

    async function resetPassword(values: any, actions: any) {
        const data = {
            Email: values.email,
        };

        const response = await makePlainPostRequestNoErrorHandling(ApiUrls.SEND_FORGOT_PASSWORD_EMAIL, dispatch, JSON.stringify(data));

        if (response.ok) {
            dispatch(SetUserMessageSuccessAction('password_reset_text'));
            navigate(ApplicationRoutes.LOGIN);
        } 
        actions.setSubmitting(false);
    }

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (isEmpty(values.email)) {
            errors.email = getLabel('validation_message_required');
        } else if (!isValidEmail(values.email)) {
            errors.email = getLabel('validation_message_email');
        }
        return errors;
    };

    useLayoutEffect(() => {
        if (isAuthenticated()) {
            navigate(getAuthenticatedHomePage(), {replace: true});
        }
    }, []);

    return (
        <UnauthenticatedLayout {...props}>
            <Formik
                initialValues={{ username: '', password: '' }}
                validate={validate}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, actions) => {
                    resetPassword(values, actions);
                }}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <Container maxWidth={false} className="reset-password-container">
                            <Card className="reset-password-panel">
                                <CardHeader title={getLabel('reset_password_heading')} />
                                <CardContent className="reset-password-card-content">
                                    <TextInput name="email" label="reset_password_email_label" fullwidth={true} />
                                    <Button
                                        className="submit-button"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!props.dirty || props.isSubmitting}
                                    >
                                        {getLabel('reset_password_submit_button_label')}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Container>
                    </form>
                )}
            </Formik>
        </UnauthenticatedLayout>
    );
};
