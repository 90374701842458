import React, { useEffect } from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik, FormikTouched } from 'formik';
import { Button, CardContent, Card, Checkbox, FormControl, FormGroup, FormLabel, FormControlLabel, FormHelperText } from '@mui/material';
import { makeJSONPostRequest, makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { AppState } from '../../store/configureStore';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { useDispatch, useSelector } from 'react-redux';
import { SetRoleDropdownAction } from '../../actions/roleDropdownAction';
import { roleDropdownValues } from '../../reducers/rootReducer';
import './UserAttributes.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { User, UserRole } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { TabValue } from '../../constants/TabValue';
import { Permissions } from '../../constants/Permissions';
import { getClientId } from '../../services/client/client';
import { getUserId, hasPermissions, logOut } from '../../services/auth/auth';
import { useACNDropdownValues } from '../../hooks/useACNDropdownValues';
import { isEmpty, isValidEmail } from '../../services/validate/validate';
import { getAllowLoginLabel } from './UserAttributesUtil';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { UserACNsMessagesOnLoad } from './user.accountable.care.networks/UserAccountableCareNetworks';
import { useFeatureFlagCheck } from '../../hooks/useFeatureFlags';
import { FeatureFlags } from '../../constants/FeatureFlags';

interface Props {
    user: User;
    reloadUser: () => Promise<void>;
}

export const UserInfo: React.FC<Props> = ({ user, reloadUser }) => {
    const roleValues = useSelector<AppState, any>(roleDropdownValues);
    const acnValues = useACNDropdownValues();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAdd = user.id === -1;
    const canOnlyAssignAcnRoles = !hasPermissions(Permissions.CAN_ACT_FOR);
    const canUpdateAllFields = isAdd || hasPermissions(Permissions.CAN_ACT_FOR) || user.id !== getUserId();
    const isSSOFeatureEnabled = useFeatureFlagCheck(FeatureFlags.SSOLogIn);

    useEffect(() => {
        const getRoles = async () => {
            const result = await makeJSONGetRequest(ApiUrls.GET_ROLES, dispatch, null, false, false)
            dispatch(SetRoleDropdownAction(result));
        }
        if (!roleValues) {
            getRoles();
        }
    }, [roleValues, dispatch]);

    const userHasPermission = (permission: string, userRoleIds: number[]): boolean => {
        if (!!roleValues) {
            var rolesWithPermission: UserRole[] = roleValues.body.filter((role: UserRole) => role.permissions.some(p => p.code === permission));
            return !!rolesWithPermission && rolesWithPermission.some(desiredRole => userRoleIds.includes(desiredRole.id));
        }
        return false;
    }

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (isEmpty(values.DisplayName)) {
            errors.DisplayName = getLabel('validation_message_required');
        }
        if (isEmpty(values.Email)) {
            errors.Email = getLabel('validation_message_required');
        } else if (!isValidEmail(values.Email)) {
            errors.Email = getLabel('validation_message_email');
        }
        if (user.roleIds.length === 0) {
            errors.RoleIds = getLabel('validation_message_required');
        }
        return errors;
    }

    //TODO: `isFormClean(values)` - comparing current form values with the stored user, like in most other forms in PRA
    const formClean = (touched: FormikTouched<any>) => {
        return (touched.DisplayName === undefined &&
            touched.Email === undefined &&
            touched.Active === undefined &&
            touched.ViewOnly === undefined &&
            touched.AllowPwdLogin === undefined &&
            touched.AllowSsoLogin === undefined &&
            touched.RoleIds === undefined);
    }

    const updateUserRoles = (
        setFieldValue: (field: string, value: any) => void,
        setFieldTouched: (field: string, isTouched?: boolean) => void
    ) => function (roleIds: number[]) {
        setFieldValue('RoleIds', roleIds);
        setFieldTouched('RoleIds', true);
    }

    const getRoles = (updateUserRoles: (roleIds: number[]) => void) => {
        if (!roleValues) return;
        const nonReadRoles: UserRole[] = roleValues.body.filter((role: UserRole) => !role.isReadRole);
        return (
            <>
                {nonReadRoles.map(role => {
                    return <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={user.roleIds.some((x: number) => x === role.id || x === role.readRoleId)}
                                name={role.name}
                                onChange={(event) => handleRoleCheck(event, updateUserRoles)}
                                disabled={!canUpdateAllFields}
                            />
                        }
                        label={role.name}
                        key={role.id}
                    />
                })}
            </>
        );
    }

    const handleRoleCheck = (event: React.ChangeEvent<HTMLInputElement>, updateUserRoles: (roleIds: number[]) => void) => {
        if (!!roleValues) {
            var roleIds = user.roleIds;
            var changedRole = roleValues.body.find((role: UserRole) => role.name === event.target.name);
            if (event.target.checked) {
                roleIds.push(changedRole.id); //TODO: updating the original 'user' object!? bad idea... all this "role checks" handling should be rewritten
            } else {
                var index = roleIds.findIndex((id: number) => id === changedRole.id || id === changedRole.readRoleId);
                if (index !== -1) {
                    roleIds.splice(index, 1);
                }
            }
            updateUserRoles(roleIds);
        }
    }

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.USERS));
    }

    const saveUser = async (values: any) => {
        const data: any = {
            DisplayName: values.DisplayName,
            Email: values.Email,
            Active: values.Active,
            ViewOnly: values.ViewOnly,
            AllowPwdLogin: values.AllowPwdLogin,
            AllowSsoLogin: values.AllowSsoLogin,
            RoleIds: values.RoleIds,
            ClientId: getClientId()
        };

        const response = (isAdd) ?
            await makeJSONPostRequest(ApiUrls.REGISTER_USER, dispatch, data) :
            await makeJSONPostRequest(createUrl(ApiUrls.EDIT_USER, { userId: user.id }), dispatch, data);

        const logOutUpdatedCurrentUser = user.id === getUserId();
        const switchToACNsTab = needAssignACNs(values.RoleIds) && !await autoAssignDefaultACN(response.body.userId);
        if (logOutUpdatedCurrentUser)
            await logOut(dispatch);
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, {
                id: response.body.userId,
                tab: switchToACNsTab ? TabValue.USER_ACN : TabValue.USER_ATTRIBUTES
            }));
            reloadUser(); // no await here, let this (and the navigate above) complete after our set msg below
        }
        const overrideNextMessages = logOutUpdatedCurrentUser || !switchToACNsTab ? 0 :
            UserACNsMessagesOnLoad + (isAdd ? 0 : 1);  // +1 for the "clear msg" on page switch (although on Add somehow it happens before)
        dispatch(SetUserMessageSuccessAction(isAdd ? 'user_success_add_text' : 'user_success_edit_text', overrideNextMessages));
    }

    const needAssignACNs = (userRoleIds: number[]) => {
        const hasACNRole = userHasPermission(Permissions.CAN_VIEW_ONLY_MY_ACN, userRoleIds);
        return hasACNRole && user.acnIds.length === 0;
    }

    const autoAssignDefaultACN = async (userId: number) => {
        const isSingleACNAdminAddingNewUser = canOnlyAssignAcnRoles && acnValues.length === 1 && isAdd;
        if (isSingleACNAdminAddingNewUser)
            await makeJSONPostRequest(createUrl(ApiUrls.CREATE_USER_ACN_ASSOCIATION, { userId, acnId: acnValues[0].id }), dispatch, null);
        return isSingleACNAdminAddingNewUser;
    }

    return (
        <Card>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        DisplayName: user.name ?? '',
                        Email: user.email ?? '',
                        Active: user.active ?? true,
                        ViewOnly: user.viewOnly ?? false,
                        AllowPwdLogin: user.allowPwdLogin ?? false,
                        AllowSsoLogin: user.allowSsoLogin ?? false,
                        RoleIds: user.roleIds ?? []
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveUser(values).finally(() => {
                            actions.setTouched({});
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form className="edit-user-attributes" onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <TextInput name="DisplayName" label="user_attributes_displayName_label" disabled={!canUpdateAllFields} fullwidth={false} />
                                <TextInput name="Email" label="user_attributes_email_label" disabled={!canUpdateAllFields} fullwidth={false} />
                                <FormControl className="roles" error={props.touched && Boolean(props.errors.RoleIds)} name="RoleIds" component="fieldset">
                                    <FormLabel component="legend">{getLabel("user_attributes_roles_label")}</FormLabel>
                                    <FormGroup >
                                        {getRoles(updateUserRoles(props.setFieldValue, props.setFieldTouched))}
                                    </FormGroup>
                                    {props.errors.RoleIds && <FormHelperText className="validateText">{props.errors.RoleIds}</FormHelperText>}
                                </FormControl>
                                <div className="flexColumn">
                                    <CheckboxInput name="ViewOnly" label="user_attributes_viewOnly_label" heading="user_attributes_access_label" disabled={!canUpdateAllFields} padded={false} />
                                    <span className="flexFill"></span>
                                    {!isAdd && isSSOFeatureEnabled && <>
                                        <CheckboxInput name="AllowPwdLogin" label={getAllowLoginLabel(user, 'Pwd')} disabled={user.allowPwdLogin === null} padded={false} />
                                        <CheckboxInput name="AllowSsoLogin" label={getAllowLoginLabel(user, 'Sso')} disabled={user.allowSsoLogin === null} padded={false} />
                                    </>}
                                </div>
                            </div>
                            <div className="flexRow">
                                <span className="flexFill"></span>
                                <CheckboxInput name="Active" label="user_attributes_active_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('user_attributes_cancel_button_label')}</Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={formClean(props.touched) || props.isSubmitting}>{getLabel('user_attributes_submit_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
            </CardContent>
        </Card>
    )
}
