export const CONTEXT_PATH: string = '/';
export const ACTING_FOR_ID: string = '/:actingForId';

export class ApplicationRoutes {
    public static readonly ROOT_ROUTE: string = CONTEXT_PATH;
    public static readonly LOGIN: string = CONTEXT_PATH + 'login';
    public static readonly SET_PASSWORD: string = CONTEXT_PATH + 'set-password';
    public static readonly RESET_PASSWORD: string = CONTEXT_PATH + 'reset-password';
    public static readonly USERS: string = CONTEXT_PATH + 'users';
    public static readonly EDIT_USER: string = CONTEXT_PATH + 'users/edit/:id/:tab';
    public static readonly ACCOUNTABLE_CARE_NETWORKS: string = CONTEXT_PATH + 'accountable-care-networks';
    public static readonly EDIT_ACCOUNTABLE_CARE_NETWORKS: string = CONTEXT_PATH + 'accountable-care-networks/edit/:id/:tab';
    public static readonly PROVIDERS: string = CONTEXT_PATH + 'providers';
    public static readonly EDIT_PROVIDER: string = CONTEXT_PATH + 'providers/edit/:id/:tab';
    public static readonly EDIT_DESIGNATION: string = CONTEXT_PATH + 'providers/designations/edit/:providerId/:id/:tab';
    public static readonly RELEASE_PROVIDER: string = CONTEXT_PATH + 'providers/filter/:filter';
    public static readonly IMPORT_FACILITY_SITES: string = CONTEXT_PATH + 'providers/importFacilitySites';
    public static readonly VIEW_IMPORT_FACILITY_SITES_RESULT: string = CONTEXT_PATH + 'providers/importFacilitySites/result/:id';
    public static readonly IMPORT_PROVIDERS: string = CONTEXT_PATH + 'providers/importProviders';
    public static readonly VIEW_IMPORT_PROVIDERS_RESULT: string = CONTEXT_PATH + 'providers/importProviders/result/:id';
    public static readonly IMPORT_ROSTER: string = CONTEXT_PATH + 'providers/importRoster';
    public static readonly VIEW_IMPORT_ROSTER_RESULT: string = CONTEXT_PATH + 'providers/importRoster/result/:id';
    public static readonly IMPORT_ACNS: string = CONTEXT_PATH + 'accountable-care-networks/importACNs';
    public static readonly VIEW_IMPORT_ACNS_RESULT: string = CONTEXT_PATH + 'accountable-care-networks/importACNs/result/:id';
    public static readonly EDIT_GROUP: string = CONTEXT_PATH + 'groups/groups/edit/:id/:tab';
    public static readonly EDIT_SUBGROUP: string = CONTEXT_PATH + 'groups/subgroups/edit/:groupId/:id/:tab';
    public static readonly GROUPS: string = CONTEXT_PATH + 'groups';
    public static readonly PROGRAMS: string = CONTEXT_PATH + 'programs';
    public static readonly EDIT_PROGRAM: string = CONTEXT_PATH + 'programs/edit/:id/:tab';
    public static readonly EDIT_CYCLE: string = CONTEXT_PATH + 'cycles/edit/:id/:tab';
    public static readonly CYCLES: string = CONTEXT_PATH + 'cycles';
    public static readonly REPORTS: string = CONTEXT_PATH + 'reports';
    public static readonly VIEW_REPORT: string = CONTEXT_PATH + 'reports/view/:id';
    public static readonly INFO: string = CONTEXT_PATH + 'info';
    public static readonly VIEW_INFO: string = CONTEXT_PATH + 'info/view/:id';
    public static readonly NOT_FOUND: string = CONTEXT_PATH + 'notFound';
    public static readonly PRODUCTS: string = CONTEXT_PATH + 'products';
    public static readonly EDIT_PRODUCT: string = CONTEXT_PATH + 'products/edit/:id/:tab';
    public static readonly FILES: string = CONTEXT_PATH + 'files';
    public static readonly UPLOAD_FILE: string = CONTEXT_PATH + 'files/upload';
}

export function getActiveClass(locationPath: string, routes: string[]): string {
    return routes.some((route) => locationPath.startsWith(createRoute(route))) ? 'active' : '';
}

export function createRoute(url: string, params?: { [key: string]: any }): string {
    let newRoute = url;
    if (params) {
        Object.keys(params).forEach((key) => {
            newRoute = newRoute.replace(':' + key, params[key]);
        });
    }
    return newRoute;
}