import { getLabel } from '../label/Label.library';

export const validate = (values: any) => {
  const errors: { [key: string]: string } = {};
  if (!values.File) {
    errors.File = getLabel('validation_message_required');
  } 
  if (!values.Notes) {
    errors.Notes = getLabel('validation_message_required');
  }
  return errors;
};
