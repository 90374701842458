import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Label } from '../common/label/Label';
import { Notifications } from '../common/notifications/Notifications';
import { getHomePage, isAuthenticated, logOut, hasPermissions, getUserName, getUserEmail } from '../../services/auth/auth';
import PriorityHealthLogo from '../../resources/images/priority-health-logo.png';
import './Header.css';
import { ApplicationRoutes, getActiveClass, createRoute } from '../../constants/ApplicationRoutes';
import { useDispatch } from 'react-redux';
import { Permissions } from '../../constants/Permissions';
import AppLinks from '../../resources/data/links.json';
import { ActingFor } from '../common/acting.for/ActingFor';
import { InfoLink } from '../info/info.link/InfoLink';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { SetAppFeatureFlagsAction } from '../../actions/applicationInfoAction';
import { useHasGroupsFeature, useHasProductsFeature, useFeatureFlags } from '../../hooks/useFeatureFlags';
import { NextCycle } from '../common/next.cycle/NextCycle';

export const Header: React.FC<any> = (props) => {
    const featureFlags = useFeatureFlags();
    const areGroupsEnabled = useHasGroupsFeature();
    const areProductsEnabled = useHasProductsFeature();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const getAppFeatureFlags = async () => {
            const featureFlags = await makeJSONGetRequest(ApiUrls.GET_APP_FEATURE_FLAGS, dispatch, null, false, false);
            dispatch(SetAppFeatureFlagsAction(featureFlags.body.featureFlags));
        }
        if (!featureFlags) {
            getAppFeatureFlags();
        }
    }, [dispatch, featureFlags])

    function NavLinks() {
        return (
            <>
                {
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.USERS])} to={createRoute(ApplicationRoutes.USERS)}>
                        <Label label="users_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_VIEW_ACNS) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS])} to={createRoute(ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS)}>
                        <Label label="accountable_care_networks_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_VIEW_PROVIDERS) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.PROVIDERS])} to={createRoute(ApplicationRoutes.PROVIDERS)}>
                        <Label label="providers_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_VIEW_GROUPS) && areGroupsEnabled &&

                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.GROUPS])} to={createRoute(ApplicationRoutes.GROUPS)}>
                        <Label label="groups_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_SEE_PROGRAMS_PAGE) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.PROGRAMS])} to={createRoute(ApplicationRoutes.PROGRAMS)}>
                        <Label label="programs_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_SEE_PRODUCTS_PAGE) && areProductsEnabled &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.PRODUCTS])} to={ApplicationRoutes.PRODUCTS}>
                        <Label label="products_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_VIEW_CYCLES) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.CYCLES])} to={createRoute(ApplicationRoutes.CYCLES)}>
                        <Label label="cycles_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_VIEW_REPORTS) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.REPORTS])} to={ApplicationRoutes.REPORTS}>
                        <Label label="reports_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_VIEW_FILES) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.FILES])} to={createRoute(ApplicationRoutes.FILES)}>
                        <Label label="files_nav_heading" />
                    </Link>
                }
                <InfoLink {...props} infoTitle={props.infoTitle}/>
            </>
        );
    }

    return (
        <header>
            <div className="navigation-links">
                <div className="roster-provider-header">
                    <Link to={getHomePage()}>
                        <Label label="header_provider_roster_heading" />
                    </Link>
                </div>
                <div className="header-right">
                    {isAuthenticated() && (
                        <>
                            <NavLinks />
                            <Notifications {...props} />
                            <Link to={ApplicationRoutes.LOGIN} onClick={() => logOut(dispatch)} title={`${getUserName()} (${getUserEmail()})`}>
                                <Label label="header_log_out" />
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <div className="logo-acting-for">
                <div className="header-logo">
                    <a href={AppLinks.header_logo} target="_blank" rel="noopener noreferrer">
                        <img src={PriorityHealthLogo} alt="priority health logo" />
                    </a>
                </div>
                <div className="acting-for-panel">
                    {isAuthenticated() && 
                        <>
                            <NextCycle />
                            <ActingFor onChange={props.actingForOnChange} forceFocusOnReason={props.actingForForceFocusOnReason} />
                        </>
                    }
                </div>
            </div>
        </header>
    );
};
