import { setActingForId, setActingForReason } from "../services/auth/auth";

export interface ACNActingForValue { id: number, acnName: string, reason: string }

export const SET_ACN_ACTING_FOR_VALUE = "SET_ACN_ACTING_FOR_VALUE";
export const CLEAR_ACN_ACTING_FOR_VALUE = "CLEAR_ACN_ACTING_FOR_VALUE";

interface SetACNActingForValue {
    type: typeof SET_ACN_ACTING_FOR_VALUE;
    acnActingForValue: ACNActingForValue;
}

interface ClearACNActingForValue {
    type: typeof CLEAR_ACN_ACTING_FOR_VALUE;
}

export type ACNActingForValueAction = SetACNActingForValue | ClearACNActingForValue;

export const SetACNActingForValueAction = (id: number, acnName: string, reason: string ): SetACNActingForValue => {
    if (id !== -1) {
        setActingForId(String(id));
        setActingForReason(reason);
    }
    return {
        type: SET_ACN_ACTING_FOR_VALUE,
        acnActingForValue: { id, acnName, reason }
    }
};

export const ClearACNActingForValueAction = (): ClearACNActingForValue => ({
    type: CLEAR_ACN_ACTING_FOR_VALUE
});
