import React from 'react';
import { getLabel } from '../common/label/Label.library';
import { CardContent, Card, Checkbox } from '@mui/material';
import './ViewCycleAttributes.css';
import { Cycle } from '../../interfaces/ApiInterfaces';
import { getAcnFromDropdown, useACNDropdownValues } from '../../hooks/useACNDropdownValues';

interface Props {
    cycle: Cycle
}

export const ViewCycleInfo: React.FC<Props> = ({ cycle }) => {
    const acnValues = useACNDropdownValues();
    const dedicatedACN = cycle.dedicatedAcnId ? getAcnFromDropdown(acnValues, cycle.dedicatedAcnId) : null;

    return (
        <Card>
            <CardContent>
                <div className="view-only viewCycle">
                    <div className="cycle-grid grid">
                        <div><div className="colHeader">{getLabel('cycle_attributes_name_label')}</div><div>{cycle.name}</div></div>
                        <div><div className="colHeader">{getLabel('cycle_attributes_snapshot_label')}</div><div>{cycle.snapshotAt}</div></div>
                        <div><div className="colHeader">{getLabel('cycle_attributes_start_label')}</div><div>{cycle.startAt}</div></div>
                        <div><div className="colHeader">{getLabel('cycle_attributes_end_label')}</div><div>{cycle.endAt}</div></div>
                        <div><div className="colHeader">{getLabel('cycle_attributes_dedicated_acn_label')}</div><div>{dedicatedACN?.acnName ?? ''}</div></div>
                        <div className="cycle-status">
                            <Checkbox name="Locked" checked={cycle.locked} disabled={true} />{getLabel('cycle_attributes_locked_label')}</div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
